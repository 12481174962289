import {
  SecondaryColor, boxShadow,
  hiBotTooltipV2,
  primaryColor, primaryFont, wLight, wMBold,
} from 'Utils/utilsStyle';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { selectActivetype, selectHighlight, selectShowAccordion } from 'Selector/CertificationSelector';
import { ChatbotCertificationTypes } from 'Utils/Constants';
import { valueToObject } from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { actions as searchTopicsActions } from 'Slice/GlobalSearchContentSlice';
import { selectGilroyPopup } from 'Selector/GlobalSearchContentSelector';
import CrossCircle from '../assets/svgIcons/CrossCircle';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
  children: object,
  tipTitle: string,
  showAskGif?: boolean,
  inChatbot?: boolean,
  isOpen?: boolean,
  openGilroy: (a: boolean) => void,
  setIsOpen: (a: boolean) => void
};

const defaultProps = {
  showAskGif: false,
  inChatbot: false,
  isOpen: false,
};
const Wrapper = styled.div`
  &.unHeighLighted {
    z-index: 2;
  }
  &.imageWrapper {
    bottom: 2px;
    right: 15px;
  } 
  position: fixed;
  bottom: 2px;
  right: 14px;
  z-index: 999;
  cursor: pointer;
`;

const TipArea = styled.div`
background: #E6FFEE;
box-shadow:  ${boxShadow};
backdrop-filter: blur(12px);
opacity: 0.95;
border-radius: 6px;
position: absolute;
top: -80px;
right: 75px;
min-width: 222px;
padding: 40px 20px 12px 16px;
z-index: -1;
cursor: pointer;
.title {
font-family: ${primaryFont};
font-style: normal;
font-weight: ${wLight};
font-size: 14px;
line-height: 16px;
color: #000000;
.title-name {
  color: ${SecondaryColor};
  font-weight: ${wMBold};
  margin: 0px 5px;
}
}
`;

const CloseIcon = styled.span`
cursor: pointer;
 &.cross-icon-x {
  width: 19px;
  &.close {
    position: absolute;
    top: 0px;
    right: 5px;
  }
 }
 svg {
  width: 18px;
  min-width: 18px;
  &:hover {
    path {
      fill: ${primaryColor};
    }
  }
 }
`;

const BotIcon = styled(LazyLoadImage)`
 position: absolute;
 top: -35px;
 left: 2px;
 width: 65px;
`;

const ContentPopOver = function ContentPopOver(props: Props) {
  const {
    children, tipTitle, showAskGif, inChatbot, isOpen, setIsOpen, openGilroy,
  } = props;
  const host = window.location.href;
  const [text, setText] = useState('');
  const pathName = window.location.pathname;
  const tooltipText = 'Want me to help with your Sales?';
  const isHighlight = useSelector(selectHighlight);
  const showAccordion = useSelector(selectShowAccordion);
  const currentType = useSelector(selectActivetype);
  const showGilroyTooltip = useSelector(selectGilroyPopup);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tipTitle?.length || pathName.includes('documents')) {
      setText(tooltipText);
    }
  }, [pathName]);

  const show = () => isOpen && (text?.length || tipTitle?.length)
    && (!inChatbot || showAskGif) && !showAccordion && showGilroyTooltip;

  const handleCrossClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(false);
    dispatch(searchTopicsActions.updateGilroyTooltip());
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('xcgpt_push_popup_closed', 0, {
        host,
      }),
    ));
  };

  return (
    <Wrapper className={classNames(
      'content-popover-c',
      { imageWrapper: !showAskGif },
      { unHeighLighted: isHighlight && !ChatbotCertificationTypes.includes(currentType) },
    )}
    >
      {show() ? (
        <TipArea
          className="tip-area"
          onClick={() => openGilroy(true)}
          onKeyPress={() => openGilroy(true)}
          tabIndex={0}
          role="button"
        >
          <div className="title">
            {text || (
              <>
                Want to know about
                <span className="title-name">
                  {tipTitle}
                  ?
                </span>
                <br />
                Let me help you out.
              </>
            )}

          </div>
          <CloseIcon
            className="cross-icon-x close"
            onClick={handleCrossClick}
          >
            <CrossCircle />
          </CloseIcon>
          <BotIcon src={hiBotTooltipV2} alt="bot-hi" />
        </TipArea>
      ) : null}

      {children}
    </Wrapper>
  );
};
ContentPopOver.defaultProps = defaultProps;
export default ContentPopOver;
