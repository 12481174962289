import React, {
  lazy, Suspense, useEffect, useRef,
} from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import CloseAlerts from 'Components/alerts/assets/svg/CloseAlerts';
import { maxMobile, scrollbar } from 'Utils/utilsStyle';
import { Alert, Article } from 'Types/ArticleTypes';
import { isSimilarArticleScroll, isSimilarArticleScrollItem } from 'Selector/UISelector';
import { useSelector } from 'react-redux';
import { scrollToElement } from 'Utils/UtilityFunctions';
import {
  selectAlertDrawerVisible,
  selectSimilarArticleTwo, selectSimilarDrawerVisible,
} from 'Selector/ArticleSector';
import classNames from 'classnames';

const ArticleInfo = lazy(() => import('./ArticleInfo'));

const ScrollWrapper = styled.div`
  margin: 0px 10px 0 20px;
  padding: 10px 8px 10px 10px;
  height: calc(100vh - 57px);
  ${scrollbar};
  @media all and (max-width: ${maxMobile}) {
    height: calc(100vh - 90px);
  }
`;

type Props = {
  visible?: boolean;
  articleId?: number;
  title?: string,
  domain?: string,
  dateTimeStamp?: string,
  summary?: string,
  image?: string,
  url?: string,
  alertId?: number
  companyName?: string
  spotlight?: boolean
  articleAlert?: Alert[]
  isSimilarArticle?: boolean
  similarArticles?: Article[]
  onClose: () => void
  similarArticlesCount?: number
};
const defaultProps = {
  visible: false,
  articleId: 0,
  title: '',
  domain: '',
  dateTimeStamp: '',
  summary: '',
  image: '',
  url: '',
  alertId: 0,
  companyName: '',
  spotlight: false,
  articleAlert: [],
  isSimilarArticle: false,
  similarArticles: [],
  similarArticlesCount: 0,
};
const ArticleDrawer = function ArticleDrawer(props: Props) {
  const {
    visible,
    articleId,
    title,
    domain,
    dateTimeStamp,
    summary,
    image,
    url,
    alertId,
    companyName,
    spotlight,
    articleAlert,
    isSimilarArticle,
    onClose,
    similarArticles,
    similarArticlesCount,
  } = props;

  const scrollRefDrawerOne = useRef<HTMLDivElement>(null);
  const scrollRefDrawerTwo = useRef<HTMLDivElement>(null);
  const isSimilarArticleScrollFlag: boolean = useSelector(isSimilarArticleScroll);
  const isSimilarArticleScrollItemFlag: boolean = useSelector(isSimilarArticleScrollItem);
  const similarArticlesTwo = useSelector(selectSimilarArticleTwo);
  const similarDrawerVisible = useSelector(selectSimilarDrawerVisible);
  const alertDrawerVisible = useSelector(selectAlertDrawerVisible);
  const articleDetailLoading = !articleId;

  useEffect(() => {
    if (similarArticlesTwo.length === 0
      && !isSimilarArticleScrollItemFlag && similarDrawerVisible) {
      scrollRefDrawerTwo.current?.scrollTo(0, 0);
    }
  }, [similarArticlesTwo]);

  useEffect(() => {
    if (isSimilarArticleScrollFlag && alertDrawerVisible
      && !similarDrawerVisible && scrollRefDrawerOne?.current) {
      setTimeout(() => {
        scrollToElement('tabs-article-id', scrollRefDrawerOne?.current);
      }, 500);
    }
  }, [alertDrawerVisible, isSimilarArticleScrollFlag, scrollRefDrawerOne?.current]);

  useEffect(() => {
    if (isSimilarArticleScrollItemFlag) {
      setTimeout(() => {
        scrollToElement('tabs-article-id-two', scrollRefDrawerTwo?.current);
      }, 500);
    }
  }, [isSimilarArticleScrollItemFlag]);

  useEffect(() => {
    const node = document.getElementsByClassName('article-preview');
    return similarDrawerVisible ? node[0]?.classList.add('nestedDrawerViewer') : node[0]?.classList.remove('nestedDrawerViewer');
  }, [similarDrawerVisible]);

  return (
    <Drawer
      destroyOnClose
      className={classNames('drawer-alerts people-group-drawer article-preview', { nestedDrawerViewer: false })}
      placement="right"
      visible={visible}
      onClose={onClose}
      width={609}
      extra={(
        <span onClick={onClose} onKeyPress={onClose} tabIndex={0} role="button" aria-label="close" className="close-alerts"><CloseAlerts /></span>
      )}
    >
      <ScrollWrapper className="detail-drawer-scroll" ref={isSimilarArticle ? scrollRefDrawerTwo : scrollRefDrawerOne} id={isSimilarArticle ? 'articleDrawerScrollTwo' : 'articleDrawerScroll'}>
        <Suspense
          fallback={
            <div />
          }
        >
          <ArticleInfo
            articleId={articleId}
            title={title}
            domain={domain}
            dateTimeStamp={dateTimeStamp}
            summary={summary}
            image={image}
            url={url}
            alertId={alertId}
            companyName={companyName}
            spotlight={spotlight}
            articleAlert={articleAlert}
            isSimilarArticle={isSimilarArticle}
            similarArticles={similarArticles}
            similarArticlesCount={similarArticlesCount}
            articleDetailLoading={articleDetailLoading}
          />
        </Suspense>
      </ScrollWrapper>
    </Drawer>
  );
};

ArticleDrawer.defaultProps = defaultProps;
export default React.memo(ArticleDrawer);
