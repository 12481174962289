import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import {
  maxTablet,
  maxWidth,
  primaryFont, primaryHeadingColor, retina, sadGilroyFace, secondaryFont, wMBold,
  wMedium,
} from 'Utils/utilsStyle';
import { IsTablet } from 'Utils/UtilityFunctions';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';

const ClearPromptModal = styled(Modal)`
&.deletePopupPlaceholder {
  .ant-modal-content {
    .ant-modal-body {
      padding: 84px 86px 0px;
      .clear-text-modal {
        font-size: 24px;
        line-height: normal;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 18px;
          line-height: 24px;
        }
        @media all and (max-width: ${maxTablet}) {
          font-size: 16px !important;
          line-height: 22px !important;
        }
      }
    }
  }
}
&.clearPromptV3Modal {
  width: 420px !important;
  @media all and (max-width: ${maxTablet}) {
    width: 320px !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 49px !important;
      padding-right: 60px !important;
      padding-left: 60px !important;
      .gilroy-white-svg {
        width: 75px;
        height: 60px;
      }
      .clear-text-modal {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .ant-modal-footer {
      padding-bottom: 52px;
      .ant-btn {
        font-size: 12px;
        min-width: 76px;
      }
    }
  }
}
&.deletePromptCategory {
  .ant-modal-content {
     .ant-modal-body {
      padding-right: 48px !important;
      padding-left: 48px !important;
     }
     .ant-modal-footer {
      .ant-btn {
        padding: 5px 0px;
      }
     }
  }
}
@media all and (max-width: ${maxWidth}), ${retina} {
  width: 335px !important;
}
@media all and (max-width: ${maxTablet}) {
  width: 320px !important;
}
.ant-modal-content {
    border-radius: 18px;
    @media all and (max-width: ${maxTablet}){
      border-radius: 10px;
    }
    .ant-modal-close{
      display: none;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 84px;
      padding: 84px 115px 0px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        padding-top: 32px !important;
        padding: 32px 35px 0px !important;
      }
      .gilroy-white-svg {
        width: 101.31px;
        height: 82.247px;
        flex-shrink: 0;
        margin-bottom: 16px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 75px;
          height: 60px;
        }
        @media all and (max-width: ${maxTablet}) {
          width: 55px !important;
          height: 45px !important;
        }
      }
      .paragraph-text {
        color: ${primaryHeadingColor};
        text-align: center;
        font-family: ${primaryFont};
        font-size: 16px;
        font-weight: ${wMedium};
        line-height: 20px;
        letter-spacing: -0.578px;
        margin-bottom: 8px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 14px;
          line-height: 18px;
        }
        @media all and (max-width: ${maxTablet}) {
          font-size: 12px;
          line-height: 14px;
        }
      }
      .clear-text-modal {
        font-family: ${secondaryFont};
        font-weight: ${wMBold};
        font-size: 28px;
        line-height: 40px;
        color: ${primaryHeadingColor};
        text-align: center;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 18px;
          line-height: 24px;
        }
        @media all and (max-width: ${maxTablet}) {
          font-size: 16px !important;
          line-height: 22px !important;
        }
      }
    }
    .ant-modal-footer {
      padding: 15px 10px 48px !important;
      @media all and (max-width: ${maxWidth}), ${retina} {
        padding: 15px 10px 24px !important; 
      }
      .ant-btn {
        font-family: ${primaryFont};
        font-weight: ${wMBold};
        font-size: 16px;
        border-radius: 23px;
        line-height: 16px;
        min-width: 92px;
        max-width: 92px;
        padding: 8px 0px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 12px;
          min-width: 85px !important;
          padding: 5px 0px;
        }
        &:hover {
          outline: none !important;
        }
      }
    }
  }
`;
type Props = {
  isVisible?: boolean;
  extraText?: string;
  customClassName?: string;
  popupText: string;
  deleteBtnText?: string;
  setIsVisible: (a: boolean) => void;
  onDelete: () => void;
  onCancel?: () => void;
} & typeof defaultProps;

const defaultProps = {
  isVisible: false,
  extraText: '',
  customClassName: '',
  deleteBtnText: 'Yes',
  onCancel: () => { },
};
const GilroyDeleteChatPopup = function GilroyDeleteChatPopup(props: Props) {
  const {
    isVisible, setIsVisible, customClassName,
    extraText, popupText, onDelete, onCancel, deleteBtnText,
  } = props;

  const isGilroyV3 = !window.location.pathname.includes('feed');
  const isTab = IsTablet();
  const dontMap = !isGilroyV3 && isTab;

  const handleDelete = () => {
    onDelete();
    setIsVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsVisible(false);
    onCancel();
  };

  return (
    <ClearPromptModal
      getContainer={dontMap ? document.body : () => document.querySelector('.container-inner-area') || document.body}
      visible={isVisible}
      onOk={handleDelete}
      onCancel={handleDeleteCancel}
      centered
      cancelText="Cancel"
      okText={deleteBtnText}
      closeIcon={null}
      width="582px"
      className={classNames(`clear-prompt-modal ${customClassName}`, { clearPromptV3Modal: isGilroyV3, deletePromptCategory: extraText !== '' })}
    >
      <img src={sadGilroyFace} alt="sad-gilroy" className="gilroy-white-svg" />
      {extraText !== '' ? (
        <span className="paragraph-text">
          {HTMLReactParser(extraText)}
        </span>
      ) : null}
      <h2 className="clear-text-modal">
        {popupText}
      </h2>
    </ClearPromptModal>
  );
};
GilroyDeleteChatPopup.defaultProps = defaultProps;
export default GilroyDeleteChatPopup;
