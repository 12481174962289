/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import {
  Checkbox, Dropdown, MenuProps, Popover,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { PersonalityTypes, PersonCard } from 'Types/PersonTypes';
import DrawerPersonPersonality from 'Components/person/components/DrawerPersonPersonality';
import DrawerPeopleMoveReason from 'Components/people/components/DrawerPeopleMoveReason';
import { actions as flurryActions } from 'Slice/HelperSlice';
import {
  getCurrentTime, isNutanixUser, isXiqUser, valueToObject,
} from 'Utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { COMPANY_OVERVIEW_ROUTE, PERSONALITY_INSIGHTS_ROUTE } from 'Utils/Constants';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import { ChangeCategoryData } from 'Types/BuyerGroupsPageTypes';
import { actions } from 'Slice/BuyerGroupsPageSlice';
import {
  secondaryFont, wMBold, maxWidth, retina,
  primaryFont, wMedium, primaryHeadingColor, wELight, minWidthApp, maxTablet, maxMobile, smMobile,
  nutenixColor,
  white,
  greyColor,
  greyColorSecondary,
  primaryColor,
  minStandardScreen,
  maxWidth1300,
  noPersonImage,
} from '../../../Utils/utilsStyle';
import DragIcon from '../assets/svgIcons/DragIcon';
import { PersonProps } from './PeopleCardLarge';

const SingleHoloIconSvg = lazy(() => import('../assets/holoSvgIcon/SingleHoloIconSvg'));
const MultiHoloIconSvg = lazy(() => import('../assets/holoSvgIcon/MultiHoloIconSvg'));
const RemoveIcon = lazy(() => import('../assets/svgIcons/RemoveIcon'));

export type WidgetProps = {
  personInfo?: PersonCard,
  fromLargeCard?: boolean,
  persona?: string,
  logo: string,
  ringPadding?: string,
  hidePersonality?: boolean,
  personality?: string,
  diameter?: string,
  diameterSm?: string,
  diameterXsm?: string,
  diameterXxsm?: string,
  margin?: string,
  marginSm?: string,
  imageClassName?: string
  discImage?: boolean;
  wrapperColor?: string;
  showPersonName?: boolean;
  personName?: string;
  showCheckBox?: boolean;
  showRemoveIcon?: boolean;
  addRemovePerson?: (a: boolean, b: number, c: string, d: string) => void
  personId?: number,
  checked?: boolean,
  type?: string,
  companyId?: number,
  openInNewTab?: boolean,
  disableNavigation?: boolean,
  ringPosition?: string,
  ringSize?: string,
  ringPaddingSm?: string,
  isBoxResOne?: boolean,
  personForHover?: boolean;
  personDesignation?: string,
  personalityTypesColors?: PersonalityTypes[],
  holoDiameterXxsmHeight?: string,
  holoDiameterXxsmWidth?: string,
  holoDiameterMinHeight?: string,
  holoDiameterMinWidth?: string,
  holoDiameterHeight?: string,
  holoDiameterWidth?: string,
  isAffinitySearch?: boolean;
  firstPerson?: boolean;
  secondPerson?: boolean;
  showDrawerIcon?: boolean;
  showDropdownToDrag?: boolean;
  categoryData?: ChangeCategoryData;
  isExpended?: boolean;
  inGlobalSearch?: boolean,
  personaType?: string,
} & typeof defaultProps;

const defaultProps = {
  holoDiameterHeight: '155px',
  holoDiameterWidth: '168px',
  holoDiameterMinWidth: '115px',
  holoDiameterMinHeight: '105px',
  holoDiameterXxsmHeight: '114px',
  holoDiameterXxsmWidth: '124px',
  ringSize: 'cover',
  ringPosition: 'center',
  personId: 0,
  hidePersonality: true,
  showPersonName: false,
  personality: 'Conscientious',
  ringPadding: '0px',
  diameter: '160px',
  diameterSm: '90px',
  diameterXsm: '160px',
  diameterXxsm: '130px',
  margin: '0px 3px 0px 3px',
  marginSm: '0px 0px 0px 0px',
  imageClassName: '',
  discImage: false,
  checked: false,
  wrapperColor: '#979797',
  personalityTypesColors: [{
    description: '',
    color: '',
    image: '',
    key: '',
    value: '',
    cardColor: '',
  }],
  persona: '#979797',
  personName: 'Adam',
  showCheckBox: false,
  showRemoveIcon: false,
  type: 'EXECUTIVE',
  companyId: 0,
  ringPaddingSm: '0px',
  addRemovePerson: (a: boolean, b: number, c: string, d: string) => { },
  openInNewTab: false,
  disableNavigation: false,
  isBoxResOne: false,
  personForHover: false,
  personDesignation: '',
  fromLargeCard: false,
  isAffinitySearch: false,
  firstPerson: false,
  secondPerson: false,
  personInfo: {},
  showDrawerIcon: false,
  showDropdownToDrag: false,
  categoryData: {} as ChangeCategoryData,
  isExpended: false,
  inGlobalSearch: false,
  personaType: '',
};

const Wrapper = styled.div < { ringPaddingSm: string, color: string, diameter: string, diameterXsm: string, diameterXxsm: string, diameterSm: string, margin: string, marginSm: string, ringPadding: string, holoDiameterMinHeight: string, holoDiameterMinWidth: string, holoDiameterXxsmHeight: string, holoDiameterXxsmWidth: string }>`
&.searchPersonWidget {
  @media all and (max-width: ${maxWidth1300}) {
  width: ${(props) => props.diameterXsm};
  height: ${(props) => props.diameterXsm};
  min-width: ${(props) => props.diameterXsm};
  }
  .holo-svg {
    @media all and (max-width: ${maxWidth1300}) {
      width: ${(props) => props.holoDiameterMinWidth};
      height: ${(props) => props.holoDiameterMinHeight};
    }
  }
  .image-wrap-placeholder {
    .person-image-holder {
      @media all and (max-width: ${maxWidth1300}) {
        margin: 2px;
      }
      
    }
  }
}
 width: ${(props) => props.diameter};
 height: ${(props) => props.diameter};
 min-width: ${(props) => props.diameter};
 border-radius: 50%;
 position:relative;
 margin: ${(props) => props.margin};
 @media all and (max-width: ${minWidthApp}) {
  width: ${(props) => props.diameterXsm};
  height: ${(props) => props.diameterXsm};
  min-width: ${(props) => props.diameterXsm};
 }

  @media all and (max-width: ${maxTablet}) {
    width: ${(props) => props.diameterXxsm};
    height: ${(props) => props.diameterXxsm};
    min-width: ${(props) => props.diameterXxsm};
  }

  .image-wrap-placeholder {
    background: linear-gradient(to right, ${(props) => props.color}, ${(props) => props.color});
  }

  .person-image-holder {
   border: 2px solid #fff;
  }
  
  &.isFromLargeCard {
   .name-wrapper {
     bottom: 5px;
      @media all and (max-width: ${minWidthApp}) {
      bottom: -5px;
     }
      @media all and (max-width: ${maxTablet}) {
      bottom: 0px;
     }
   }
 }
`;

const Image = styled.div < { image: string, ringSize: string, ringPosition: string, }>`
 border-radius: 50%;
 background-image: url(${(props) => props.image});
 background-color: #fff;
 background-repeat: no-repeat;
 background-position: ${(props) => props.ringPosition};
 background-size: ${(props) => props.ringSize};
 margin: 3px;
 height: 96%;
 width: 96%;
 top: 0px;
 left: 0px;
 transform: unset;
 border: 2px solid #fff;
  @media all and (max-width: ${minWidthApp}) {
    margin: 1.7px;
  }
  @media all and (max-width: ${maxTablet}) {
    margin: 2px;
  }
`;

const ImageWrapper = styled.div<{ color: string }>`
   width: 77%;
   height: 77%;
   min-width: 77%;
   border-radius: 50%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-54%,-54%); 
`;

const PersonalityWrapper = styled.div<{ color: string, }>`
 min-width: 100px;
 width: fit-content;
 background: linear-gradient(to right, ${(props) => props.color}, ${(props) => props.color});
 position: absolute;
 bottom: -37px;
 left: 50%;
 transform: translate(-55%, -36%);
 border-radius: 30px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    min-width: 90px;
    bottom: -32px;
  }
  @media all and (max-width: ${minWidthApp}){
    min-width: 75px;
  }
  .anticon {
    min-width: 17px;
    color: rgb(255, 255, 255);
    margin-left: 5px;
    margin-right: 15px;
    @media all and (max-width: ${minWidthApp}){
      min-width: 12px;
      font-size: 10px;
      margin-top: 2px;
      margin-left: 2px;
      margin-right: 6px;
    }
  }

  h4 {
    font-size: 14px;
    font-family: ${secondaryFont};
    letter-spacing: -0.35;
    color: #fff;
    margin: 3px 5px;
    padding-left: 10px;
    white-space: nowrap;
    @media all and (max-width: ${maxTablet}) {
      font-size: 10px !important;
    }
  }
`;


const PeopleWidgetDrag = function PeopleWidgetDrag(props: WidgetProps) {
  const {
    persona, logo, personality, diameter, diameterSm,
    margin, marginSm, imageClassName,
    wrapperColor, personId,
    companyId, ringPadding,
    ringPosition, ringSize, ringPaddingSm,
    fromLargeCard,
    holoDiameterXxsmHeight, holoDiameterXxsmWidth,
    holoDiameterMinHeight, holoDiameterMinWidth, isAffinitySearch,
    secondPerson, firstPerson, diameterXsm, diameterXxsm, categoryData, inGlobalSearch, personaType
  } = props;
  const disclessPersonality = useSelector(selectDisclessPersonality);


  return (
    <Wrapper
      color={disclessPersonality ? nutenixColor : wrapperColor}
      diameter={diameter}
      diameterXsm={diameterXsm}
      diameterXxsm={diameterXxsm}
      diameterSm={diameterSm}
      margin={margin}
      marginSm={marginSm}
      ringPadding={ringPadding}
      ringPaddingSm={ringPaddingSm}

      holoDiameterMinHeight={holoDiameterMinHeight}
      holoDiameterMinWidth={holoDiameterMinWidth}
      holoDiameterXxsmHeight={holoDiameterXxsmHeight}
      holoDiameterXxsmWidth={holoDiameterXxsmWidth}
      className={classNames(`person-item-widget ${personaType}`, {
        isFromLargeCard: fromLargeCard,
        isZindex: isAffinitySearch,
        isFirstPerson: firstPerson,
        isSecondPerson: secondPerson,
        searchPersonWidget: inGlobalSearch,
      })}
    >
      <ImageWrapper color={wrapperColor} className="image-wrap-placeholder">
        <Image
          className={`person-image-holder ${imageClassName} ${!(personId === 0) || !(companyId === 0) ? 'c-pointer' : ''}` || 'c-pointer'}
          image={disclessPersonality ? noPersonImage : logo}
          onClick={() => { }}
          ringPosition={ringPosition}
          ringSize={ringSize}
        />

      </ImageWrapper>
      {(!disclessPersonality) && (
        <PersonalityWrapper
          color={persona}
          className={classNames('d-flex justify-content-center align-items-center name-wrapper', {
            isAffinitySearch,
          })}
        >
          <h4>
            {personality}
          </h4>
          <ArrowRightOutlined />
        </PersonalityWrapper>
      )}
    </Wrapper>
  );
};

PeopleWidgetDrag.defaultProps = defaultProps;
export default PeopleWidgetDrag;
