import {
  emptyCategoryGilroy,
  primaryHeadingColor,
  secondaryFont,
  wMBold,
} from 'Utils/utilsStyle';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 192px;
.empty-prompt-img {
  height: 144px;
}
.empty-text-prompt {
  color: ${primaryHeadingColor};
  text-align: center;
  font-family: ${secondaryFont};
  font-size: 12px;
  font-weight: ${wMBold};
  line-height: 16px;
  letter-spacing: -0.709px;
  margin-top: -8px;
  margin-bottom: 0px;
}
`;

type Props = {
  text: string
};

const EmptyCategory = function EmptyCategory(props: Props) {
  const { text } = props;
  return (
    <Wrapper>
      <img src={emptyCategoryGilroy} alt="empty-state-prompt" className="empty-prompt-img" />
      <h3 className="empty-text-prompt">
        {text}
      </h3>
    </Wrapper>
  );
};
export default EmptyCategory;
