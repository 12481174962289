import React, { useEffect } from 'react';
import { useDrag, useDragLayer, XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { PersonProps } from 'Components/common/components/PeopleCardLarge';
import { usePrevious } from 'Utils/hooks/usePrevious';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { actions as uiActions } from '../../../Slice/UISlice';
import PeopleCardLargeDrag from 'Components/common/components/PeopleCardLargeDrag';
import { GROUP_DETAIL_ROUTE } from 'Utils/Constants';

type Props = {
  children: JSX.Element
  isDrag: (d: boolean) => void
  highlighted?: boolean
};

const defaultProps = {
  highlighted: false,
};

const getDragLayerStyles = (initialOffset: XYCoord | null, currentOffset: XYCoord | null) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

const SCROLL_THRESHOLD = 150;
const SCROLL_SPEED = 5;
const SCROLL_INTERVAL = 15;

const CustomDragLayer = function CustomDragLayer() {
  const dispatch = useDispatch();
  const {
    itemType,
    item,
    isDragging,
    initialOffset,
    currentOffset,
  }: {
    itemType: string | symbol | null,
    item: PersonProps,
    isDragging: boolean,
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null
  } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  useEffect(() => {
    if (item?.membersActive || item?.membersActive === undefined) {
      dispatch(uiActions.groupsTitleName(item?.groupTitle || ''));
      dispatch(uiActions.setGroupCategory(
        { category: item?.categoryData?.prevCategory, stage: item?.categoryData?.prevStage },
      ));
      if (isDragging) {
        dispatch(uiActions.isPersonDrag(true));
      } else {
        dispatch(uiActions.isPersonDrag(false));
      }
    }
  }, [item, isDragging]);

  useEffect(() => {
    let scrollInterval: NodeJS.Timeout | null = null;
    const pathName = window.location.pathname;
    if (isDragging && currentOffset && pathName.includes(GROUP_DETAIL_ROUTE)) {
      const { y } = currentOffset;
      const viewportHeight = window.innerHeight;

      if (y > viewportHeight - SCROLL_THRESHOLD) {
        scrollInterval = setInterval(() => {
          window.scrollBy(0, SCROLL_SPEED);
        }, SCROLL_INTERVAL);
      } else if (y < SCROLL_THRESHOLD + 150) {
        scrollInterval = setInterval(() => {
          window.scrollBy(0, -SCROLL_SPEED);
        }, SCROLL_INTERVAL);
      }
    }

    return () => {
      if (scrollInterval) clearInterval(scrollInterval);
    };
  }, [currentOffset, isDragging]);

  const renderItem = () => {
    switch (itemType) {
      case 'PEOPLE':
        return <PeopleCardLargeDrag {...item} dragCheck />;

      default:
        return null;
    }
  };

  if (!isDragging) {
    return null;
  }

  return (
    <div className="drag-appeaser">
      <div style={getDragLayerStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  );
};

const DragWrapper = function DragWrapper({ children, isDrag, highlighted }: Props) {
  const { isPersonPage, ...restProps } = children.props;

  const [{
    isDragging, offset,
  }, drag, preview] = useDrag(
    () => ({
      type: 'PEOPLE',
      item: { ...restProps } as PersonProps,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        offset: monitor.getDifferenceFromInitialOffset(),
      })
    }),
    [children.props],
  );

  const prevIsDragging = usePrevious(isDragging);

  useEffect(() => {
    if (prevIsDragging && !isDragging) isDrag(isDragging);
  }, [isDragging]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    if (offset && offset.x && offset.y) {
      if (Math.abs(offset.x) > 70 || Math.abs(offset.y) > 70) { isDrag(true); }
    }
  }, [offset]);

  return (
    <>
      <div
        ref={drag}
        className={classNames(
          // {!isDragging ? 'grabber' : 'backActive'}
          {
            highlightedComponent: highlighted,
            downloadProfileBtn: highlighted,
            grabber: !isDragging,
            backActive: isDragging,
          },
        )}
      >
        {children || null}
      </div>
      <CustomDragLayer />
    </>

  );
};

export default React.memo(DragWrapper);
DragWrapper.defaultProps = defaultProps;
