import {
  useState, useMemo, useEffect, lazy, Suspense,
} from 'react';
import { Badge, Drawer, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Article } from 'Types/ArticleTypes';
import { Pulse } from 'Types/PulseTypes';

import { selectPeopleCount, selectArticleCount } from 'Selector/GlobalSearchContentSelector';
import {
  maxTablet,
  maxWidth, primaryFont, retina, wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import ProgressBarSkeleton from 'Components/common/skeleton/ProgressBarSkeleton';
import { Filter } from 'Types/FilterTypes';
import { useSearchParams } from 'react-router-dom';

import { actions as UiAction } from 'Slice/UISlice';
import { isDrawerOpen } from 'Selector/UISelector';
import { actions as flurryActions } from 'Slice/HelperSlice';
import {
  selectAlertDrawerVisible,
  selectArticleItemId,
  selectArticleItemSummary,
  selectSimilarDrawerVisible,
} from 'Selector/ArticleSector';
import { actions as articleActions } from 'Slice/ArticlesSlice';
import {
  anyValueInKeyOfArrayExists, getEmail, isSalesforce, valueToObject,
} from '../../../Utils/UtilityFunctions';
import { Company, NotificationAlerts } from '../../../Types/CompaniesTypes';

import {
  selectPeopleTriggers, selectPeopleTriggersLoading, selectTriggers, selectTriggersLoading,
} from '../../../Selector/TriggersSelector';
import {
  selectAlertCompanies, selectCompaniesLoading, selectCompanyCount, selectSearchedCompanies,
} from '../../../Selector/CompaniesSelector';

import { actions as alertActions } from '../../../Slice/AlertsSlice';
import { actions as triggersActions } from '../../../Slice/TriggersSlice';
import { actions as companiesActions } from '../../../Slice/CompaniesSlice';
import { actions as globalActions } from '../../../Slice/GlobalSearchContentSlice';

import AlertsStyle from '../../../Style/AlertsStyle';
import AlertsArticleArea from './AlertsArticleArea';

import {
  selectArticles, selectArticlesEnded, selectLoading, selectNotificationPulse, selectPage,
  selectSelectedTriggers, selectCompaniesSelectionToggle,
  selectShowAlertsSetting,
  selectUnSelectedCompanies,
  selectSelectedCompanies,
  selectOpenDrawer,
  selectNotificationsEnded,
  selectNotificationPage,
  selectPeopleNotification,
  selectnNotificationLoading,
  selectSelectedPeopleTriggers,
  selectPeopleMessage,
} from '../../../Selector/AlertsSelector';
import PeopleAlertsTriggersPanel from './PeopleAlertsTriggersPanel';
import ArticleItem from './ArticleItem';

const NoDataFound = lazy(() => import('Components/common/components/NoDataFound'));
const ArticleTileSkeleton = lazy(() => import('../../common/skeleton/ArticleTileSkeleton'));
const CloseAlerts = lazy(() => import('../assets/svg/CloseAlerts'));
const AlertsHead = lazy(() => import('./AlertsHead'));
const HeatMapProgress = lazy(() => import('../../common/components/HeatMapProgress'));

const AlertsDescription = lazy(() => import('./AlertsDescription'));
const BellIcon = lazy(() => import('../assets/svg/BellIcon'));
const AlertsCompanyPanel = lazy(() => import('./AlertsCompanyPanel'));
const ApplyAlerts = lazy(() => import('./ApplyAlerts'));
const AlertsTriggersPanel = lazy(() => import('./AlertsTriggersPanel'));
const NotificationItem = lazy(() => import('./NotificationItem'));

const BellAction = styled.span`
 color:#000;
 margin: 5px 20px 0px 12px;
 display: flex;
 height: 25px;
 min-height: 25px;
 min-width: 20px;
  @media all and (max-width: ${maxTablet}){
    margin: 5px 15px 0px 12px;
  }
  svg {
    height: 22px;
    min-height: 22px;
    width: auto;
    cursor: pointer;
  }
  .ant-badge-count {
    margin-top: 0px !important;
    right: 0px !important;
  }
`;

const AlertBadge = styled(Badge) <{ background: string }>`
  .ant-badge-count {
    width:27px;
    height:13px;
    font-family:${primaryFont};
    font-size:8px;
    font-weight:${wMedium};
    background: ${(props) => props.background};
    right:-14px;
    line-height:12px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
    width:24px;
    right:-10px;
    line-height:14px;
    }
  }
  .ant-badge-multiple-words {
    padding: 0px 0px;
  }
`;

const TabText = styled.span<{ color: string }>`
    color: ${(props) => props.color};

`;

type Props = {
  alertClassName?: string,
} & typeof defaultProps;

const defaultProps = {
  alertClassName: '',
};

const DrawerAlerts = function DrawerAlerts(props: Props) {
  const dispatch = useDispatch();
  const { alertClassName } = props;
  const [searchParams] = useSearchParams();
  const peopleAlert = searchParams.get('peopleAlert');
  const articleIdDeepLink: number = parseInt(searchParams.get('articleId') || '0', 10);
  const [visible, setVisible] = useState(false);
  const [people, setPeople] = useState(false);
  const [peopleAPICalled, setPeopleAPICalled] = useState(false);
  const [triggerTab, setTriggerTab] = useState(true);
  const [triggers, setTriggers] = useState<Filter[]>([]);
  const [peopleTriggers, setPeopleTriggers] = useState<Filter[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const showAlertsCheckBox: boolean = useSelector(selectShowAlertsSetting);
  const selectedTriggers: Filter[] = useSelector(selectSelectedTriggers);
  const selectedPeopleTriggers: Filter[] = useSelector(selectSelectedPeopleTriggers);
  const loading: boolean = useSelector(selectLoading);
  const selectAll: boolean = useSelector(selectCompaniesSelectionToggle);
  const page: number = useSelector(selectPage);
  const articlesEnded: boolean = useSelector(selectArticlesEnded);
  const articles: Article[] = useSelector(selectArticles);
  const filters: Filter[] = useSelector(selectTriggers);
  const peopleFilters: Filter[] = useSelector(selectPeopleTriggers);
  const companiesStore: Company[] = useSelector(selectAlertCompanies);
  const searchedCompaniesStore: Company[] = useSelector(selectSearchedCompanies);
  const selectedCompanies: Company[] = useSelector(selectSelectedCompanies);
  const unSelectedCompanies: Company[] = useSelector(selectUnSelectedCompanies);
  const companiesLoading: boolean = useSelector(selectCompaniesLoading);
  const triggersLoading: boolean = useSelector(selectTriggersLoading);
  const peopleTriggersLoading: boolean = useSelector(selectPeopleTriggersLoading);
  const pulseData: Pulse[] = useSelector(selectNotificationPulse);
  const peopleCountSC: number = useSelector(selectPeopleCount);
  const articleCountSC: number = useSelector(selectArticleCount);
  const [totalCount, setTotalCount] = useState(0);
  const [searchedCompany, setSearchedCompany] = useState('');
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const openDrawer: boolean = useSelector(selectOpenDrawer);
  const notificationLoading: boolean = useSelector(selectnNotificationLoading);
  const peopleNotification: NotificationAlerts[] = useSelector(selectPeopleNotification);
  const message: string = useSelector(selectPeopleMessage);
  const notificationPage: number = useSelector(selectNotificationPage);
  const notificationEnded: boolean = useSelector(selectNotificationsEnded);
  const isDrawerOpenFlag: boolean = useSelector(isDrawerOpen);
  const count: number = useSelector(selectCompanyCount);
  const alertDrawerVisible = useSelector(selectAlertDrawerVisible);
  const similarDrawerVisible = useSelector(selectSimilarDrawerVisible);
  const host = window.location.href;
  const articleItemSummary = useSelector(selectArticleItemSummary);
  const articleItemId = useSelector(selectArticleItemId);

  const handleFlurry = (event: string) => {
    dispatch(flurryActions.callFlurryEvent(
      valueToObject(event, 0, {
        host,
      }),
    ));
  };

  const togglePeople = () => {
    if (!peopleAPICalled) {
      const data: { username: string, page: number, filter: Filter[] } = {
        username: getEmail() || '',
        page: 1,
        filter: [],
      };
      dispatch(alertActions.getNotificationAlerts(data));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('people_notifications_loaded', 0),
      ));
      setPeopleAPICalled(true);
    }
    setPeople(true);
    setTriggerTab(false);
  };

  const toggleTrigger = () => {
    setPeople(false);
    setTriggerTab(true);
  };

  const checkForCompaniesSelection = (tempCompanies: Company[]) => {
    const tmpCompanies = tempCompanies.map((comp: Company) => {
      let tempComp = comp;
      if (selectedCompanies.some((company) => company.companyId === comp.companyId)) {
        tempComp = { ...tempComp, isSelected: true };
      } else {
        dispatch(alertActions.setSelectedCompanies(selectedCompanies
          .filter((company) => company.companyId !== comp.companyId)));
      }
      return tempComp;
    });
    setCompanies(tmpCompanies);
  };

  const setInitialCompanies = () => {
    const tempCompanies = companiesStore.map(
      (company: Company) => ({ ...company, isSelected: false }),
    );
    checkForCompaniesSelection(tempCompanies);
  };

  const setSearchedCompanies = () => {
    const tempCompanies = searchedCompaniesStore.map(
      (company: Company) => {
        let tempCompany = company;
        let tempSelectedCompanies = selectedCompanies;
        const isInSelected = tempSelectedCompanies
          .findIndex((comp) => comp.companyId === company.companyId) !== -1;
        if (isInSelected) {
          tempCompany = { ...tempCompany, isSelected: true };
          tempSelectedCompanies = tempSelectedCompanies.filter(
            (comp) => comp.companyId !== company.companyId,
          );
        }
        return tempCompany;
      },
    );
    setCompanies(tempCompanies);
  };

  const setInitialTriggers = () => {
    if (triggerTab) {
      const tempFilters = filters.map((filter: Filter) => ({ ...filter, isSelected: true }));
      setTriggers(tempFilters);
    } else {
      const tempFilters = peopleFilters.map(
        (peopleFilter: Filter) => ({ ...peopleFilter, isSelected: true }),
      );
      setPeopleTriggers(tempFilters);
    }
  };

  const closeDrawer = () => {
    toggleTrigger();
    setPeopleAPICalled(false);
    dispatch(UiAction.isDrawerOpen(false));
    setVisible(false);
  };

  const showDrawer = () => {
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('alertPanel_open', 0, {
        host,
      }),
    ));
    toggleTrigger();
    setPeopleAPICalled(false);
    dispatch(UiAction.isDrawerOpen(true));
    setVisible(true);
  };

  const onClose = () => {
    toggleTrigger();
    setPeopleAPICalled(false);
    setVisible(false);
    dispatch(UiAction.isDrawerOpen(false));
    dispatch(alertActions.toggleScreen(false));
  };

  useEffect(() => {
    const node = document.querySelector('body');
    return isDrawerOpenFlag ? node?.classList.add('body-drawer-alerts-toggle') : node?.classList.remove('body-drawer-alerts-toggle');
  }, [isDrawerOpenFlag]);

  useEffect(() => {
    if (!unSelectedCompanies.length) {
      dispatch(alertActions.setUnSelectedAll(true));
    } else if (!selectedCompanies.length && unSelectedCompanies.length === count) {
      dispatch(alertActions.setUnSelectedAll(false));
    }
  }, [unSelectedCompanies, selectedCompanies]);

  useMemo(() => {
    if (filters.length > 0) {
      if (selectedTriggers.length > 0) {
        const tempFilters = filters.map((fil: Filter) => {
          let obj;
          if (selectedTriggers.find((trigger) => trigger.filterId === fil.filterId)) {
            obj = { ...fil, isSelected: true };
          } else obj = { ...fil, isSelected: false };
          return obj;
        });
        setTriggers(tempFilters);
      } else {
        setInitialTriggers();
      }
    }
  }, [filters]);

  useMemo(() => {
    if (peopleFilters.length > 0) {
      if (selectedPeopleTriggers.length > 0) {
        const tempFilters = peopleFilters.map((fil: Filter) => {
          let obj;
          if (selectedPeopleTriggers.find((trigger) => trigger.filterId === fil.filterId)) {
            obj = { ...fil, isSelected: true };
          } else obj = { ...fil, isSelected: false };
          return obj;
        });
        setPeopleTriggers(tempFilters);
      } else {
        setInitialTriggers();
      }
    }
  }, [peopleFilters]);

  useEffect(() => {
    if (peopleAlert === 'true') {
      setVisible(true);
      setPeopleAPICalled(false);
      togglePeople();
    }
  }, [peopleAlert]);

  useEffect(() => {
    if (searchedCompany.trim() !== '' || searchedCompaniesStore.length) {
      setSearchedCompanies();
    } else {
      setInitialCompanies();
    }
  }, [companiesStore, searchedCompaniesStore]);

  useEffect(() => {
    if (visible) {
      if (articles.length === 0) {
        const articlesParams = {
          page: 1,
          is_selected_all: selectAll,
          filter: [],
          company: [],
        };

        dispatch(alertActions.getAlertArticles(articlesParams));
      }
    }
    if (!visible && (articles.length > 0 || articlesEnded || peopleNotification.length > 0)) {
      dispatch(alertActions.reset());
    }
  }, [visible]);

  useEffect(() => {
    if (!triggerTab && !notificationLoading) {
      dispatch(globalActions.updatePeopleCount(0));
    }
  }, [notificationLoading, peopleCountSC]);

  useEffect(() => {
    if (triggerTab && !loading && visible) {
      dispatch(globalActions.updateArticleCount(0));
    }
  }, [loading, articleCountSC, visible]);

  useEffect(() => {
    if (showAlertsCheckBox) {
      if (triggerTab) {
        if (filters.length < 1) {
          const filterParams = {
            filter: 1,
          };
          dispatch(triggersActions.getFilters(filterParams));
        } else if (selectedTriggers.length < 1) {
          setInitialTriggers();
        }
        if (!companiesStore.length) {
          const companyParams = {
            offset: 0,
          };
          dispatch(companiesActions.getCompanies(companyParams));
        }
      } else if (peopleFilters.length < 1) {
        const filterParams = {
          filter: 1,
        };
        dispatch(triggersActions.getPeopleFilters(filterParams));
      } else if (selectedPeopleTriggers.length < 1) {
        setInitialTriggers();
      }
    }
  }, [showAlertsCheckBox]);

  const fetchMoreArticles = () => {
    if (articles.length > 0) {
      const filter = triggers.filter((item) => item.isSelected);
      let company = [];
      if (selectAll === true) {
        company = unSelectedCompanies;
      } else {
        company = selectedCompanies;
      }
      const articlesParams = {
        page,
        is_selected_all: selectAll,
        filter,
        company,
      };
      dispatch(alertActions.getAlertArticles(articlesParams));
    }
  };

  const fetchMoreNotifications = () => {
    if (peopleNotification.length > 0) {
      const filter = peopleTriggers.filter((item) => item.isSelected);
      const data = {
        username: getEmail() || '',
        page: notificationPage,
        filter,
      };
      dispatch(alertActions.getNotificationAlerts(data));
    }
  };

  const onChangeTriggers = (flag: boolean, id: string) => {
    if (triggerTab) {
      const tempTriggers = triggers.map((trigger) => {
        if (trigger.filterId === id) {
          return { ...trigger, isSelected: flag };
        }
        return trigger;
      });
      setTriggers(tempTriggers);
    } else {
      const tempTriggers = peopleTriggers.map((trigger) => {
        if (trigger.filterId === id) {
          return { ...trigger, isSelected: flag };
        }
        return trigger;
      });
      setPeopleTriggers(tempTriggers);
    }
  };

  const onChangeCompanies = (flag: boolean, id: string) => {
    const tempCompanies = companies.map((company) => {
      let tempCompany = company;
      if (company.companyId.toString() === id) {
        tempCompany = { ...tempCompany, isSelected: flag };
        let tempSelectedCompanies = selectedCompanies;
        let tempUnSelectedCompanies = unSelectedCompanies;
        if (flag === true) {
          if (tempSelectedCompanies.some((comp) => comp.companyId !== company.companyId)
            || tempSelectedCompanies.length < 1) {
            tempSelectedCompanies = tempSelectedCompanies.concat(company);
            dispatch(alertActions.setSelectedCompanies(tempSelectedCompanies));
          }
          tempUnSelectedCompanies = tempUnSelectedCompanies.filter(
            (comp) => comp.companyId !== company.companyId,
          );
          dispatch(alertActions.setUnSelectedCompanies(tempUnSelectedCompanies));
        } else {
          if (tempUnSelectedCompanies.some((comp) => comp.companyId !== company.companyId)
            || tempUnSelectedCompanies.length < 1) {
            tempUnSelectedCompanies = tempUnSelectedCompanies.concat(company);
            dispatch(alertActions.setUnSelectedCompanies(tempUnSelectedCompanies));
          }
          tempSelectedCompanies = tempSelectedCompanies.filter(
            (comp) => comp.companyId !== company.companyId,
          );
          dispatch(alertActions.setSelectedCompanies(tempSelectedCompanies));
        }
      }
      return tempCompany;
    });
    setCompanies(tempCompanies);
  };

  const triggerAllSelection = (flag: boolean) => {
    if (triggerTab) {
      const tempTriggers = triggers.map((trigger) => ({ ...trigger, isSelected: flag }));
      setTriggers(tempTriggers);
    } else {
      const tempTriggers = peopleTriggers.map((trigger) => ({ ...trigger, isSelected: flag }));
      setPeopleTriggers(tempTriggers);
    }
  };

  const changePanel = () => {
    dispatch(alertActions.toggleScreen(true));
    dispatch(flurryActions.callFlurryEvent(
      valueToObject(`alertPanel_${people ? 'people' : 'salesTriggers'}_manage_alerts_clicked`, 0, {
        host,
      }),
    ));
  };

  const searchCompanies = (searchTerm: string) => {
    setSearchedCompany(searchTerm);
    if (searchTerm?.trim() !== '') {
      setIsSearch(true);
      const companyParams = {
        offset: 0,
        searchTerm,
      };
      dispatch(companiesActions.getCompanies(companyParams));
    } else {
      setIsSearch(false);
      dispatch(companiesActions.emptySearchedCompanies());
      setInitialCompanies();
    }
  };

  const applyFilters = () => {
    if (triggerTab) {
      const filter = triggers.filter((item) => item.isSelected);
      const company = selectedCompanies;
      const articlesParams = {
        page: 1,
        filter,
        company,
      };
      dispatch(alertActions.getAlertArticles(articlesParams));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('alertPanel_salesTriggers_manage_alerts_savedSettings', 0, {
          host,
        }),
      ));
    } else {
      const filter = peopleTriggers.filter((item) => item.isSelected);
      const data: { username: string, page: number, filter: Filter[] } = {
        username: getEmail() || '',
        page: 1,
        filter,
      };
      dispatch(alertActions.getNotificationAlerts(data));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('alertPanel_people_manage_alerts_savedSettings', 0, {
          host,
        }),
      ));
    }
    dispatch(alertActions.toggleScreen(false));
  };

  const goBack = () => {
    dispatch(alertActions.toggleScreen(false));
  };

  const validateFilters = () => {
    if (triggerTab) {
      if (anyValueInKeyOfArrayExists(triggers, 'isSelected', false) || selectedCompanies.length) {
        return false;
      }
      return true;
    }
    if (!anyValueInKeyOfArrayExists(peopleTriggers, 'isSelected', true)) { return true; }
    return false;
  };

  useEffect(() => {
    if (openDrawer) { showDrawer(); }
  }, [openDrawer]);

  useEffect(() => {
    setTotalCount(peopleCountSC + articleCountSC);
  }, [articleCountSC, peopleCountSC]);
  useEffect(() => {
    if (articleIdDeepLink && articleIdDeepLink > 0 && window.location.pathname.includes('feed')) {
      showDrawer();
      if (!alertDrawerVisible) {
        dispatch(articleActions.getArticle({
          articleid: articleIdDeepLink,
          ...(articleIdDeepLink > 0 && { isPushNotification: true }),
        }));
        dispatch(articleActions.getSimilarArticle({
          article_id: articleIdDeepLink,
        }));
        if (articleItemId === articleIdDeepLink) {
          dispatch(articleActions.setArticleSummary({
            articleId: articleIdDeepLink,
            isSimilarArticle: false,
            summary: articleItemSummary,
          }));
        } else {
          dispatch(articleActions.getArticleSummary({
            articleId: articleIdDeepLink,
            isSimilarArticle: false,
          }));
        }
        dispatch(articleActions.setAlertDrawerVisible(!alertDrawerVisible));
      }
    }
  }, [articleIdDeepLink]);

  return (
    <>
      <AlertsStyle />
      {isSalesforce() ? null
        : (
          <BellAction onClick={showDrawer}>
            <Badge
              count={totalCount}
              overflowCount={99}
              offset={[
                totalCount < 10 ? -4 : 0,
                3,
              ]}
            >
              <Suspense
                fallback={
                  <div />
                }
              >
                <BellIcon />
              </Suspense>
            </Badge>
          </BellAction>
        )}
      <Drawer
        className={classNames(
          `drawer-alerts alerts-drawer-index transform-remove ${alertClassName}`,
          {
            nestedTwoDrawerViewer: (alertDrawerVisible && visible && !similarDrawerVisible),
            nestedThreeDrawerViewer: similarDrawerVisible,
          },
        )}
        placement="right"
        visible={visible}
        onClose={onClose}
        width={600}
        extra={(
          <span onClick={onClose} onKeyPress={onClose} tabIndex={0} role="button" aria-label="close" className="close-alerts">
            <Suspense
              fallback={
                <div />
              }
            >
              <CloseAlerts />
            </Suspense>
          </span>
        )}
      >
        <Suspense
          fallback={
            <div />
          }
        >
          <AlertsHead
            onClickManage={changePanel}
            isTriggers={showAlertsCheckBox}
            goBack={goBack}
          />
        </Suspense>

        {triggerTab ? (
          <Suspense
            fallback={
              <ProgressBarSkeleton />
            }
          >
            <HeatMapProgress pulse={pulseData} load={loading && !articles.length} />
          </Suspense>
        ) : null}

        {!showAlertsCheckBox && (
          <div className="tabs-wrapper alerts-tab-padding">

            <span
              className={classNames('tabs-item alerts-tabs left-tab c-pointer', { active: triggerTab ? 'activeTab' : '' })}
              onClick={() => {
                toggleTrigger();
                handleFlurry('alertPanel_salesTriggers_tab_clicked');
              }}
              onKeyPress={() => {
                toggleTrigger();
              }}
              role="button"
              tabIndex={0}
            >
              <TabText
                color={triggerTab ? '#1EC252' : '#7E7E7E'}
              >
                Triggers
              </TabText>
            </span>
            <span
              className={classNames('tabs-item alerts-tabs right-tab c-pointer', { active: people ? 'activeTab' : '' })}
              onClick={() => {
                togglePeople();
                handleFlurry('alertPanel_people_tab_clicked');
              }}
              onKeyPress={() => togglePeople()}
              role="button"
              tabIndex={0}
            >
              <AlertBadge
                count={peopleCountSC}
                background={people ? '#1EC252' : '#7E7E7E'}

              >
                <TabText
                  color={people ? '#1EC252' : '#7E7E7E'}
                >
                  People
                </TabText>
              </AlertBadge>
            </span>
          </div>
        )}
        {
          showAlertsCheckBox ? (
            <div className={classNames('triggers-wrapper', { peopleAlertsWrapper: !triggerTab })}>
              {triggerTab ? (
                <>
                  <Suspense
                    fallback={
                      <div />
                    }
                  >
                    <AlertsDescription dec="Making changes to what you want to be alerted in here will change the alerts you receive in the app, in your browser, and on your xiQ Sales Xelerator." />
                  </Suspense>

                  <Suspense
                    fallback={
                      <div />
                    }
                  >
                    <AlertsTriggersPanel
                      onCheckedChange={onChangeTriggers}
                      onClickUnSelect={triggerAllSelection}
                      load={triggersLoading}
                      filters={triggers}
                    />
                  </Suspense>

                  <Suspense
                    fallback={
                      <div />
                    }
                  >
                    <AlertsCompanyPanel
                      companies={companies}
                      load={companiesLoading}
                      selection={onChangeCompanies}
                      onSearching={searchCompanies}
                      isSearch={isSearch}
                      isSelected={selectAll || selectedCompanies.length > 0}
                    />
                  </Suspense>
                </>
              ) : (
                <Suspense
                  fallback={
                    <div />
                  }
                >
                  <PeopleAlertsTriggersPanel
                    onCheckedChange={onChangeTriggers}
                    onClickUnSelect={triggerAllSelection}
                    load={peopleTriggersLoading}
                    filters={peopleTriggers}
                  />
                </Suspense>
              )}
              <Suspense
                fallback={
                  <div />
                }
              >
                <ApplyAlerts disabled={validateFilters()} text="Apply Alert settings" onClickApplyAlerts={applyFilters} />
              </Suspense>

            </div>
          )
            : (
              <AlertsArticleArea>
                {
                  people && (
                    <InfiniteScroll
                      dataLength={peopleNotification.length}
                      next={fetchMoreNotifications}
                      hasMore={!notificationEnded}
                      loader={<span className="spin-wrap"><Spin /></span>}
                      className="o-x-hidden articles-wrapper"
                      height="auto"
                    >
                      {peopleNotification?.length ? peopleNotification?.map(
                        (notification: NotificationAlerts) => (
                          <Suspense
                            fallback={
                              <div />
                            }
                          >
                            <NotificationItem
                              key={notification?.personId}
                              isRead={notification?.readFlag}
                              notification={notification}
                              closeDrawer={closeDrawer}
                            />
                          </Suspense>
                        ),
                      ) : null}
                      {notificationLoading && [1, 2, 3, 4, 5, 6].map(() => (
                        <Suspense
                          fallback={
                            <div />
                          }
                        >
                          <ArticleTileSkeleton />
                        </Suspense>
                      ))}
                      {
                        !notificationLoading
                        && peopleNotification.length < 1 && notificationEnded && (
                          <Suspense
                            fallback={
                              <div />
                            }
                          >
                            <div className="no-test-message">
                              <NoDataFound title={message} />
                            </div>
                          </Suspense>
                        )
                      }
                    </InfiniteScroll>
                  )
                }
                {
                  triggerTab && (
                    <InfiniteScroll
                      dataLength={articles.length}
                      next={fetchMoreArticles}
                      hasMore={!articlesEnded}
                      loader={false}
                      className="o-x-hidden articles-wrapper"
                      height="calc(100% - 145px)"
                    >

                      {articles?.length ? articles?.map((article: Article) => {
                        const alert = article?.alerts[0];
                        return (
                          <Suspense
                            fallback={
                              <div />
                            }
                          >
                            <ArticleItem
                              key={article?.articleId}
                              image={article?.image}
                              heading={article?.title}
                              summary={article?.transcribeText}
                              domain={article?.domain}
                              date={article?.dateTimeStamp}
                              industry={article?.companyName}
                              redirectUrl={article?.url}
                              articleAlert={alert?.alert}
                              trigger={alert?.color}
                              articleId={article?.articleId}
                              isRead={article?.isRead}
                              isFavorite={article?.isFavorite}
                              showDrawer
                              companyId={article?.companyId}
                              alertId={(article?.alerts && article?.alerts.length > 0
                                && article.alerts[0].alertId) || 0}
                              similarArticlesCount={article?.similarArticlesCount}
                            />
                          </Suspense>
                        );
                      }) : null}
                      {loading && [1, 2, 3, 4, 5, 6].map(() => (
                        <Suspense
                          fallback={
                            <div />
                          }
                        >
                          <ArticleTileSkeleton />
                        </Suspense>
                      ))}
                      {!loading && articles.length < 1 && articlesEnded && (
                        <Suspense
                          fallback={
                            <div />
                          }
                        >
                          <NoDataFound title="No Alerts Found!" />
                        </Suspense>
                      )}
                    </InfiniteScroll>
                  )
                }
              </AlertsArticleArea>
            )
        }
      </Drawer>

    </>
  );
};

DrawerAlerts.defaultProps = defaultProps;
export default DrawerAlerts;
