import { Popover } from 'antd';
import FilterIcon from 'Components/common/assets/svgIcons/FilterIcon';
import GlobalButton from 'Components/common/components/GlobalButton';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  greyColor,
  maxMobile,
  maxWidth,
  primaryColor, midWidthApp,
  primaryFont, primaryHeadingColor, retina, scrollbar, secondaryFont, wMBold,
  wMedium,
} from 'Utils/utilsStyle';
import AlertsSearch from 'Components/alerts/components/AlertsSearch';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomizableCheckbox from 'Components/common/components/CustomizableCheckbox';
import PeopleWidgetSmall from 'Components/common/components/PeopleWidgetSmall';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/GroupsSlice';
import { actions as ChatbotActions } from 'Slice/ChatbotV2Slice';
import {
  selectCategoryExecutivesSearched,
  selectCategoryOrganizationExecutives,
  selectExecutivesEnded, selectExecutivesLoading,
  selectExecutivesPage,
  selectOrganizationExecutiveForGilroy,
  selectSearchedEnded, selectSearchedPage,
} from 'Selector/GroupsSelector';
import { backgroundColorParsing, getLangCookie } from 'Utils/UtilityFunctions';
import { selectPrompts, selectSelectedPromptCateogry, selectUnselectedPersonIds } from 'Selector/ChatBotV2Selector';
import NoDataFound from 'Components/common/components/NoDataFound';
import classNames from 'classnames';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';

const ContentWrapper = styled.div`
  box-shadow: 0px 1.617px 48.524px 0px rgba(0, 0, 0, 0.28);
  border-radius: 12px;
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px 10px 24px;
  @media all and (max-width: ${maxMobile}) {
    padding: 10px 13px 10px 20px;
  }
  .icon-text-div {
    display: flex;
    align-items: baseline;
    .filter-icon-dropdown {
      width: 15px;
      height: 13px;
      flex-shrink: 0;
      @media all and (max-width: ${maxMobile}) {
        width: 12px;
        height: 10px;
      }
    }
    .div-text {
      color: ${primaryHeadingColor};
      font-family: ${secondaryFont};
      font-size: 20px;
      font-weight: ${wMBold};
      line-height: normal;
      letter-spacing: -0.422px;
      margin-left: 3px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        font-size: 18px;
      }
      @media all and (max-width: ${midWidthApp}) {
        font-size: 16px;
        line-height: 12px;
      }
      @media all and (max-width: ${maxMobile}) {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
  .apply-btn {
    cursor: pointer;
    color: #FFF;
    font-family: ${primaryFont};
    font-size: 16px;
    font-weight: ${wMBold};
    line-height: normal;
    border-radius: 8px;
    background: ${primaryColor};
    text-align: center;
    padding: 6px 12px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      font-size: 14px;
      border-radius: 4px;
    }
    @media all and (max-width: ${midWidthApp}) {
      height: 26px;
      padding: 4px 14px;
    }
    @media all and (max-width: ${maxMobile}) {
      font-size: 11px;
      padding: 4px 12px;
      height: 22px;
    }
  }
}
.content-div {
  &.noResultFound {
      .infinite-scroll-component__outerdiv {
        padding: 0px 22px 0px 22px;
        @media all and (max-width: ${maxMobile}) {
          padding: 0px 16px 0px 16px;
        }
        .infinite-scroll-component {
          max-height: 152px;
          height: 152px !important;
          @media all and (max-width: ${midWidthApp}) {
            max-height: 149px;
            height: 149px !important;
          }
        }
      }
  }
  border-radius: 0px 0px 12px 12px;
  background: #F8F8F8;
  .heading-text {
    padding: 8px 22px 0px;
    color: ${greyColor}; 
    font-family: ${primaryFont};
    font-size: 16px;
    font-weight: ${wMedium};
    line-height: 20px;
    letter-spacing: -0.375px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      font-size: 14px;
    }
    @media all and (max-width: ${midWidthApp}) {
      font-size: 12px;
    }
    @media all and (max-width: ${maxMobile}) {
      padding: 6px 16px 0px;
      font-size: 10px;
      line-height: normal;
    }
  }
   .search-wrapper {
    padding: 0px 22px;
    margin-top: 8px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      margin-top: 6px;
      height: 28px;
    }
    @media all and (max-width: ${midWidthApp}) {
      height: 26px;
    }
    @media all and (max-width: ${maxMobile}) {
      padding: 0 16px;
      margin-top: 2px;
      height: 22px;
    }
    .ant-input-affix-wrapper {
      height: 34px;
      border: 0.8px solid #DADADA;
      padding: 6.5px 30px 6.5px 11px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        height: 28px;
        padding: 3.5px 30px 3.5px 11px;
      }
      @media all and (max-width: ${midWidthApp}) {
        height: 26px;
      }
      @media all and (max-width: ${maxMobile}) {
        height: 22px;
        padding: 3.5px 18px 3.5px 2px;
      }
      .ant-input-prefix {
        @media all and (max-width: ${maxWidth}), ${retina} {
          padding: 0px 5px 0px 8px;
        }
        .search-global-icon-svg {
          width: 12px;
          min-width: 12px;
          height: 12px;          
          @media all and (max-width: ${maxMobile}) {
            width: 10px;
            min-width: 10px;
            height: 10px;
          }
          path {
            fill: ${primaryHeadingColor};
          }
        }
      }
      .ant-input {
        font-size: 16px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 14px;
        }
        @media all and (max-width: ${midWidthApp}) {
          font-size: 12px;
        }
        @media all and (max-width: ${maxMobile}) {
          font-size: 10px;
          line-height: normal;
        }
      }
      .ant-input::placeholder {
        color: #C1C1C1;
        font-size: 16px;
        font-style: italic;
        font-weight: 400 !important;
        line-height: 18px;
        letter-spacing: -0.45px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 14px;
        }
        @media all and (max-width: ${midWidthApp}) {
          font-size: 12px;
        }
        @media all and (max-width: ${maxMobile}) {
          font-size: 10px;
          line-height: normal;
        }
      }
    }
    .cross-icon  {
      cursor: pointer;
      top: 9px;
      right: 30px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        top: 7px;
      }
      @media all and (max-width: ${midWidthApp}) {
        height: 11px;
        top: 7px;
        right: 27px;
      }
      @media all and (max-width: ${maxMobile}) {
        height: 10px;
        top: 6px;
        right: 20px;
      }
    }
  }
  .infinite-scroll-component__outerdiv {
    margin-top: 12px;
    padding: 0px 4px 0px 22px;
    padding-bottom: 8px;
    @media all and (max-width: ${midWidthApp}) {
      margin-top: 11px;
    }
    @media all and (max-width: ${maxMobile}) {
      margin-top: 8px;
      padding: 0px 4px 0px 16px;
    }
    .infinite-scroll-component {
      max-height: 152px;
      height: 152px !important;
      @media all and (max-width: ${midWidthApp}) {
        max-height: 141px;
        height: 141px !important;
      }
      ${scrollbar};
      &::-webkit-scrollbar-thumb {
        background: #DCDCDD;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      overflow: hidden auto !important;
      border-radius: 0px !important;
      .loader-div {
        .person-skeleton-div {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
        }
      }
      .no_data_found {
        height: 100%;
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}
`;

const PromptCategoryFilter = function PromptCategoryFilter() {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [unselectedPersonIds, setUnselectedPersonIds] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const unselectedIds = useSelector(selectUnselectedPersonIds);
  const searchedPage = useSelector(selectSearchedPage);
  const loading = useSelector(selectExecutivesLoading);
  const executivesEnded = useSelector(selectExecutivesEnded);
  const searchedEnded = useSelector(selectSearchedEnded);
  const executivesPage = useSelector(selectExecutivesPage);
  const allExecutives = useSelector(selectCategoryOrganizationExecutives);
  const searchedExecutives = useSelector(selectCategoryExecutivesSearched);
  const prompts = useSelector(selectPrompts);
  const selectedPromptCategory = useSelector(selectSelectedPromptCateogry);
  const organizationExecutiveForGilroy = useSelector(selectOrganizationExecutiveForGilroy);
  const executives = searchTerm?.length ? searchedExecutives : allExecutives;

  useEffect(() => {
    if (!allExecutives?.length || !organizationExecutiveForGilroy) {
      const obj = {
        page: 1,
        customCategoryUsers: true,
      };
      dispatch(actions.getOrganizationExecutives(obj));
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setUnselectedPersonIds(unselectedIds);
    }
  }, [isOpen]);

  const onSearching = (searchText: string) => {
    setSearchTerm(searchText);
    if (searchText !== '') {
      const obj = {
        page: 1,
        ...(searchText && { query: searchText }),
        customCategoryUsers: true,
      };
      dispatch(actions.getOrganizationExecutives(obj));
    }
    dispatch(actions.resetSearchedExecutives());
  };

  const fetchMore = () => {
    if (!loading) {
      if (searchTerm === '' && !executivesEnded) {
        dispatch(actions.getOrganizationExecutives({
          page: executivesPage,
          customCategoryUsers: true,
        }));
      } else if (searchTerm !== '' && !searchedEnded) {
        dispatch(actions.getOrganizationExecutives({
          page: searchedPage,
          customCategoryUsers: true,
          ...(searchTerm && { query: searchTerm }),
        }));
      }
    }
  };

  const handleCheckbox = (isChecked: boolean, personId: number) => {
    if (!isChecked) {
      setUnselectedPersonIds([...unselectedPersonIds, personId]);
    } else {
      setUnselectedPersonIds(unselectedPersonIds.filter(
        (id) => id !== personId,
      ));
    }
  };

  const handleApply = () => {
    dispatch(ChatbotActions.getPrompts({
      categoryId: selectedPromptCategory?.categoryId,
      userIds: unselectedPersonIds?.toString(),
      defaultLanguage: getLangCookie(),
    }));
    dispatch(ChatbotActions.setUnselectedPersonIds(unselectedPersonIds));
    setIsOpen(false);
  };

  const content = (
    <ContentWrapper>
      <div className="header">
        <div className="icon-text-div">
          <FilterIcon color={primaryHeadingColor} />
          <span className="div-text">Filter</span>
        </div>
        <span
          tabIndex={0}
          role="button"
          onClick={handleApply}
          onKeyPress={handleApply}
          className="apply-btn"
        >
          Apply
        </span>
      </div>
      <div className={classNames('content-div', { noResultFound: executives?.length === 0 })}>
        <h4 className="heading-text">Search by name:</h4>
        <AlertsSearch
          onSearching={onSearching}
          query={searchTerm}
          placeholderText="Search applicants"
          margin="0px"
        />
        <InfiniteScroll
          hasMore
          dataLength={executives?.length}
          next={fetchMore}
          className="o-x-hidden o-y-hidden"
          loader={false}
          height="auto"
        >
          {executives?.map((person) => (
            <CustomizableCheckbox
              personId={person.userId || 0}
              onChange={handleCheckbox}
              isChecked={!unselectedPersonIds?.includes(person.userId || 0)}
              checkboxTitle={person?.personName}
            >
              <PeopleWidgetSmall
                personaColor={backgroundColorParsing(person?.personalityTypes)}
                personImage={person?.personImage}
              />
            </CustomizableCheckbox>
          ))}
          {loading ? (
            <div className="loader-div">
              {[1, 2, 3].map(() => (
                <div className="person-skeleton-div">
                  <SkeletonCustom
                    width="18px"
                    smWidth="18px"
                    height="18px"
                    diameter="4px"
                    margin="0px 10px 0px 0px"
                    marginSm="0px 10px 0px 0px"
                  />
                  <SkeletonCustom
                    width="18px"
                    smWidth="18px"
                    height="18px"
                    diameter="50%"
                    margin="0px 10px 0px 0px"
                    marginSm="0px 10px 0px 0px"
                  />
                  <SkeletonCustom
                    width="60%"
                    smWidth="60%"
                    height="18px"
                    margin="0px 0px 0px 0px"
                    marginSm="0px 0px 0px 0px"
                  />
                </div>
              ))}
            </div>
          ) : null}
          {executives?.length === 0 && !loading ? (
            <NoDataFound title="No Person Found" />
          ) : null}
        </InfiniteScroll>
      </div>
    </ContentWrapper>
  );
  return (
    <Popover
      open={isOpen}
      placement="bottomRight"
      content={content}
      trigger="click"
      onOpenChange={setIsOpen}
      getPopupContainer={(e: HTMLElement) => e}
      className="prompt-category-popover promptFilter"
    >
      <span
        role="button"
        tabIndex={0}
        onClick={() => setActiveButton(!activeButton)}
        onKeyPress={() => setActiveButton(!activeButton)}
        aria-label="prompt-filter"
        className={classNames({ disabled: !unselectedIds?.length && !prompts?.length })}
      >
        <GlobalButton
          textColor={isOpen || unselectedIds?.length ? primaryColor : '#7E7E7E'}
          outlineColor={isOpen || unselectedIds?.length ? primaryColor : '#DADADA'}
          bgColor="#fff"
          title="All Prompts"
          IconLeft={<FilterIcon color={isOpen || unselectedIds?.length ? primaryColor : '#7E7E7E'} />}
          IconRight={<CaretDownOutlined />}
          PaddingSm="5px 8px 5px"
          PaddingXsm="5px 8px 5px"
          PaddingXxsm="5px 8px 5px"
          innerSpace="5px 8px 5px"
          RightSeparator
          buttonClassName="all-prompt-filter"
          fontSizeSm="14px"
          height="32px"
          heightSm="30px"
        />
      </span>
    </Popover>
  );
};
export default PromptCategoryFilter;
