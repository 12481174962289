import CopyMailIcon from 'Components/common/assets/svgIcons/CopyMailIcon';
import DeleteIconGilroy from 'Components/common/assets/svgIcons/DeleteIconGilroy';
import Edit from 'Components/common/assets/svgIcons/Edit';
import ThumbsDownIcon from 'Components/common/assets/svgIcons/ThumbsDownIcon';
import ThumbsUpIcon from 'Components/common/assets/svgIcons/ThumbsUpIcon';
import IconTooltipGlobal from 'Components/common/components/IconTooltipGlobal';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actions as helperActions } from 'Slice/HelperSlice';
import { selectChatLoading } from 'Selector/ChatBotV2Selector';
import { boxShadow, maxTablet, primaryColor } from 'Utils/utilsStyle';
import { getCurrentTime, replaceTags, valueToObject } from 'Utils/UtilityFunctions';
import RegenerateIcon from 'Components/common/assets/svgIcons/RegenerateIcon';
import ExpandArrowOutputAction from 'Components/common/assets/svgIcons/ExpandArrowOutputAction';
import classNames from 'classnames';
// import SingleFavoriteIcon from 'Components/common/assets/svgIcons/SingleFavoriteIcon';
import { ChatV2 } from 'Types/ChatBotV2Types';
import { actions } from 'Slice/ChatbotV2Slice';

const Wrapper = styled.div`
&.visible {
  visibility: visible;
}
&.hidden {
  visibility: hidden;
}
display: flex;
align-items: center;
.icon-tooltip-global-wrapper {
  height: 16px;
  @media all and (max-width: ${maxTablet}) {
    height: 20px;
  }
  .icon-tooltip-global {
    height: 16px;
    @media all and (max-width: ${maxTablet}) {
      height: 20px;
    }
    svg {
      height: 16px;
      width: 16px;
      margin-right: 8px;
      cursor: pointer;
      @media all and (max-width: ${maxTablet}) {
        height: 12px;
        width: 12px;
      }
    }
  }
}
.expand-arrow-output-action-svg {
  cursor: pointer;
}
.show-div {
  &.showAllDiv {
    background-color: #F2F2F2;
    border-radius: 4px;
    .expand-arrow-output-action-svg {
      transform: rotate(180deg);
    }
  }
  display: flex;
  align-items: center;
  padding: 4px 8px;
  &.hidden {
    background-color: #fff;
    box-shadow: ${boxShadow};
    margin-left: 6px;
    border-radius: 4px;
    .icon-tooltip-global-wrapper {
      &:last-child {
        .icon-tooltip-global {
          svg {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
`;
type Props = {
  chatObj: ChatV2,
  chatResponse: string,
  hideFeedbackIcon: boolean,
  visibilityFlag: boolean,
  editToggle: () => void,
  deleteToggle: () => void,
  onRegenerate: () => void,
  feedbackToggle: (a: boolean) => void,
};

const OutputActionsDiv = function OutputActionsDiv(props: Props) {
  const {
    chatResponse, editToggle, deleteToggle,
    onRegenerate, feedbackToggle, hideFeedbackIcon,
    visibilityFlag, chatObj,
  } = props;
  const {
    isFavourite, promptId, prompt, id,
  } = chatObj;
  const dispatch = useDispatch();
  const chatLoading = useSelector(selectChatLoading);
  const startTime = getCurrentTime();
  const host = window.location.href;
  const [showAll, setShowAll] = useState(false);
  const handleCopy = async (chatData: string) => {
    const chatWithoutHtml = chatData.replace(/<br\s*\/?>/g, '\n').replace(/<\/?b>/g, '').replace(/<span\b[^>]*>(.*?)<\/span>/gi, (match, capturedText: string) => capturedText);
    await navigator.clipboard.writeText(chatWithoutHtml);
    dispatch(helperActions.setNotification({
      message: 'Text Copied!',
      proTip: false,
      rightPlacement: false,
      groupId: 0,
      classnameFlag: true,
      getContainerEle: 'chatContainer',
    }));
    dispatch(helperActions.callFlurryEvent(
      valueToObject('xcgpt_copyResponse_clicked', startTime, {
        host,
      }),
    ));
  };

  const handleFavoritePrompt = () => {
    dispatch(actions.markFavoritePrompt({
      isNew: !promptId,
      isFavourite: !isFavourite,
      ...(id && { id }),
      prompt: replaceTags(prompt || ''),
      ...(promptId && { promptId }),
    }));
  };

  return (
    <Wrapper className={classNames('output-actions-div', { visible: !visibilityFlag, hidden: visibilityFlag })}>
      <div className={classNames('show-div', { showAllDiv: showAll })}>
        {/* <IconTooltipGlobal
          tooltipPlacement="bottom"
          tooltipTitle={isFavourite ? 'Remove from Favorites' : 'Add to Favorites'}
          icon={(
            <SingleFavoriteIcon
              fillColor={isFavourite ? primaryColor : '#fff'}
              strokeColor={isFavourite ? primaryColor : '#667982'}
              onClick={handleFavoritePrompt}
            />
          )}
        /> */}
        <IconTooltipGlobal
          tooltipPlacement="bottom"
          tooltipTitle="Copy"
          icon={<CopyMailIcon onClick={() => handleCopy(chatResponse)} />}
        />
        <IconTooltipGlobal
          tooltipPlacement="bottom"
          tooltipTitle="Edit"
          icon={<Edit fllColor="#667982" onClick={editToggle} />}
          disableBtn={chatLoading}
        />
        <IconTooltipGlobal
          tooltipPlacement="bottom"
          tooltipTitle="Re-generate"
          icon={<RegenerateIcon color="#667982" onClick={onRegenerate} />}
          disableBtn={chatLoading}
        />
        <ExpandArrowOutputAction color={showAll ? primaryColor : '#6A7981'} onClick={() => setShowAll(!showAll)} />
      </div>
      {showAll ? (
        <div className="show-div hidden">
          <IconTooltipGlobal
            tooltipPlacement="bottom"
            tooltipTitle="Delete"
            icon={<DeleteIconGilroy onClick={deleteToggle} />}
          />
          {hideFeedbackIcon !== true ? (
            <>
              <IconTooltipGlobal
                tooltipPlacement="bottom"
                tooltipTitle="Thumbs up"
                icon={<ThumbsUpIcon onClickAction={() => feedbackToggle(true)} />}
              />
              <IconTooltipGlobal
                tooltipPlacement="bottom"
                tooltipTitle="Thumbs Down"
                icon={<ThumbsDownIcon onClickAction={() => feedbackToggle(false)} />}
              />
            </>
          )
            : null}
        </div>
      ) : null}
    </Wrapper>
  );
};

export default OutputActionsDiv;
