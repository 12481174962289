import BackIcon from 'Components/common/assets/svgIcons/BackIcon';
import CategoryPopover from 'Components/common/components/CategoryPopover';
import HeadingWithToolTip from 'Components/common/components/HeadingWithToolTip';
import { selectCategoryPermission, selectSelectedPromptCateogry } from 'Selector/ChatBotV2Selector';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import { MidWidthApp1200 } from 'Utils/UtilityFunctions';
import {
  maxMobile,
  maxTablet,
  maxWidth,
  midWidthApp,
  primaryFont, primaryHeadingColor, retina, wMedium,
} from 'Utils/utilsStyle';
import { Divider } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledWrapper = styled.span`
&.withEditCategory {

}
display: flex;
align-items: center;
/* padding-top: 8px; */
padding-left: 14px;
padding-right: 8px;
.back-icon-svg {
  width: 18px;
  min-width: 18px;
  height: 18px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
  @media all and (max-width: ${midWidthApp}) {
    width: 18px;
    min-width: 18px;
    height: 18px;
  }
  @media all and (min-height: ${maxTablet}) {
    width: 18px;
    min-width: 18px;
    height: 18px;
  }
  @media all and (max-width: ${maxTablet}) {
    width: 12px;
    min-width: 12px;
    height: 12px;
  }
}
.ant-divider {
  height: 20px;
  border-color: #DDD;
  @media all and (max-width: ${midWidthApp}) {
    margin: 0 6px;
  }
  @media all and (max-width: ${maxTablet}){
    height: 16px;
    margin: 0px 5px;
  }
}
.back-icon-text {
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  font-size: 20px;
  line-height: 40px;
  letter-spacing: -0.792px;
  color: ${primaryHeadingColor};
  @media all and (max-width: ${midWidthApp}) {
    font-size: 18px;
    line-height: normal;
  }
  @media all and (max-width: ${maxTablet}){
    font-size: 16px;
    line-height: 26px;
  }
  }
  .global-tooltip-element {
    height: 18px;
    .icon-area {
      height: 14px;
      width: 14px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 16px;
      }
    }
  }
  .prompt-category-popover {
    margin-left: 10px;
    width: 16px;
    height: 16px;
    position: relative;
    @media all and (max-width: ${maxMobile}){
      height: 18px;
    }
    .icon-tooltip-global-wrapper {
      .icon-tooltip-global {
        .edit-icon-svg {
          width: 16px;
          min-width: 16px;
          height: 16px;
          @media all and (max-width: ${maxMobile}){
            width: 12px;
            min-width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
`;
type Props = {
  title?: string,
  tooltipText?: string,
  customClassName?: string,
  onClick?: () => void,
  editCategory?: boolean,
} & typeof defaultProps;

const defaultProps = {
  title: '',
  tooltipText: '',
  customClassName: '',
  onClick: () => { },
  editCategory: false,
};
const BackButtonSecondaryHeader = function BackButtonSecondaryHeader(props: Props) {
  const {
    title, onClick, tooltipText, editCategory, customClassName,
  } = props;
  const midWidth = MidWidthApp1200();
  const categoryPermissions = useSelector(selectCategoryPermission);
  const selectedCategory = useSelector(selectSelectedPromptCateogry);
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const isGilroyV3 = disclessPersonality ? (!window.location.pathname.includes('feed') && !window.location.pathname.includes('person'))
    : !window.location.pathname.includes('feed');
  const textareWidth = document.getElementById('back-icon-text')?.clientWidth || 100;
  const placementCheck = !!(isGilroyV3 && textareWidth > 400);
  return (
    <StyledWrapper className={classNames('back-button-secondary-header', { withEditCategory: editCategory })}>
      <BackIcon onClick={onClick} />
      <Divider type="vertical" />
      <span id="back-icon-text" className={`back-icon-text ${customClassName}`}>{disclessPersonality && title.trim() === 'Person insights' ? 'Stakeholders' : title}</span>
      {tooltipText !== '' ? (
        <HeadingWithToolTip
          toolTipText={tooltipText}
          midTabletFlag={midWidth}
          widthIcon="14px"
          heightIcon="14px"
          heightIconXSm="12px !important"
          widthIconXSm="12px !important"
        />
      ) : null}
      {editCategory && categoryPermissions && selectedCategory?.categoryId ? (
        <CategoryPopover editCategory popoverPlacement={placementCheck ? 'bottomRight' : 'bottomLeft'} />
      ) : null}
    </StyledWrapper>
  );
};
BackButtonSecondaryHeader.defaultProps = defaultProps;
export default BackButtonSecondaryHeader;
