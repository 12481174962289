import React from 'react';

const GilroyWhiteSvg = function GilroyWhiteSvg() {
  return (
    <svg width="109" height="80" viewBox="0 0 109 80" fill="none" xmlns="http://www.w3.org/2000/svg" className="gilroy-white-svg">
      <path d="M50.6348 33.0188L52.1197 8.22965L52.8007 8.2168L54.6381 33.5896L50.6348 33.0188Z" fill="#001760" />
      <path d="M52.4491 8.58904C53.3493 8.58904 54.079 7.85928 54.079 6.95907C54.079 6.05886 53.3493 5.3291 52.4491 5.3291C51.5489 5.3291 50.8191 6.05886 50.8191 6.95907C50.8191 7.85928 51.5489 8.58904 52.4491 8.58904Z" fill="#26CF0A" />
      <path d="M88.1155 39.854H80.3787C75.7225 39.854 71.948 43.6286 71.948 48.2847V54.432C71.948 59.0881 75.7225 62.8626 80.3787 62.8626H88.1155C92.7717 62.8626 96.5462 59.0881 96.5462 54.432V48.2847C96.5462 43.6286 92.7717 39.854 88.1155 39.854Z" fill="#001760" />
      <path d="M24.8917 39.854H17.1548C12.4987 39.854 8.72412 43.6286 8.72412 48.2847V54.432C8.72412 59.0881 12.4987 62.8626 17.1548 62.8626H24.8917C29.5478 62.8626 33.3223 59.0881 33.3223 54.432V48.2847C33.3223 43.6286 29.5478 39.854 24.8917 39.854Z" fill="#001760" />
      <path d="M68.3634 23.248H36.8983C25.7471 23.248 16.7073 32.2879 16.7073 43.4391V59.2762C16.7073 70.4275 25.7471 79.4673 36.8983 79.4673H68.3634C79.5146 79.4673 88.5544 70.4275 88.5544 59.2762V43.4391C88.5544 32.2879 79.5146 23.248 68.3634 23.248Z" fill="#26CF0A" />
      <path d="M68.6554 30.6621H36.6214C29.7279 30.6621 24.1396 36.2504 24.1396 43.1439V59.572C24.1396 66.4655 29.7279 72.0538 36.6214 72.0538H68.6554C75.5489 72.0538 81.1372 66.4655 81.1372 59.572V43.1439C81.1372 36.2504 75.5489 30.6621 68.6554 30.6621Z" fill="white" />
      <path style={{ mixBlendMode: 'hard-light' }} opacity="0.3" d="M74.3309 38.4157H30.9384V36.8169H74.3309V38.4157ZM79.0849 39.5886H26.1862V41.1873H79.0849V39.5886ZM83.0828 42.3015H22.1884V43.9003H83.0828V42.3015ZM85.6177 45.0714H19.6535V46.6701H85.6177V45.0714ZM87.3908 47.8412H17.8785V49.44H87.3908V47.8412ZM88.9125 50.6111H16.3586V52.2098H88.9125V50.6111ZM88.9125 53.324H16.3586V54.9228H88.9125V53.324ZM87.3908 56.0939H17.8785V57.6926H87.3908V56.0939ZM84.687 58.8178H20.5822V60.4166H84.687V58.8178ZM79.7035 61.5877H25.5676V63.1864H79.7035V61.5877ZM68.213 64.3006H37.0582V65.8994H68.213V64.3006Z" fill="url(#paint0_radial_279_5001)" />
      <path d="M46.8411 59.4033C50.7177 60.8699 54.5485 61.0021 58.4307 59.9521C55.8481 64.7007 49.9468 65.5726 46.8411 59.4033Z" fill="#26CF0A" />
      <path d="M44.1782 46.5567C44.2039 49.0219 42.2252 51.0391 39.7619 51.0667C37.2968 51.0924 35.2795 49.1136 35.252 46.6503C35.2263 44.1852 37.205 42.1679 39.6683 42.1404C42.1334 42.1147 44.1507 44.0934 44.1782 46.5567Z" fill="url(#paint1_linear_279_5001)" />
      <path d="M41.8217 44.4328C41.618 44.7962 40.8635 44.7577 40.1385 44.3502C39.4135 43.9427 38.9913 43.3168 39.195 42.9551C39.3988 42.5917 40.1532 42.6303 40.8782 43.0377C41.6033 43.4452 42.0254 44.0712 41.8217 44.4328Z" fill="#FDFDFD" />
      <path d="M39.7376 48.9153C37.6433 48.9373 35.8775 47.5092 35.3745 45.5727C35.2919 45.9178 35.246 46.2794 35.2497 46.6484C35.2754 49.1135 37.2945 51.0904 39.7597 51.0647C42.2248 51.039 44.2017 49.0199 44.176 46.5548C44.1705 46.1821 44.1191 45.8242 44.0292 45.481C43.5666 47.4303 41.8302 48.8932 39.7358 48.9153H39.7376Z" fill="#26CF0A" />
      <path d="M70.022 46.3873C70.0477 48.8524 68.069 50.8697 65.6057 50.8972C63.1405 50.9229 61.1232 48.9442 61.0957 46.4809C61.07 44.0158 63.0487 41.9985 65.512 41.971C67.9772 41.9453 69.9945 43.924 70.022 46.3873Z" fill="url(#paint2_linear_279_5001)" />
      <path d="M67.6601 44.2643C67.4563 44.6278 66.7019 44.5892 65.9769 44.1817C65.2518 43.7742 64.8297 43.1483 65.0334 42.7867C65.2372 42.4233 65.9916 42.4618 66.7166 42.8693C67.4417 43.2768 67.8638 43.9027 67.6601 44.2643Z" fill="#FDFDFD" />
      <path d="M65.5811 48.7444C63.4868 48.7664 61.721 47.3383 61.218 45.4018C61.1354 45.7469 61.0896 46.1085 61.0932 46.4775C61.1189 48.9426 63.138 50.9195 65.6032 50.8938C68.0683 50.8681 70.0452 48.849 70.0195 46.3839C70.014 46.0112 69.9626 45.6533 69.8727 45.3101C69.4101 47.2594 67.6737 48.7223 65.5793 48.7444H65.5811Z" fill="#26CF0A" />
      <path d="M16.1038 24.6732C16.1038 24.6732 10.8346 26.7336 9.28798 26.9486C7.74137 27.1636 -0.109546 9.99026 0.43049 8.54453C0.970527 7.09879 23.3305 0.539669 24.3769 0.971956C25.4234 1.40424 25.2705 20.4094 24.8386 21.096C24.4067 21.7825 20.0958 23.4748 20.0958 23.4748C20.0958 23.4748 21.1862 26.2253 23.5627 26.124C23.5674 26.1224 18.524 28.4983 16.1038 24.6732Z" fill="#FDBC1A" />
      <path d="M5.99159 10.6292C5.99159 10.6292 10.9558 22.4511 11.1055 22.4304C11.2553 22.4096 12.8364 21.942 12.8197 21.5258C12.803 21.1096 10.7107 16.2039 10.847 16.1584C10.9833 16.1129 12.7326 15.3189 12.9305 15.4629C13.1284 15.6069 14.3964 20.5707 14.6567 20.6336C14.917 20.6965 16.2363 20.3163 16.1825 19.9745C16.1286 19.6326 12.5497 8.37093 12.3039 8.35658C12.0581 8.34223 10.8414 8.48326 10.8416 8.84476C10.8418 9.20626 12.275 13.948 12.0127 14.0648C11.7504 14.1816 10.5794 14.8153 10.3986 14.6347C10.2177 14.454 8.29189 9.76828 8.03412 9.61502C7.77635 9.46175 5.83908 10.2652 5.99159 10.6292Z" fill="white" />
      <path d="M15.2674 12.5267C15.2674 12.5267 16.9031 19.5923 17.0756 19.6552C17.2481 19.7182 18.8257 19.4928 18.8074 19.0771C18.7892 18.6613 16.7741 12.084 16.4286 11.9514C16.083 11.8189 15.1753 12.1322 15.2674 12.5267Z" fill="white" />
      <path d="M15.5435 10.2997C16.1416 10.1001 16.4645 9.45344 16.2648 8.85543C16.0652 8.25742 15.4186 7.93448 14.8206 8.13412C14.2226 8.33376 13.8996 8.98039 14.0993 9.57839C14.2989 10.1764 14.9455 10.4993 15.5435 10.2997Z" fill="white" />
      <path d="M16.7725 6.83584C16.7725 6.83584 18.92 16.363 19.1994 16.4057C19.4788 16.4485 20.0443 16.4095 20.0932 16.1073C20.1422 15.8052 18.6789 6.28549 18.3911 6.05271C18.1034 5.81993 16.6679 6.37834 16.7725 6.83584Z" fill="white" />
      <path d="M20.4027 18.6046C20.8433 18.4575 21.0812 17.9811 20.9341 17.5405C20.787 17.0999 20.3106 16.862 19.87 17.0091C19.4294 17.1562 19.1915 17.6326 19.3386 18.0732C19.4857 18.5137 19.9621 18.7517 20.4027 18.6046Z" fill="white" />
      <circle cx="79.8396" cy="8.55956" r="3.23046" fill="white" />
      <rect x="80.4854" y="7.26758" width="23.2593" height="5.81482" fill="#26CF0A" />
      <path d="M100.594 2.09863H82.344C78.1067 2.09863 74.6709 5.52696 74.6709 9.75506C74.6709 13.9832 78.1067 17.4115 82.344 17.4115H84.5242V23.1538C84.5242 24.0016 85.5822 24.3881 86.1299 23.743L91.5214 17.4115H100.594C104.832 17.4115 108.268 13.9832 108.268 9.75506C108.268 5.52696 104.832 2.09863 100.594 2.09863ZM85.3551 12.2183C84.1074 12.2183 83.0975 11.208 83.0975 9.96301C83.0975 8.71804 84.11 7.70766 85.3551 7.70766C86.6001 7.70766 87.6153 8.71804 87.6153 9.96301C87.6153 11.208 86.6028 12.2183 85.3551 12.2183ZM91.4679 12.2183C90.2202 12.2183 89.2077 11.208 89.2077 9.96301C89.2077 8.71804 90.2202 7.70766 91.4679 7.70766C92.7156 7.70766 93.7255 8.71804 93.7255 9.96301C93.7255 11.208 92.713 12.2183 91.4679 12.2183ZM97.5781 12.2183C96.3304 12.2183 95.3179 11.208 95.3179 9.96301C95.3179 8.71804 96.3304 7.70766 97.5781 7.70766C98.8258 7.70766 99.8357 8.71804 99.8357 9.96301C99.8357 11.208 98.8232 12.2183 97.5781 12.2183Z" fill="#001760" />
      <defs>
        <radialGradient id="paint0_radial_279_5001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52.7095 51.3581) scale(30.0312 19.8882)">
          <stop stopColor="#26CF0A" />
          <stop offset="0.23" stopColor="#43B430" />
          <stop offset="0.52" stopColor="#64985B" />
          <stop offset="0.75" stopColor="#788676" />
          <stop offset="0.88" stopColor="#808080" />
        </radialGradient>
        <linearGradient id="paint1_linear_279_5001" x1="35.252" y1="46.6008" x2="44.1782" y2="46.6008" gradientUnits="userSpaceOnUse">
          <stop stopColor="#21AD04" />
          <stop offset="0.83" stopColor="#26CF0A" />
          <stop offset="0.99" stopColor="#26CF0A" />
        </linearGradient>
        <linearGradient id="paint2_linear_279_5001" x1="70.022" y1="46.4313" x2="61.0957" y2="46.4313" gradientUnits="userSpaceOnUse">
          <stop stopColor="#21AD04" />
          <stop offset="1" stopColor="#26CF0A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default GilroyWhiteSvg;
