import React from 'react';

type Props = {
  className?: string;
  color?: string,
  onClick?: () => void,
} & typeof defaultProps;

const defaultProps = {
  className: '',
  color: '#606060',
  onClick: () => { },
};

const CrossCircle = function CrossCircle(props: Props) {
  const { color, className, onClick } = props;
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" className={`cross-circle-svg ${className}`} onClick={onClick}>
      <rect width="29" height="29" fill="none" />
      <circle opacity="0.543816" cx="14.5" cy="14.5" r="14.5" fill={color} fillOpacity="0.620013" />
      <path d="M9.50818 19.7311L19.7312 9.50815" stroke="white" strokeWidth="3" strokeLinecap="square" />
      <path d="M9.50812 9.50843L19.7311 19.7314" stroke="white" strokeWidth="3" strokeLinecap="square" />
    </svg>
  );
};
CrossCircle.defaultProps = defaultProps;
export default CrossCircle;
