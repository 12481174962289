/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SettingState, SubmitVerificationCodeData } from 'Types/GlobalTypes';
import { SendVerification, SettingsRequest } from 'Types/RequestBodies';

export const initialState: SettingState = {
  updateLoading: false,
  settingUpdated: false,
  subscription: '',
  sendVerificationLoading: false,
  submitVerificationCode: {
    loading: false,
    loaded: false,
    data: undefined,
  },
};

const sriSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    updateSetting(state, action: PayloadAction<SettingsRequest>) {
      const { subscription } = action.payload;
      state.updateLoading = true;
      state.settingUpdated = false;
      state.subscription = subscription;
    },
    updateSettingSuccess(state) {
      state.updateLoading = false;
      state.settingUpdated = true;
      state.submitVerificationCode.loaded = false;
    },
    updateSettingfail(state) {
      state.updateLoading = false;
      state.settingUpdated = false;
    },
    sendVerification(state, action: PayloadAction<SendVerification>) {
      state.sendVerificationLoading = true;
    },
    setSendVerification(state) {
      state.sendVerificationLoading = false;
    },
    setVerificationCodeResponse(state, action) {
      state.submitVerificationCode.loading = false;
      state.submitVerificationCode.loaded = true;
      state.submitVerificationCode.data = action?.payload as SubmitVerificationCodeData;
    },
    verifyCode(state, action: PayloadAction<SendVerification>) {
      state.sendVerificationLoading = true;
      state.submitVerificationCode.loading = true;
      state.submitVerificationCode.loaded = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = sriSlice;
