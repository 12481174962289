import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'Slice/HelperSlice';

import { RootState } from 'Store/InjectorsTypes';

const selectDomain = (state: RootState) => state?.helper || initialState;

export const notificationMessage = createSelector(
  [selectDomain],
  (state) => state.message,
);
export const protipFlag = createSelector(
  [selectDomain],
  (state) => state.proTip,
);
export const rightPlacementFlag = createSelector(
  [selectDomain],
  (state) => state.rightPlacement,
);
export const groupId = createSelector(
  [selectDomain],
  (state) => state.groupId,
);
export const classnameFlag = createSelector(
  [selectDomain],
  (state) => state.classnameFlag,
);

export const showWarning = createSelector(
  [selectDomain],
  (state) => state.showWarning,
);

export const certificationFlag = createSelector(
  [selectDomain],
  (state) => state.certification,
);

export const accountPlanFlag = createSelector(
  [selectDomain],
  (state) => state.accountPlanFlag,
);

export const messageType = createSelector(
  [selectDomain],
  (state) => state.type,
);

export const companyId = createSelector(
  [selectDomain],
  (state) => state.companyId,
);

export const getContainerEle = createSelector(
  [selectDomain],
  (state) => state.getContainerEle,
);

export const selectClassName = createSelector(
  [selectDomain],
  (state) => state.className,
);
