import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import {
  maxMobile, primaryColor,
  maxWidth, primaryFont, retina, wMedium,
  midWidthApp,
} from 'Utils/utilsStyle';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media all and (max-width: ${maxMobile}) {
    height: 14px;
    margin-bottom: 8px;
  }
  .ant-checkbox-wrapper {
    width: unset;
    margin-bottom: 0px;
    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border: 2px solid ${primaryColor};
          @media all and (max-width: ${maxMobile}) {
            border: 1px solid ${primaryColor};
          }
          &::after {
            @media all and (max-width: ${maxMobile}) {
              transform: rotate(45deg) scale(0.9) translate(-50%, -50%);
              top: 44%;
            }
          }
        }
        &::after {
          border: 0px;
          border-radius: 2px;
          @media all and (max-width: ${maxMobile}) {
            border: 1px solid ${primaryColor};
          }
        }
      }
      .ant-checkbox-inner {
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 18px;
          height: 18px;
          border-radius: 4px;
        }
        @media all and (max-width: ${midWidthApp}) {
          width: 16px;
          height: 16px;
        }
        @media all and (max-width: ${maxMobile}) {
          width: 14px;
          height: 14px;
          border-radius: 3px;
          border-width: 1px;
        }
      }
    }
  }
  .checkbox-title {
    color: #0D0D0D;
    font-family: ${primaryFont};
    font-size: 16px;
    font-weight: ${wMedium};
    line-height: 20px;
    letter-spacing: -0.4px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      font-size: 14px;
    }
    @media all and (max-width: ${midWidthApp}) {
      font-size: 12px;
    }
    @media all and (max-width: ${maxMobile}) {
      font-size: 10px;
    }
  }
`;

type Props = {
  checkboxTitle?: string,
  customClassName?: string,
  children?: ReactNode,
  onChange: (isChecked: boolean, personId: number) => void,
  isChecked: boolean,
  personId: number,
} & typeof defaultProps;

const defaultProps = {
  checkboxTitle: '',
  children: <span className="no-children" />,
  customClassName: '',
};

const CustomizableCheckbox = function CustomizableCheckbox(props: Props) {
  const {
    checkboxTitle, children, customClassName, onChange, isChecked, personId,
  } = props;

  return (
    <Wrapper className={classNames(`customizable-checkbox-wrapper,${customClassName}`)}>
      <Checkbox
        onChange={(e) => onChange(e.target.checked, personId)}
        checked={isChecked}
      />
      {children}
      <span className="checkbox-title text-truncate">{checkboxTitle}</span>
    </Wrapper>
  );
};

CustomizableCheckbox.defaultProps = defaultProps;
export default CustomizableCheckbox;
