/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UIState, ViewInterface } from 'Types/GlobalTypes';
import { SelectedGroupCategory } from 'Types/BuyerGroupsPageTypes';
import { getLocalStorageObject } from '../Utils/UtilityFunctions';

const getPath = getLocalStorageObject('ViewTab') as ViewInterface;

export const initialState: UIState = {
  isTrue: false,
  isStickyState: false,
  isSpotLightInView: false,
  isSearchOpen: false,
  isCertification: false,
  groupTitleName: '',
  isPersonDrag: false,
  tabView: { view: getPath.view } as ViewInterface,
  isDrawerOpen: false,
  isDrawerPanelOpen: false,
  isGilroyTextHeight: false,
  isShowGilroyGlobal: false,
  isEditChatOpen: false,
  isShowAskGif: false,
  isSimilarArticleScroll: false,
  onBoardingSlide: 0,
  isSimilarArticleScrollItem: false,
  expandFlag: false,
  companyPrompt: false,
  onlyFavorites: false,
  groupCategory: {
    category: '',
    stage: '',
  },
};

const userUiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    changeUIState(state, action: PayloadAction<boolean>) {
      state.isTrue = action.payload;
    },
    changeUIStickyState(state, action: PayloadAction<boolean>) {
      state.isStickyState = action.payload;
    },
    spotlightInView(state, action: PayloadAction<boolean>) {
      state.isSpotLightInView = action.payload;
    },
    isSearchOpen(state, action: PayloadAction<boolean>) {
      state.isSearchOpen = action.payload;
    },
    isCertification(state, action: PayloadAction<boolean>) {
      state.isCertification = action.payload;
    },
    groupsTitleName(state, action: PayloadAction<string>) {
      state.groupTitleName = action.payload;
    },
    setGroupCategory(state, action: PayloadAction<SelectedGroupCategory>) {
      state.groupCategory = action.payload;
    },
    isPersonDrag(state, action: PayloadAction<boolean>) {
      state.isPersonDrag = action.payload;
    },
    isTabView(state, action: PayloadAction<ViewInterface>) {
      state.tabView = action.payload;
    },
    isDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
    },
    setOnBoardingSlide(state, action: PayloadAction<number>) {
      state.onBoardingSlide = action.payload;
    },
    isSimilarArticleScroll(state, action: PayloadAction<boolean>) {
      state.isSimilarArticleScroll = action.payload;
    },
    isSimilarArticleScrollItem(state, action: PayloadAction<boolean>) {
      state.isSimilarArticleScrollItem = action.payload;
    },
    isDrawerPanelOpen(state, action: PayloadAction<boolean>) {
      state.isDrawerPanelOpen = action.payload;
    },
    isGilroyTextHeightIncrease(state, action: PayloadAction<boolean>) {
      state.isGilroyTextHeight = action.payload;
    },
    isShowGilroyGlobalToggle(state, action: PayloadAction<boolean>) {
      state.isShowGilroyGlobal = action.payload;
    },
    isEditChatOpenInput(state, action: PayloadAction<boolean>) {
      state.isEditChatOpen = action.payload;
    },
    isShowAskGifToggle(state, action: PayloadAction<boolean>) {
      state.isShowAskGif = action.payload;
    },
    isExpandFlag(state, action: PayloadAction<boolean>) {
      state.expandFlag = action.payload;
    },
    isCompanyPrompt(state, action: PayloadAction<boolean>) {
      state.companyPrompt = action.payload;
    },
    isOnlyFavorites(state, action: PayloadAction<boolean>) {
      state.onlyFavorites = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = userUiSlice;
