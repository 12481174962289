/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */

import {
  call, put, takeLatest, takeEvery,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';

import { actions } from 'Slice/BuyerGroupsPageSlice';

import { API } from 'Utils/Constants';
import {
  ChangeCategory,
  EditGroupTitleRequest, EditReason, GetBuyerGroupPageMembersRequest,
  GetNotMembers, GetReasons, GetTopPeopleRequest,
} from 'Types/RequestBodies';
import {
  CategoryStages,
  ChangeCategoryResponse,
  GroupRenameDataResponse, HighlightsBuyersDataResponse,
  MemberCategories,
  MembersDataResponse, MembersDataResponseV2, TopPeopleDataResponse,
} from 'Types/BuyerGroupsPageTypes';
import { FollowedPeopleResponse } from 'Types/CompaniesTypes';
import { actions as helperActions } from 'Slice/HelperSlice';
import { getCurrentTime, stringToCamelCase, valueToObject } from 'Utils/UtilityFunctions';
import { RecommendedPeopleResponse } from 'Types/PersonTypes';
import { TopGroupPeopleResponse } from '../Types/BuyerGroupsPageTypes.d';

export function* getTopPeople(dispatched: PayloadAction<GetTopPeopleRequest>) {
  try {
    const response: TopGroupPeopleResponse = yield call(
      axios.post,
      API.BUYER_GROUPS_PAGE_TOP_PEOPLE,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setTopPeople(response.response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getHighlights(dispatched: PayloadAction<GetTopPeopleRequest>) {
  const startTime = getCurrentTime();

  try {
    const response: HighlightsBuyersDataResponse = yield call(
      axios.post,
      API.BUYER_GROUPS_PAGE_HIGHLIGHTS,
      dispatched?.payload,
    );
    yield put(actions.setHighlights(response.response));
    yield put(helperActions.callFlurryEvent(
      valueToObject('buyer_group_highlights_loaded', startTime, dispatched?.payload),
    ));
  } catch (err) {
    console.log(err);
  }
}

export function* getMembers(dispatched: PayloadAction<GetBuyerGroupPageMembersRequest>) {
  const startTime = getCurrentTime();

  try {
    const response: MembersDataResponse = yield call(
      axios.post,
      API.BUYER_GROUPS_PAGE_MEMBERS,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.setMembers({
        ...response.response[0],
        groupId: dispatched?.payload.groupId,
      }));
      if (dispatched?.payload?.filterRequired === 1) {
        yield put(actions.setTotalMembers(response.response[0].membersCount));
      }
      if (response.response[1].data.length) yield put(actions.setFilterArray(response.response[1]));
      yield put(helperActions.callFlurryEvent(
        valueToObject('buyer_group_members_loaded', startTime, dispatched?.payload),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* editTitleOfGroup(dispatched: PayloadAction<EditGroupTitleRequest>) {
  const startTime = getCurrentTime();

  try {
    const response: GroupRenameDataResponse = yield call(
      axios.post,
      API.BUYER_GROUPS_RENAME_GROUP,
      dispatched?.payload,
    );

    if (response?.status === true) {
      yield put(actions.handleEditedTitleOfGroup(dispatched.payload.groupName));
      yield put(helperActions.callFlurryEvent(
        valueToObject('buyer_group_name_edit_completed', startTime, dispatched?.payload),
      ));
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: true,
      }));
    } else {
      yield put(actions.titleUpdateLoadingForce(true));
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getNotMembers(dispatched: PayloadAction<GetNotMembers>) {
  const startTime = getCurrentTime();

  try {
    const response: FollowedPeopleResponse = yield call(
      axios.post,
      API.GET_FOLLOWED_PEOPLE,
      dispatched?.payload,
    );
    if (response.status === true) {
      const { members, followedPeople } = response.response[0];
      yield put(actions.NotMembersInGroup({ members, followedPeople }));

      yield put(helperActions.callFlurryEvent(
        valueToObject('buyer_group_followed_people_loaded', startTime, dispatched?.payload),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getRecommendedPeople(dispatched: PayloadAction<GetNotMembers>) {
  const startTime = getCurrentTime();

  try {
    const response: FollowedPeopleResponse = yield call(
      axios.post,
      API.BUYER_GROUP_RECOMMENDED_PEOPLE,
      dispatched?.payload,
    );
    if (response.status === true) {
      const { members, type, page } = response.response[0];
      yield put(actions.recommendedPeopleInGroup(response.response[0]));
      yield put(helperActions.callFlurryEvent(
        valueToObject('loaded_recommended_buyerGroup', startTime, dispatched?.payload),
      ));
    } else {
      yield put(actions.recommendedPeopleInGroup({ members: [] }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* changeCategory(dispatched: PayloadAction<ChangeCategory>) {
  try {
    const response: ChangeCategoryResponse = yield call(
      axios.post,
      API.BUYER_GROUPS_CHANGE_CATEGORY,
      dispatched?.payload,
    );
    if (response.status === true) {
      // yield put(actions.setChangeCategory(dispatched?.payload));
      const message = `Category changed from ${dispatched?.payload.categoryPrev} to ${dispatched?.payload.categoryNext} successfully`;
      yield put(helperActions.setNotification({
        message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    }
    yield put(actions.setReasonPopupLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getMembersV2(dispatched: PayloadAction<GetBuyerGroupPageMembersRequest>) {
  const startTime = getCurrentTime();

  try {
    const response: MembersDataResponseV2 = yield call(
      axios.post,
      API.GET_BUYER_GROUP_MEMBERS,
      dispatched?.payload,
    );

    if (response?.status === true) {
      const {
        data, filters, totalMembers, toolTip,
      } = response.response;
      const membersArray = Array.isArray(data) ? data : [data];
      yield put(actions.setMembersV2({
        offset: dispatched?.payload?.offset || 0,
        members: membersArray,
        totalMembers,
        tooltip: toolTip,
      }));
      if (filters?.length) yield put(actions.setFilterArrayV2(filters));
      yield put(helperActions.callFlurryEvent(
        valueToObject('buyer_group_members_loaded', startTime, dispatched?.payload),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* changeCategoryV2(dispatched: PayloadAction<ChangeCategory>) {
  try {
    const response: ChangeCategoryResponse = yield call(
      axios.post,
      API.BUYER_GROUPS_CHANGE_CATEGORY_V2,
      dispatched?.payload,
    );
    if (response.status === true) {
      const {
        stageNext, stagePrev, categoryNext, categoryPrev,
      } = dispatched.payload;
      yield put(actions.setChangeCategory({
        ...dispatched?.payload,
        categoryNext: stringToCamelCase(categoryNext) as keyof MemberCategories,
        categoryPrev: stringToCamelCase(categoryPrev) as keyof MemberCategories,
        stageNext: stageNext as keyof CategoryStages,
        stagePrev: stagePrev as keyof CategoryStages,
      }));
      const message = `Category changed from ${dispatched?.payload.categoryPrev} to ${dispatched?.payload.categoryNext} successfully`;
      yield put(helperActions.setNotification({
        message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    }
    yield put(actions.setReasonPopupLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export function* BuyerGroupsPageSaga() {
  yield takeLatest(actions.getTopPeople.type, getTopPeople);
  yield takeLatest(actions.getHighlights.type, getHighlights);
  yield takeLatest(actions.getMembers.type, getMembers);
  yield takeLatest(actions.editTitleOfGroup.type, editTitleOfGroup);
  yield takeLatest(actions.getMembersNotInGroup.type, getNotMembers);
  yield takeLatest(actions.getRecommendedPeople.type, getRecommendedPeople);
  yield takeLatest(actions.changeCategory.type, changeCategory);
  yield takeEvery(actions.getMembersV2.type, getMembersV2);
  yield takeLatest(actions.changeCategoryV2.type, changeCategoryV2);
}
