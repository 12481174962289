/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/PersonProfileSlice';

const selectDomain = (state: RootState) => state?.personProfile || initialState;
export const profileLoading = createSelector([selectDomain], (state) => state.profile.loading);
export const profileFollowLoading = createSelector(
  [selectDomain],
  (state) => state.profile.followLoading,
);
export const personCard = createSelector(
  [selectDomain],
  (state) => state.profile.data.personCard,
);
export const personPersonalityInfo = createSelector(
  [selectDomain],
  (state) => state.profile.data.personalityInfo,
);
export const personSocialLinks = createSelector(
  [selectDomain],
  (state) => state.profile.data.socialLinks?.data,
);

export const personProfileName = createSelector(
  [selectDomain],
  (state) => state.profile.data?.personCard?.personName,
);

export const selectPersonDossierAllowed = createSelector(
  [selectDomain],
  (state) => state.dossierAllowed,
);
export const personIntentData = createSelector(
  [selectDomain],
  (state) => state.intent,
);
export const personProfilePersonalDetails = createSelector(
  [selectDomain],
  (state) => state.personalDetails,
);
export const selectInsightsLoading = createSelector(
  [selectDomain],
  (state) => state.insights.loading,
);
export const selectInsightsData = createSelector(
  [selectDomain],
  (state) => state.insights.insightsData,
);
export const selectSimilarLoading = createSelector(
  [selectDomain],
  (state) => state.similarPeopleLoading,
);

export const personNews = createSelector([selectDomain], (state) => state.news);
export const personVideos = createSelector([selectDomain], (state) => state.video);
export const personCareer = createSelector([selectDomain], (state) => state.career);
export const personProfileDownloadLoading = createSelector(
  [selectDomain],
  (state) => state.downloadProfile.loading,
);

export const personCardPersonId = createSelector(
  [selectDomain],
  (state) => state.profile?.data?.personCard?.personId,
);

export const personProfilePopups = createSelector(
  [selectDomain],
  (state) => state.popups,
);

export const navigationTabsPersonProfile = createSelector(
  [selectDomain],
  (state) => state.tabs,
);

export const personWorkbenchData = createSelector(
  [selectDomain],
  (state) => state.workbenchStats.data,
);

export const personWorkbenchLoading = createSelector(
  [selectDomain],
  (state) => state.workbenchStats.loading,
);

export const selectIsEmptyStats = createSelector(
  [selectDomain],
  (state) => state.workbenchStats.isEmptyWorkbenchStats,
);

export const selectCardResult = createSelector(
  [selectDomain],
  (state) => state.cardResult,
);

export const selectPersonNotesAvaliable = createSelector(
  [selectDomain],
  (state) => state.personNotesAvailable,
);

export const selectNewNotes = createSelector(
  [selectDomain],
  (state) => state.newNotes,
);
export const selectLanguagePopup = createSelector(
  [selectDomain],
  (state) => state.showLanguagePopup,
);
export const selectGptAllowed = createSelector(
  [selectDomain],
  (state) => state.chatGptAllowed,
);
export const selectSocialFlurry = createSelector(
  [selectDomain],
  (state) => state.socialFlurry,
);

export const selectVideoFlurry = createSelector(
  [selectDomain],
  (state) => state.videoFlurry,
);

export const selectNewsFlurry = createSelector(
  [selectDomain],
  (state) => state.newsFlurry,
);

export const selectSocialCall = createSelector(
  [selectDomain],
  (state) => state.socialApiCall,
);

export const selectVideoCall = createSelector(
  [selectDomain],
  (state) => state.videoApiCall,
);

export const selectNewsCall = createSelector(
  [selectDomain],
  (state) => state.newsApiCall,
);
export const selectMessage = createSelector([selectDomain], (state) => state.message);

export const selectSearchedLoading = createSelector(
  [selectDomain],
  (state) => state.searchedPerson.loading,
);
export const selectSearchedPersonData = createSelector(
  [selectDomain],
  (state) => state.searchedPerson.insightsData,
);
export const selectSearchedPerson = createSelector(
  [selectDomain],
  (state) => state.searchedPerson.selectedPerson,
);
export const selectBookADemoURL = createSelector(
  [selectDomain],
  (state) => state.bookADemoURL,
);

export const selectPersonalityDrawerOpen = createSelector(
  [selectDomain],
  (state) => state.personalityDrawerOpen,
);

export const selectFeedbackSubmitted = createSelector(
  [selectDomain],
  (state) => state.feedbackSubmitted,
);

export const selectSimilarPeopleData = createSelector(
  [selectDomain],
  (state) => state.similarPeopleData,
);

export const selectExportSimilarPeopleLoading = createSelector(
  [selectDomain],
  (state) => state.exportSimilarPeopleLoading,
);

export const selectSimilarPeopleFilters = createSelector(
  [selectDomain],
  (state) => state.similarPeopleFilters,
);

export const selectSavedProfile = createSelector(
  [selectDomain],
  (state) => state.similarPeopleData.savedId,
);

export const selectEditPhoneEmailLoading = createSelector(
  [selectDomain],
  (state) => state.editPhoneEmailLoading,
);

export const selectIsEditingPhone = createSelector(
  [selectDomain],
  (state) => state.isEditingPhone,
);

export const selectIsEditingEmail = createSelector(
  [selectDomain],
  (state) => state.isEditingEmail,
);

export const selectIsRecommended = createSelector(
  [selectDomain],
  (state) => state.isRecommended,
);

export const selectPersonThemes = createSelector(
  [selectDomain],
  (state) => state.personThemeResponse,
);

export const selectShowDigestPopup = createSelector(
  [selectDomain],
  (state) => state.showDigestPopup,
);

export const selectRedirectWorkbench = createSelector(
  [selectDomain],
  (state) => state.redirectWorkbench,
);

export const selectCreateDigestData = createSelector(
  [selectDomain],
  (state) => state.createDigestData,
);

export const selectPersonDigestLoading = createSelector(
  [selectDomain],
  (state) => state.personDigestLoading,
);

export const selectThemeLoading = createSelector(
  [selectDomain],
  (state) => state.personThemeLoading,
);
