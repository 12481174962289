import { primaryFont, primaryHeadingColor, wMBold } from 'Utils/utilsStyle';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import classNames from 'classnames';
import React, { useState } from 'react';
import styled from 'styled-components';

const TooltipWrapper = styled(Tooltip) <{ arrowAlignment?: string, outputAction?: boolean }>`
.ant-tooltip-content {
  .ant-tooltip-arrow {
    transform: ${(props) => (props.outputAction ? 'translateX(97%) translateY(-50%)' : '')};
    .ant-tooltip-arrow-content {
      left: ${(props) => props.arrowAlignment};
    }
  }
  width: fit-content;
  .ant-tooltip-inner {
    color: ${primaryHeadingColor};
    font-family: ${primaryFont};
    font-weight: ${wMBold};
    font-style: normal !important;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.4px;
    padding: 7px 16px;
    border-radius: 5px;
    min-height: unset;
    white-space: pre;
  }
}
`;
type Props = {
  icon: object,
  tooltipPlacement: TooltipPlacement,
  tooltipTitle: string,
  customClassName?: string,
  arrowAlignment?: string,
  disableBtn?: boolean,
  outputAction?: boolean,
};
const defaultProps = {
  arrowAlignment: '',
  customClassName: '',
  disableBtn: false,
  outputAction: false,
};

const IconTooltipGlobal = function IconTooltipGlobal(props: Props) {
  const {
    icon, tooltipPlacement, tooltipTitle,
    arrowAlignment, disableBtn, outputAction,
    customClassName,
  } = props;
  const [visible, setVisible] = useState(false);

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleClick = () => {
    setVisible(false);
  };
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`icon-tooltip-global-wrapper ${customClassName}`}
    >
      {disableBtn ? (
        <span className={classNames('icon-tooltip-global', { disabled: disableBtn })}>
          {icon}
        </span>
      ) : (
        <TooltipWrapper
          arrowAlignment={arrowAlignment}
          outputAction={outputAction}
          placement={tooltipPlacement}
          getPopupContainer={(e: HTMLElement) => e}
          color="#fff"
          title={tooltipTitle}
          className={classNames('icon-tooltip-global')}
          mouseLeaveDelay={0}
          visible={visible}
        >
          <span>
            {icon}
          </span>
        </TooltipWrapper>
      )}

    </div>
  );
};
IconTooltipGlobal.defaultProps = defaultProps;
export default IconTooltipGlobal;
