import { createGlobalStyle } from 'styled-components';
import {
  boxShadow,
  maxMobile,
  maxTablet,
  maxWidth,
  midMobile,
  midWidthApp,
  minExtraLargeScreen,
  minStandardScreen,
  primaryColor,
  primaryFont, primaryHeadingColor, retina, textTruncateSnippetFour, wMBold, wMedium,
} from '../Utils/utilsStyle';

const GeneralStyleV2 = createGlobalStyle`
.image-title-tooltip-gilroy {
  .ant-tooltip-content {
    border-radius: 8px;
    .ant-tooltip-inner {
      border-radius: 8px;
      color: ${primaryHeadingColor};
      font-weight: ${wMedium};
      font-style: ${primaryFont};
      max-height: 97px;
      overflow: hidden;
      ${textTruncateSnippetFour};
    }
  }
}
.prompt-category-popover {
  &.categoryPopover {
    .ant-popover {
      .ant-popover-content {
        .ant-popover-inner {
          @media all and (max-width: ${midWidthApp}) {
            width: 238px;
            height: 276px;
          }
          @media all and (max-width: ${maxMobile}) {
            width: 203px;
            height: 220px;
          }
        }
      }
    }
  }
  &.promptFilter {
    .ant-popover {
      .ant-popover-content {
        .ant-popover-inner {
          width: 264px;
          height: 281px;
          @media all and (max-width: ${midWidthApp}) {
            width: 249px;
            height: 266px;
          }
          @media all and (max-width: ${maxMobile}) {
            width: 214px;
            height: 227px;
            border-radius: 6px;
          }
        }
      }
    }
  }
  &.ant-popover-open {
    .all-prompt-filter {
      .anticon-caret-down {
        transform: rotate(180deg);
      }
    }
  }
  .all-prompt-filter {
    @media all and (max-width: ${midWidthApp}) {
      height: 26px;
    }
    @media all and (max-width: ${maxMobile}){
      border-radius: 4px;
      font-size: 10px;
      padding: 3px 8px;
      height: 24px;
    }
    .filter-icon-dropdown {
      margin-right: 8px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        width: 12px;
        min-width: 12px;
      }
      @media all and (max-width: ${maxMobile}){
        width: 11px;
        min-width: 11px;
        height: 10px;
      }
    }
    .separator-right {
      background-color: #DADADA;
      margin: 0px 5px 0px 10px;
    }
    .anticon-caret-down {
      @media all and (max-width: ${maxMobile}){
        svg {
          width: 9px;
          min-width: 9px;
        }
      }
    }
  }
  .prompt-custom-popover {
     &.editPromptPopover {
      &.ant-popover-placement-bottomRight {
        left: unset !important;
        right: -18px !important;
      }
      left: 12px !important;
    }
    &.placeholder-category {
      left: 16px !important;
    }
    &.ant-popover-placement-bottomLeft {
      transform-origin: bottom left !important;
      transform: translate3d(-35px, 0px, 0px) !important;
      top: 16px !important;
      left: 44px !important;
      &.editPromptPopover {
        left: 12px !important;
      }
      &.placeholder-category {
        left: 16px !important;
      }
    }
    &.ant-popover-placement-topLeft {
      transform-origin: top left !important;
      transform: translate3d(0px, 0px, 0px) !important; 
      top: -333px !important; 
      left: 6px !important;
    }
  }
  .ant-popover {
    .ant-popover-content {
      .ant-popover-inner {
        border-radius: 12px;
        .ant-popover-inner-content {
          padding: 0px;
          width: unset !important;
          height: unset !important;
        }
      }
    }
  }
}
.select-highlight-none {
  user-select: none;
}
/* Sharing Popup common styles */
.popoverSharingCustom{
  .ant-popover{
    .ant-popover-content{
      .ant-popover-inner{
        border-radius: 12px;
        overflow: hidden;
        .ant-popover-inner-content{
          padding: 0;
          .sharingPopoverContentWrapper{
            padding: 10px 16px 14px 16px;
            border-radius: 12px;
            /* min-width: 238px; */
            box-shadow: ${boxShadow};
            @media all and (${retina}) {
              padding: 14px 18px;
            }
            .heading-popover {
              font-family: ${primaryFont};
              font-size: 16px;
              font-weight: ${wMedium};
              color: ${primaryColor};
              @media all and (${retina}) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
.example-prompt-badge {
  .ant-tooltip  {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        max-width: 250px;
        @media all and (max-width: ${maxMobile}) {
          max-width: 204px;
        }
      }
    }
  }
  &.popoverSharingCustom, // General Styling
  &.popoverSharingListingView, // For listview
  &.popoverSharingGridTile{ // for grid Tile 
    .ant-popover{
      .ant-popover-content{
        .ant-popover-inner{
          @media all and (min-width: ${minExtraLargeScreen}) {
          }
          @media all and (${retina}) {
          }
          @media all and (max-width: ${minStandardScreen}) {
            border-radius: 12px;
          }
          @media all and (max-width: ${maxTablet}) {
            border-radius: 8px;
          }
          
          .ant-popover-inner-content{
            .sharingPopoverContentWrapper{
              @media all and (min-width: ${minExtraLargeScreen}) {
              }
              @media all and (${retina}) {
              }
              @media all and (max-width: ${minStandardScreen}) {
                padding: 8px 12px 10px 12px;
                border-radius: 12px;
              }
              @media all and (max-width: ${maxTablet}) {
                padding: 6px 10px 8px 10px;
                border-radius: 8px;
              }
              @media all and (max-width: ${maxMobile}) {
              }
              .heading-popover {
                @media all and (min-width: ${minExtraLargeScreen}) {
                }
                @media all and (${retina}) {
                }
                @media all and (max-width: ${minStandardScreen}) {
                  font-size: 12px;
                }
                @media all and (max-width: ${maxTablet}) {
                  font-size: 10px;
                }
                @media all and (max-width: ${maxMobile}) {
                }
              }
              .shareIconsWrapper{
                @media all and (min-width: ${minExtraLargeScreen}) {
                }
                @media all and (${retina}) {
                }
                @media all and (max-width: ${minStandardScreen}) {
                  gap: 8px;
                }
                @media all and (max-width: ${maxTablet}) {
                  gap: 6px;
                }
                @media all and (max-width: ${maxMobile}) {
                }
                .evernote-share-button,
                .social-icons-slide-mail,
                .social-icons-slide-linkedin,
                .social-icons-slide-twitter,
                .social-icons-slide-facebook,
                .teams-share-button{
                  @media all and (min-width: ${minExtraLargeScreen}) {
                  }
                  @media all and (${retina}) {
                  }
                  @media all and (max-width: ${minStandardScreen}) {
                    height: 16px !important;
                    width: 16px;
                  }
                  @media all and (max-width: ${maxTablet}) {
                    height: 14px !important;
                    width: 14px;
                  }
                  @media all and (max-width: ${maxMobile}) {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-popover-placement-leftBottom{
    padding-right: 0 !important;
  }
}
.ant-tooltip {
  &.people-name-tooltip-global {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        min-width: 45px;
        font-family: ${primaryFont};
        font-weight: ${wMedium};
        padding: 10px;
        color: rgb(66, 75, 80);
        border-radius: 8px;
        max-width: 185px;
        @media all and (max-width: ${maxMobile}) {
          max-width: 150px;
          @media all and (max-width: ${midMobile}) {
            max-width: 180px;
          }
        }
      }
    }
  }
}

.fw-MBold {
  font-weight: ${wMBold};
}
/* Dragable Dropdown common styles */
.dragPopoverContentWrapper{
  color: ${primaryHeadingColor};
  font-family: ${primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: ${wMBold};
  line-height: 16px;
  letter-spacing: -0.4px;
  height: 32px;
  filter: drop-shadow(${boxShadow});
  @media (hover: none){
    display: none;
  }
  .ant-popover-inner{
    border-radius: 8px;
    .ant-popover-inner-content{
      padding: 8px 12px !important;
    }
  }
}
`;
export default GeneralStyleV2;
