// import DrawerDocViewer from 'Pages/document/DrawerDocViewer';
import DocumentEyeIcon from 'Components/common/assets/svgIcons/DocumentEyeIcon';
import DrawerDocViewer from 'Pages/document/DrawerDocViewer';
import { selectFileViewURL } from 'Selector/DocumentHubSelector';
import { actions } from 'Slice/ChatbotV2Slice';
import { actions as docHubActions } from 'Slice/DocumentHubSlice';
import { TagItem } from 'Types/ChatBotV2Types';
import { Document } from 'Types/DocumentHubTypes';
import { DEFAULT_DOCUMENT_GILROY_IAMGE, translateSkipAreas } from 'Utils/Constants';
import { MinWidthApp, imageSource } from 'Utils/UtilityFunctions';
import {
  maxTablet,
  maxWidth,
  maxWidth1300,
  midWidthApp,
  primaryColor, primaryFont, primaryHeadingColor,
  retina,
  scrollbar,
  scrollbarX,
  secondaryFont, midMobile, wMedium,
} from 'Utils/utilsStyle';
import { Divider, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const DocWrapper = styled.div`
&.gilroyV3Fix {
  .inner-table-area {
    display: flex;
    flex-flow: column wrap;
    overflow: auto hidden;
    padding: 0px 10px;
    height: 100%;
    ${scrollbarX};
  }
   .table-item {
    flex: 1 1 0%;
    max-width: 150px;
    min-width: 150px;
    &:first-child {
      min-width: 295px;
      max-width: 295px;
   }
  }
}
&.responsiveMode {
  .header-table, .body-table {
    .table-item {
      &:last-child {
        display: none;
      }
    }
  }
}
 &.hide{
    display: none;
  }
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  position: relative;
  padding: 0px;
  .inner-table-area {
    height: calc(100% - 0px);
    @media all and (max-width: ${maxTablet}) {
      display: flex;
      flex-flow: column wrap;
      overflow: auto hidden;
      padding: 0px 10px;
      height: 100%;
      ${scrollbarX};
    }
  }
  .table-width-decide {
    height: calc(100% - 0px);
  }
  .table-item {
    flex: 1 1 0%;
    padding: 10px 5px;
    &:first-child {
      min-width: 515px;
      max-width: 515px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        min-width: 353px;
        max-width: 353px;
      }
      @media all and (max-width: ${maxWidth1300}) {
        min-width: 300px;
        max-width: 300px;
      }
      @media all and (max-width: ${midWidthApp}) {
        min-width: 240px;
        max-width: 240px;
      }
    }
    &:last-child {
      max-width: 64px;
      min-width: 64px;
    }
  }
  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component  {
      padding: 0px 5px 0px 5px;
      height: calc(100% - 35px);
      max-height: calc(100% - 35px);
      ${scrollbar};
      ::-webkit-scrollbar-track { background-color: transparent; } 
      .body-table {
        &:first-child {
          margin-top: 8px;
        }
      }
    }
  }
`;
const TableHeader = styled.div`
  display: flex;
  padding: 0px 11px 0px 7px;
  margin-top: 8px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    padding: 0px 11px 0px 6px;
  }
  .table-item {
    &:first-child {
      .heading-div {
        &:first-child {
          margin-left: 46px;
        }
      }
    }
    position: relative;
    .heading-div {
      &.actionHeading {
        cursor: pointer;
        width: fit-content;
      }
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .heading-text {
        cursor: pointer;
        font-family: ${primaryFont};
        font-size: 12px;
        font-weight: ${wMedium};
        line-height: 14px;
        letter-spacing: -0.333px;
        color: #5E7079;
        margin-bottom: 0px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 10px;
        }
      }
    }
  }
  `;

const TableBody = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 8.438px -0.993px rgba(0, 0, 0, 0.08);  
  border-radius: 6px;
  background: #FFF;
  width: 100%;
  margin-bottom: 8px;
  border: 2px solid #fff;
  &.active {
    border: 2px solid ${primaryColor};
    background: #DEF6E6;
  }
  .table-item {
    text-align: start;
    position: relative;
    padding: 16px 5px;
    .date-time-div {
      display: inline-flex;
      align-items: center;
      width: 100%;
      .text-date{
        width: unset;
        line-height: 10px;
      }
      .ant-divider {
        border-color: ${primaryHeadingColor};
        height: 9px;
      }
    }
    .action-div {
      &.actionDivV3 {
        svg {
          width: 30px;
          height: 26px;
           @media all and (max-width: ${maxWidth}), ${retina} {
            width: 25px;
            height: 30px;
           }
        }
      }
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      cursor: pointer;
      svg {
        width: 30px;
        height: 27px;
        &:hover {
          path {
            fill: ${primaryColor};
          }
        }
      }
    }
  }
  `;
const FileName = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  .file-img {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .file-name {
    font-family: ${secondaryFont};
    font-size: 12px;
    font-weight: ${wMedium};
    line-height: 16px;
    letter-spacing: -0.417px;
    color: #000;
    margin-bottom: 0px;
    text-align: left;
    &:visited {
      color: red;
    }
  }
  `;

const FileInfoText = styled.span<{ statusColor?: string }>`
  font-family: ${primaryFont};
  font-size: 10px;
  font-weight: ${wMedium};
  line-height: 12px; 
  letter-spacing: -0.375px;
  color: ${primaryHeadingColor};
  width: 100%;
`;

const tableHeader = [
  {
    title: 'Name',
    type: 'name',
  },
  {
    title: 'Uploaded by',
    type: 'uploadedBy',
  },
  {
    title: 'Date/time',
    type: 'date',
  },
  {
    title: '',
    type: '',
  },
];

type Props = {
  documents: Document[],
  fetchMore: (type: string) => void
  handleClick: (item: TagItem) => void
  active: number,
  isGilRoyV3: boolean,
};

const DocResultTagging = function DocResultTagging(props: Props) {
  const {
    documents, fetchMore, handleClick, active, isGilRoyV3,
  } = props;
  const dispatch = useDispatch();
  const url = useSelector(selectFileViewURL);
  const [activeCard, setActiveCard] = useState(-1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMinWidth = MinWidthApp();
  useEffect(() => {
    dispatch(actions.setDrawerPreviewOpen(drawerOpen));
  }, [drawerOpen]);

  const handleMouseEnter = (index: number) => {
    setActiveCard(index);
  };
  const handleMouseLeave = () => {
    setActiveCard(-1);
  };

  const handlePreview = (id: number, extension: string) => {
    dispatch(docHubActions.getPreviewDocument({ id, extension, isTag: true }));
    setDrawerOpen(true);
  };

  const handleEyeClick = (doc: Document) => (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    handlePreview(doc.id, doc.extension);
  };

  return (
    <DocWrapper
      id="gilroySearchDocScrollWrapper"
      className={classNames('', {
        hide: active !== 4,
        gilroyV3Fix: isGilRoyV3,
        responsiveMode: isMinWidth,
      })}
    >
      <div className="inner-table-area">
        <div className="table-width-decide">
          <TableHeader className="header-table">
            {documents.length ? tableHeader?.map((heading) => (
              <span className="table-item">
                <div className="heading-div">
                  <span
                    className="heading-text"
                    role="button"
                    tabIndex={0}
                  >
                    {heading.title}
                  </span>
                </div>
              </span>
            )) : null}
          </TableHeader>
          <InfiniteScroll
            hasMore
            dataLength={documents.length}
            next={() => fetchMore('document')}
            className="o-x-hidden"
            loader={false}
            scrollableTarget="gilroySearchDocScrollWrapper"
          >
            {documents.map((doc, index) => (
              <TableBody
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                className={classNames('body-table', { active: index === activeCard })}
                onClick={() => handleClick({
                  type: 'document',
                  id: doc.id,
                  name: `${doc.fileName}.${doc.extension}`,
                  image: DEFAULT_DOCUMENT_GILROY_IAMGE,
                  color: '',
                })}
              >
                <span className="table-item">
                  <FileName className={translateSkipAreas}>
                    <img src={imageSource(doc.extension)} alt="file-type-icon" className="file-img" />
                    <Tooltip
                      placement={isGilRoyV3 ? 'right' : 'bottomLeft'}
                      getPopupContainer={(e: HTMLElement) => e}
                      className={classNames('triggers-bullet-tooltip', {
                        gilroyV3Tooltip: isGilRoyV3,
                        gilroyV2Tooltip: !isGilRoyV3,
                      })}
                      color="#fff"
                      title={doc.fileName}
                      zIndex={1}
                    >
                      <h3 className="file-name text-truncate">{doc.fileName}</h3>
                    </Tooltip>
                  </FileName>
                </span>
                <span className="table-item">
                  <FileInfoText className="text-truncate">{doc.username}</FileInfoText>
                </span>
                <span className="table-item">
                  <div className="date-time-div">
                    <FileInfoText className="text-date text-truncate-one-line">{doc.date}</FileInfoText>
                    <Divider type="vertical" />
                    <FileInfoText className="text-date text-truncate-one-line">{doc.time}</FileInfoText>
                  </div>
                </span>
                <span className="table-item">
                  <div className={classNames('action-div', { actionDivV3: isGilRoyV3 })} role="button" tabIndex={0} onClick={handleEyeClick(doc)} onKeyDown={handleEyeClick(doc)}>
                    {!isMinWidth ? (<DocumentEyeIcon />) : null}
                  </div>
                </span>
              </TableBody>
            ))}
          </InfiniteScroll>
        </div>

      </div>
      {drawerOpen && (
        <DrawerDocViewer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          URL={url}
          setTableRowId={() => { }}
          docViewGilV3Flag={isGilRoyV3}
        />
      )}
    </DocWrapper>
  );
};

export default DocResultTagging;
