import { createGlobalStyle } from 'styled-components';
import {
  checkboxTick, eyePassword, eyePasswordCross, primaryColor, primaryFont, SecondaryBgColor,
} from '../Utils/utilsStyle';

const AuthStyle = createGlobalStyle`
  body {
    background-color: ${SecondaryBgColor}
  }
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    p {
      font-family: ${primaryFont};
    }
  }
  .d-none {
    display:none;
  }
  .checkbox-input-login {
      visibility: hidden;
      position: absolute;
      top: 3px;
      left: 3px;
      &:checked + span {
        background-image: url(${checkboxTick});
        background-position: center;
        background-size: auto;
        border: 2px solid ${primaryColor};
      }
      &:active + span,
      &:focus + span {
        box-shadow: 0 0 0 0.1rem rgb(40 167 69 / 50%);
        outline: none;
      }
  }
  .auth-input {
    color: #424b50;
    border-radius: 10px;
    padding: 8px 18px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    border: 1px solid #dadada;
    display: block;
    width: 100%;
    font-family: "roboto";
    &:active,
    &:focus {
      box-shadow: 0 0 0 0.1rem rgb(40 167 69 / 50%);
      outline: none;
    }
    &.error {
      border: 1px solid #ffa112 !important;
      box-shadow: none !important;
    }
    + span {
      display: none;
    }
    &.password {
      padding-right: 40px !important;
    }
    &.password + span {
      position: absolute;
      display: inline-block;
      right: 12px;
      top: 16px;
      cursor: pointer;
      width: 20px;
      height: 12px;
      background-size: contain;
      background-image: url(${eyePassword});
      background-repeat: no-repeat;
      transition: all 300ms;
      padding: 0;
      &.active {
        background-image: url(${eyePasswordCross});
        top: 14px !important;
        height: 16px;
      }
    }
    &.invalid {
      color: #ffa112;
      border: 1px solid #ffa112;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: #ee1919 !important;
      }
    }
    &:placeholder {
      color: #c7c7c7;
      font-style: italic;
      font-family: "Roboto";
    }
    &::-webkit-input-placeholder {
      font-family: "Roboto";
      color: #c7c7c7;
      font-style: italic;
    }
    &:-moz-placeholder {
      color: #c7c7c7;
      font-family: "Roboto";
      font-style: italic;
    }
    &::-moz-placeholder {
      font-family: "Roboto";
      color: #c7c7c7;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      font-family: "Roboto";
      color: #c7c7c7;
      font-style: italic;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      // -webkit-text-fill-color: #424b50 !important;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .auth-input-label{
    color: #5e7079;
    display: block;
    margin: 23px 0 8px 0;
    font-size: 18px;
    font-weight: 600;
    font-family: "Roboto";
    .error-msg {
      color: #ffa112;
      font-style: italic;
      font-size: 14px;
      text-align: right;
      float: right;
      margin-top: 1px;
      margin-right: 5px;
      font-family: "Roboto_italic";
      font-weight: normal;
    }
    .optional {
      color: rgb(94, 112, 121);
      font-size: 14px;
      padding-left: 10px;
    }
  }
  .input-span-hide{
    span {
      display:none;
    }
  }
   .password-valid-popover {
    .ant-popover-placement-leftTop {
      @media all and (max-width: 992px) {
        top: 64px !important;
        left: 23px !important;
        transform-origin: 4px 0px !important;
      }
      @media all and (min-width: 992px) {
        min-width: 400px !important;
      }
    }
    .ant-popover-content {
      min-width: 400px;
      max-width: 400px;
      @media all and (max-width: 992px) {
        min-width: 200px;
        max-width: 200px;
      }
      .valid-password-content-list-item {
         @media all and (max-width: 992px) {
          font-size: 10px;
         }
      }
      .valid-password-content-div {
         @media all and (max-width: 992px) {
          padding: 5px 20px;
         }
      }
    }
  }
`;
export default AuthStyle;
