/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GlobalRequest } from 'Types/RequestBodies';
import { GlobalResponse } from 'Types/ResponseBodies';
import { API } from 'Utils/Constants';
import { actions } from 'Slice/LogoutSlice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import {
  getCurrentTime, getLocalStorageValue, getSessionStorageValue, resetOnlogout, valueToObject,
} from 'Utils/UtilityFunctions';
import OneSignalReact from 'react-onesignal';

/**
 * User request/response handlera
 */
export function* logoutUser(dispatched: PayloadAction<GlobalRequest>) {
  const startTime = getCurrentTime();
  try {
    const response: GlobalResponse = yield call(axios.post, API.LOGOUT, dispatched?.payload);
    if (response?.status === true) {
      const host = window.location.href;
      OneSignalReact.removeExternalUserId()
        .then((res) => console.log('Remove External User id Successfully', res))
        .catch((err) => console.log('Remove External User id Failed', err));
      yield put(flurryActions.callFlurryEvent(
        valueToObject('user_logout', startTime, {host, username: getLocalStorageValue('email')}),
      ));
      const route:string = getSessionStorageValue('initialRoute') || '/';
      yield call (resetOnlogout, route, true);
    } else {
      yield put(actions.logoutError(response?.message));
    }
  } catch (err) {
    console.log(err);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* logoutSaga() {
  yield takeLatest(actions.logoutUser.type, logoutUser);
}
