/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  maxMobile, maxWidth, midWidthApp,
  primaryColor,
  primaryFont, retina, scrollbar, SecondaryColor, wMedium,
} from 'Utils/utilsStyle';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { IsMobile } from 'Utils/UtilityFunctions';
import CategoryPill from './CategoryPill';

const Wrapper = styled.div`
display: flex;
align-items: center;
&.suggestedCategoryWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 4px 0px;
  .category-type {
    height: 18px;
    line-height: normal;
    @media all and (max-width: ${maxWidth}), ${retina} {
      height: 16px;
    }
    @media all and (max-width: ${maxMobile}) {
      height: 15px;
    }
  }
}
.category-example-prompt {
  .ant-tooltip {
    &.ant-tooltip-placement-bottomRight {
      left: unset !important;
      right: -12px;
    }
    left: -13px !important;
    /* @media all and (max-width: ${maxMobile}) {
      left: 0px !important;
    } */
  }
}
.show-less-primary {
  cursor: pointer;
  color: ${primaryColor};
  font-family: ${primaryFont};
  font-size: 12px;
  font-weight: ${wMedium};
  line-height: normal;
  letter-spacing: -0.4px;
}
`;
const CustomBadge = styled.span`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: auto;
  padding: 4px;
  min-width: 22px;
  height: 15px;
  box-shadow: 0px 0px 2.44px 0px rgba(0, 0, 0, 0.25);
  color: ${SecondaryColor};
  font-family: ${primaryFont};
  font-size: 12px;
  font-weight: ${wMedium};
  line-height: normal;
  letter-spacing: -0.19px;
  border-radius: 25px;
  @media all and (max-width: ${midWidthApp}) {
    padding: 2px;
    font-size: 10px;
    height: 14px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  ${scrollbar};
  border-radius: 0px !important;
  .tooltip-category-pill {
    height: 18px;
    margin-bottom: 4px;
    line-height: normal;
  }
`;
type Props = {
  suggestedCategory?: boolean,
  promptCategories?: string[],
  onCategoryClick?: (a: string) => void,
} & typeof defaultProps;

const defaultProps = {
  suggestedCategory: false,
  promptCategories: [] as string[],
  onCategoryClick: (a: string) => { },
};
const CategoriesOfExamplePrompt = function CategoriesOfExamplePrompt(props: Props) {
  const { suggestedCategory, promptCategories, onCategoryClick } = props;

  const [showAll, setShowAll] = useState(false);
  const arrayLength = suggestedCategory ? showAll ? 8 : 4 : 2;
  const categoryLengthInTooltip = promptCategories.length > arrayLength
    ? promptCategories.length - arrayLength : 0;
  const isMob = IsMobile();
  const content = (
    <ContentWrapper>
      {promptCategories?.slice(arrayLength, promptCategories.length).map((category) => (
        <CategoryPill fText={category} categoryClass="tooltip-category-pill" />))}
    </ContentWrapper>
  );
  return (
    <Wrapper
      onClick={(e) => e.stopPropagation()}
      className={classNames({ suggestedCategoryWrapper: suggestedCategory })}
    >
      {promptCategories?.slice(0, arrayLength)?.map((category) => (
        <CategoryPill onClickCategory={onCategoryClick} fText={category} />
      ))}
      {suggestedCategory ? showAll ? (
        <span
          tabIndex={0}
          role="button"
          onClick={() => setShowAll(!showAll)}
          onKeyPress={() => setShowAll(!showAll)}
          className="show-less-primary"
        >
          Show less
        </span>
      ) : (
        <CustomBadge
          tabIndex={0}
          role="button"
          onClick={() => setShowAll(!showAll)}
          onKeyPress={() => setShowAll(!showAll)}
          className={classNames('example-prompt-badge', { dNone: categoryLengthInTooltip === 0 })}
        >
          +
          {categoryLengthInTooltip}
        </CustomBadge>
      ) : (
        <Tooltip
          placement={isMob ? 'bottomRight' : 'bottomLeft'}
          getPopupContainer={(e: HTMLElement) => e}
          className={classNames('triggers-bullet-tooltip category-example-prompt', { dNone: categoryLengthInTooltip === 0 })}
          color="#fff"
          title={content}
          zIndex={1}
        >
          <CustomBadge className="example-prompt-badge">
            +
            {categoryLengthInTooltip}
          </CustomBadge>
        </Tooltip>
      )}

    </Wrapper>
  );
};
CategoriesOfExamplePrompt.defaultProps = defaultProps;
export default CategoriesOfExamplePrompt;
