import {
  maxWidth1300, maxWidth, retina, primaryFont, wMedium,
  primaryHeadingColor, maxTablet, maxMobile,
  midWidthApp,
} from 'Utils/utilsStyle';
import React, {
  Suspense, lazy,
  // useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/ChatbotV2Slice';
import { actions as UiActions } from 'Slice/UISlice';
import {
  selectAiCertificationLoading,
  selectIsAiCertificationCompleted,
} from 'Selector/CertificationSelector';
import {
  selectCompanyName,
  selectFavPromptCount, selectPrompts, selectPromptsLoading,
  selectSelectedPromptCateogry, selectShowExamplePrompts, selectShowFavorite,
  selectTotalCustomCategories,
  selectTotalPrompts,
} from 'Selector/ChatBotV2Selector';
import { showCertificationInApp } from 'Utils/UtilityFunctions';
import classNames from 'classnames';

import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import { isCompanyPromptFlag, isOnlyFavorites } from 'Selector/UISelector';
import { TOOLTIP_CUSTOM_CATEGORY, TOOLTIP_FAVORITE_PROMPT } from 'Utils/Constants';
import BackButtonSecondaryHeader from '../BackButtonSecondaryHeader';
import LibraryCategoryCmpSkeleton from './component/LibraryCategoryCmpSkeleton';
import GetCertifiedButton from '../GetCertifiedButton';
import PromptCategoryFilter from './component/PromptCategoryFilter';

const LibraryPromptSlider = lazy(() => import('./component/LibraryPromptSlider'));

const StyledWrapper = styled.div<{ slickDotsWidth: number }>`
position: relative;
height: 100%;
width: 100%;
z-index: 0;
.chatbot-library-heading {
  &.withFilter {
    .back-button-secondary-header {
      max-width: calc(100% - 170px);
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 10px);
}
.get-certified-ai-button-wrapper {
  position: absolute;
  top: 6px;
  right: ${(props) => props.slickDotsWidth + 5}px;
  padding: 6px 16px 6px 12px;
  height: 30px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    top: 9px;
  }
  @media all and (max-width: ${maxWidth1300}) {
    top: 8px;
  }
}
.heading-div-library {
  &.companyBackHeading {
    padding: 0px;
    padding-left: 13px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    @media all and (max-width: ${midWidthApp}) {
      margin: 4px 0;
      padding-top: 6px;
    }
    @media all and (max-width: ${maxTablet}) {
      margin: 0px 0px;
      padding-top: 4px;
    }
    .back-button-secondary-header {
      padding-left: 0px;
    }
  }
  display: inline-flex;
  align-items: center;
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.792px;
  color: ${primaryHeadingColor};
  padding-top: 12px;
  margin-bottom: 14px;
  padding-left: 12px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    line-height: 22px;
    padding-top: 10px;
  }
  @media all and (max-width: ${maxTablet}) {
    padding: 8px 0px 12px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
  }
  @media all and (max-width: ${maxMobile}) {
    padding: 8px 0px 8px;
  }
}
`;

type Props = {
  setInputText: (a: string) => void,
  isGilroyV3?: boolean,
};
const defaultProps = {
  isGilroyV3: false,
};

const ChatBotLibrary = function ChatBotLibrary(props: Props) {
  const { setInputText, isGilroyV3 } = props;
  const dispatch = useDispatch();
  const selectedPromptCategory = useSelector(selectSelectedPromptCateogry);
  const showExamplePrompts = useSelector(selectShowExamplePrompts);
  const aiLoading: boolean = useSelector(selectAiCertificationLoading);
  const isAiCertificationCompleted = useSelector(selectIsAiCertificationCompleted);
  const certificationAllowed = showCertificationInApp();
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const isCompanyPrompt = useSelector(isCompanyPromptFlag);
  const onlyFavoritesFlag = useSelector(isOnlyFavorites); // make it false for custom prompt
  const showFavorites = useSelector(selectShowFavorite);
  const favPromptCount = useSelector(selectFavPromptCount);
  const companyName = useSelector(selectCompanyName);
  const totalCustomCategories = useSelector(selectTotalCustomCategories);
  const selectedCategory = useSelector(selectSelectedPromptCateogry);
  const promptsLoading = useSelector(selectPromptsLoading);
  const prompts = useSelector(selectPrompts);
  const totalPrompts = useSelector(selectTotalPrompts);
  const [slickDotsWidth, setSlickDotsWidth] = useState(isCompanyPrompt ? 60 : 40);
  console.log(setSlickDotsWidth);

  // useEffect(() => {
  //   const updateSlickDotsWidth = () => {
  //     const nodeSlickDots = document.querySelector('.slick-dots');
  //     if (nodeSlickDots) {
  //       setSlickDotsWidth(nodeSlickDots.clientWidth);
  //     }
  //   };
  //   updateSlickDotsWidth();
  //   window.addEventListener('resize', updateSlickDotsWidth);
  //   return () => {
  //     window.removeEventListener('resize', updateSlickDotsWidth);
  //   };
  // }, []);

  return (
    <StyledWrapper
      className={classNames('chatbot-library', { notLibraryExample: !showExamplePrompts })}
      slickDotsWidth={slickDotsWidth}
    >
      {showExamplePrompts ? (
        <div className={classNames('chatbot-library-heading', { withFilter: selectedCategory?.categoryId })}>
          <BackButtonSecondaryHeader
            title={showFavorites ? `Favorites (${favPromptCount})` : `${selectedPromptCategory?.title} ${selectedPromptCategory?.categoryId && !(promptsLoading && !prompts?.length) ? ` (${totalPrompts})` : ''}`}
            tooltipText={showFavorites ? TOOLTIP_FAVORITE_PROMPT : ''}
            onClick={() => dispatch(actions.setShowExamplePrompts(false))}
            editCategory={!onlyFavoritesFlag}
            customClassName="text-truncate"
          />
          {selectedCategory?.categoryId ? (
            <PromptCategoryFilter />
          ) : null}
        </div>
      ) : (
        <>
          <span className={classNames('heading-div-library', { companyBackHeading: isCompanyPrompt })}>
            {isCompanyPrompt ? (
              <BackButtonSecondaryHeader
                title={`${companyName} Prompt Library (${totalCustomCategories})`}
                tooltipText={TOOLTIP_CUSTOM_CATEGORY}
                onClick={() => dispatch(UiActions.isCompanyPrompt(!isCompanyPrompt))}
              />
            ) : 'Explore our Prompt Library'}
          </span>
          {!isAiCertificationCompleted && !isCompanyPrompt && certificationAllowed && !aiLoading
            && !isGilroyV3 && !disclessPersonality
            ? <GetCertifiedButton /> : null}
        </>
      )}
      <Suspense
        fallback={
          <LibraryCategoryCmpSkeleton />
        }
      >
        <LibraryPromptSlider
          showExamplePrompt={showExamplePrompts}
          setInputText={setInputText}
          isGilroyV3={isGilroyV3}
        />
      </Suspense>
    </StyledWrapper>
  );
};
ChatBotLibrary.defaultProps = defaultProps;
export default ChatBotLibrary;
