import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import DeleteIconGilroy from 'Components/common/assets/svgIcons/DeleteIconGilroy';
import {
  driver,
  maxMobile,
  maxWidth,
  midWidthApp,
  primaryColor,
  primaryFont, primaryHeadingColor, retina, scrollbar, wLight, wMedium,
} from 'Utils/utilsStyle';
import GilroyDeleteChatPopup from 'Components/common/modal/GilroyDeleteChatPopup';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/ChatbotV2Slice';
import { selectAddRemovePromptError, selectDeleteCategoryLoading, selectSuggestedCategories } from 'Selector/ChatBotV2Selector';
import CategoriesOfExamplePrompt from './CategoriesOfExamplePrompt';

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  padding: 12px;
  max-height: 269px;
  ${scrollbar};
  @media all and (max-width: ${maxWidth}), ${retina} {
    max-height: 206px;
  }
  @media all and (max-width: ${maxMobile}) {
    max-height: 183px;
  }
  .heading-text {
    color: #5E7079;
    font-family: ${primaryFont};
    font-size: 10px;
    font-weight: ${wMedium};
    line-height: 12px;
    letter-spacing: -0.556px;
    margin-bottom: 8px;
    margin-top: 8px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      font-size: 9px;
    }
    @media all and (max-width: ${midWidthApp}) {
      font-size: 8px;
      margin-bottom: 0px;
    }
    @media all and (max-width: ${maxMobile}) {
      font-size: 8px;
    }
  }
  @media all and (max-width: ${midWidthApp}) {
    padding: 10px 16px 9px 10px;
  }
  .title-div-cat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-new-cat {
      color: #667982;
      font-family: ${primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: ${wMedium};
      line-height: 21px;
      letter-spacing: -0.363px;
      margin-bottom: 0px;
      @media all and (max-width: ${midWidthApp}) {
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 0;
      }
    }
    .delete-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      .delete-icon-gilroy-svg {
        height: 13px;
        @media all and (max-width: ${midWidthApp}) {
          height: 11px;
        }
        path {
          fill: #F5222D;
        }
      }
      .delete-text {
        color: #F5222D;
        font-family: ${primaryFont};
        font-size: 12px;
        font-style: normal;
        font-weight: ${wMedium};
        line-height: 16px;
        letter-spacing: -0.363px;
        margin-left: 3px;
        @media all and (max-width: ${midWidthApp}) {
          font-size: 10px;
        }
      }
    }
  }
  .input-title {
    &.description {
      &.optional {
        font-size: 10px;
      }
      @media all and (max-width: ${midWidthApp}) {
        margin-top: 10px;
      }
    }
    color: #5E7079;
    font-family: ${primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: ${wMedium};
    line-height: 20px;
    letter-spacing: -0.556px;
    margin-top: 12px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      margin-top: 4px;
    }
    @media all and (max-width: ${midWidthApp}) {
      font-size: 12px;
      line-height: normal;
      margin-bottom: 4px;
    }
  }
  .error-msg-input {
    font-family: ${primaryFont};
    font-weight: ${wMedium};
    font-size: 12px;
    color: ${driver};
  }
  .create-new-cat-input {
    &.text-area-category {
      @media all and (max-width: ${midWidthApp}) {
        height: 108px;
      }
    }
    border-radius: 6px;
    border: 0.832px solid #DADADA;
    background: #FFF;
    padding: 6px 12px;
    color: ${primaryHeadingColor};
    font-family: ${primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: ${wLight};
    line-height: 18px;
    letter-spacing: -0.45px;
    @media all and (max-width: ${midWidthApp}) {
      font-size: 12px;
    }
    textarea {
      color: ${primaryHeadingColor};
      font-family: ${primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: ${wLight};
      line-height: 18px;
      letter-spacing: -0.45px;
      @media all and (max-width: ${midWidthApp}) {
        font-size: 12px;
      }
      &.ant-input {
        border: none;
        border-radius: 0px;
        padding: 0px;
        background: #fff;
        &:hover, :focus {
          border: none;
          box-shadow: none;
        }
      }
      &.ant-input::placeholder {
        color: #C1C1C1;
        font-family: ${primaryFont};
        font-size: 12px;
        font-style: italic;
        font-weight: ${wLight};
        line-height: 18px;
        letter-spacing: -0.45px;
      }
    }
    &.ant-input {
      border: none;
      border-radius: 0px;
    }
    &.ant-input::placeholder {
      color: #C1C1C1;
      font-family: ${primaryFont};
      font-size: 12px;
      font-style: italic;
      font-weight: ${wLight};
      line-height: 18px;
      letter-spacing: -0.45px;
    }
  }
  .ant-input-suffix {
    .ant-input-show-count-suffix {
      color: #C1C1C1;
      font-family: ${primaryFont};
      font-size: 10px;
      font-style: italic;
      font-weight: ${wLight};
      line-height: 18px;
      letter-spacing: -0.45px;
    }
  }
  .ant-input-textarea {
    &:focus, &:hover {
      border-color: ${primaryColor};
	  }
  }
  .ant-input-textarea.ant-input-textarea-show-count::after {
    color: #C1C1C1;
    font-family: ${primaryFont};
    font-size: 10px;
    font-style: italic;
    font-weight: ${wLight};
    line-height: 18px;
    letter-spacing: -0.45px;
  }
`;
type Props = {
  categoryId?: number,
  isEdit: boolean,
  title: string
  description: string
  setTitle: (title: string) => void
  setDescription: (description: string) => void
} & typeof defaultProps;

const defaultProps = {
  categoryId: 0,
};

const CreateNewCategoryTab = function CreateNewCategoryTab(props: Props) {
  const {
    isEdit, title, description, setTitle, setDescription, categoryId,
  } = props;
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const dispatch = useDispatch();
  const suggestedCategories = useSelector(selectSuggestedCategories);
  const addRemovePromptError = useSelector(selectAddRemovePromptError);
  const deleteCategoryLoading = useSelector(selectDeleteCategoryLoading);

  const handleDeleteConfirm = () => {
    if (!deleteCategoryLoading) {
      dispatch(actions.deleteCustomCategory({ categoryId }));
    }
  };

  const handleCategoryClick = (name: string) => {
    setTitle(name);
  };

  const onTitleChange = (newTitle: string) => {
    setTitle(newTitle);
    dispatch(actions.setAddRemovePromptError(''));
  };

  return (
    <Wrapper>
      <div className="title-div-cat">
        <h6 className="title-new-cat">{isEdit ? 'Edit your Category' : 'Create New Category'}</h6>
        {isEdit ? (
          <div
            tabIndex={0}
            role="button"
            className="delete-div"
            onClick={() => setShowDeletePopup(!showDeletePopup)}
            onKeyPress={() => setShowDeletePopup(!showDeletePopup)}
          >
            <DeleteIconGilroy />
            <span className="delete-text">Delete</span>
          </div>
        ) : null}
      </div>
      <h3 className="input-title">Category*</h3>
      <Input
        placeholder="Write a name of your category"
        className="create-new-cat-input"
        value={title}
        tabIndex={-1}
        showCount
        maxLength={40}
        onChange={(e) => onTitleChange(e.target.value)}
      />
      <span className="error-msg-input text-truncate-one-line">{addRemovePromptError}</span>
      {!isEdit && suggestedCategories?.length
        ? (
          <>
            <h3 className="input-title">Suggested names</h3>
            <CategoriesOfExamplePrompt
              onCategoryClick={handleCategoryClick}
              suggestedCategory
              promptCategories={suggestedCategories}
            />
          </>
        ) : null}
      <h3 className="input-title description">
        Description
        {' '}
        <span className="input-title description optional">
          (optional)
        </span>
      </h3>
      <Input.TextArea
        placeholder="Write description of your category"
        className="create-new-cat-input text-area-category"
        value={description}
        showCount
        maxLength={95}
        rows={6}
        tabIndex={-1}
        onChange={(e) => setDescription(e.target.value)}
      />
      <GilroyDeleteChatPopup
        isVisible={showDeletePopup}
        setIsVisible={() => setShowDeletePopup(!showDeletePopup)}
        popupText="Are you sure you want to delete this category?"
        onDelete={handleDeleteConfirm}
      />
    </Wrapper>
  );
};

CreateNewCategoryTab.defaultProps = defaultProps;
export default CreateNewCategoryTab;
