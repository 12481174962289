import React from 'react';

const WhiteBotIcon = function WhiteBotIcon() {
  return (
    <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9082 7.12647L13.3477 0.885639L13.5493 0.881836L14.0931 7.29544L12.9082 7.12647Z" fill="white" />
      <path d="M13.4447 0.991531C13.7109 0.991531 13.9266 0.7758 13.9266 0.509681C13.9266 0.243563 13.7109 0.027832 13.4447 0.027832C13.1786 0.027832 12.9629 0.243563 12.9629 0.509681C12.9629 0.7758 13.1786 0.991531 13.4447 0.991531Z" fill="white" />
      <path d="M24.0012 10.2466H22.5475C22.1444 10.2466 22.1444 11.3638 22.1444 12.7419V14.5614C22.1444 15.9395 22.1445 17.0567 22.5475 17.0567H24.0012C25.3794 17.0567 26.4966 15.9395 26.4966 14.5614V12.7419C26.4966 11.3638 25.3794 10.2466 24.0012 10.2466Z" fill="white" />
      <path d="M4.69192 10.2466H2.99863C1.62049 10.2466 0.503296 11.3638 0.503296 12.7419V14.5614C0.503296 15.9395 1.62049 17.0567 2.99863 17.0567H4.69192C5.27687 16.8526 4.33439 15.9395 4.33439 14.5614V12.7419C4.33439 11.3638 5.22376 10.2466 4.69192 10.2466Z" fill="white" />
      <path d="M8.84326 6.43203H18.1563C20.8494 6.43203 23.0325 8.61518 23.0325 11.3082V15.9957C23.0325 18.6888 20.8494 20.8719 18.1563 20.8719H8.84326C6.15021 20.8719 3.96707 18.6888 3.96707 15.9957V11.3082C3.96707 8.61518 6.15021 6.43203 8.84326 6.43203Z" stroke="white" strokeWidth="2.2" />
      <path d="M11.7854 16.0332C12.9328 16.4673 14.0667 16.5064 15.2157 16.1956C14.4513 17.6011 12.7046 17.8592 11.7854 16.0332Z" fill="white" />
      <path d="M10.9968 12.232C11.0044 12.9617 10.4188 13.5587 9.68967 13.5669C8.96003 13.5745 8.36296 12.9888 8.35481 12.2597C8.3472 11.5301 8.93287 10.933 9.66197 10.9249C10.3916 10.9173 10.9887 11.5029 10.9968 12.232Z" fill="white" />
      <path d="M18.6466 12.1798C18.6542 12.9094 18.0686 13.5065 17.3395 13.5147C16.6098 13.5223 16.0127 12.9366 16.0046 12.2075C15.997 11.4779 16.5827 10.8808 17.3117 10.8726C18.0414 10.865 18.6385 11.4507 18.6466 12.1798Z" fill="white" />
    </svg>
  );
};

export default WhiteBotIcon;
