/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  loadingGifV3, maxMobile, maxTablet, maxWidth, retina, scrollbar,
} from 'Utils/utilsStyle';
import { isEditChatOpen } from 'Selector/UISelector';
import { useDispatch, useSelector } from 'react-redux';
import { actions as UiAction } from 'Slice/UISlice';
import {
  selectChatHistory, selectChatHistoryLoading, selectChatLoading,
  selectConversationSections,
  selectCustomCategories,
  selectGeneratingChannelId,
  selectPendingChatId, selectedConversationId,
} from 'Selector/ChatBotV2Selector';
import { actions } from 'Slice/ChatbotV2Slice';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import {
  getCurrentTime, scrollToElement, scrollToTarget, valueToObject,
} from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { TagItem } from 'Types/ChatBotV2Types';
import { selectGilroyDiscalimer } from 'Selector/GlobalSearchContentSelector';
import EmptyStateHistory from './components/EmptyStateHistory';
import UserQuery from './components/UserQuery';
import BackButtonSecondaryHeader from '../BackButtonSecondaryHeader';
import GPTGeneratedOutput from './components/GPTGeneratedOutput';
import RegenerateBtnGilroyChat from './components/RegenerateBtnGilroyChat';
import ChatBotHistorySkeletonComponent from './components/ChatBotHistorySkeletonComponent';

const StyledWrapper = styled.div<{ editOutputId: string, dynamicHeightMobileEdit: number }>`
  height: calc(100% - 0px);
  position: relative;
  z-index: 0;
  padding-left: 8px;
  padding-right: 8px;
  &.globalGilroyV3Wrapper {
    padding: 0 9px;
    .scroll-wrapper-chat-history {
      .infinite-scroll-component__outerdiv {
        .infinite-scroll-gilroy-chat {
          .loader-div {
            min-height: 75vh;
            @media all and (max-width: ${maxWidth}), ${retina} {
              min-height: 55vh;
            }
          }
        }
      }
      &.infinite-scroll-gilroy-edit-toggle {
        .infinite-scroll-component__outerdiv {
          .infinite-scroll-gilroy-chat {
            #${(props) => props.editOutputId} {
              height: ${(props) => props.dynamicHeightMobileEdit - 60}px;
              margin-right: 0;
              @media all and (max-width: ${maxTablet}) {
                height: ${(props) => props.dynamicHeightMobileEdit - 42}px;
                padding-bottom: 10px;
              }
              .right-side {
                width: calc(100% - 72px);
                @media all and (max-width: ${maxTablet}) {
                  width: calc(100% - 45px);
                  max-width: calc(100% - 45px);
                }
              }
            }
          }
        }
      }
    }
  }
  &.emptyStateWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .scroll-wrapper-chat-history {
    margin-left: 4px;
    &.infinite-scroll-gilroy-edit-toggle {
      overflow-y: hidden !important;
      .infinite-scroll-component__outerdiv {
        .infinite-scroll-gilroy-chat {
          .output-js {
             &:last-child {
              padding-bottom: 0px;
            }
          }
          #${(props) => props.editOutputId} {
            height: 482px;
            margin-right: 0px;
            padding-bottom: 0px;
            @media all and (max-width: ${maxWidth}), ${retina} {
              height: 312px;
            }
            @media all and (min-height: ${maxTablet}) {
              height: 481px;
            }
            @media all and (max-width: ${maxTablet}) {
              height: 270px;
              margin-right: 0px;
            }
            @media all and (max-width: ${maxMobile}) {
              height: ${(props) => props.dynamicHeightMobileEdit - 193}px;
            }
            .right-side {
              height: 100%;
              margin-top: 7px;
              width: calc(100% - 57px);
              padding-right: 5px;
              @media all and (max-width: ${maxTablet}) {
                width: calc(100% - 0px);
                max-width: calc(100% - 50px);
                padding-right: 5px;
              }
              .output-content-editTable {
                max-height: calc(100% - 7px);
                height: 100%;
                ${scrollbar};
                border-radius: 6px !important;
                ::-webkit-scrollbar { width: 22px; } 
                ::-webkit-scrollbar-track { background-color: transparent; } 
                ::-webkit-scrollbar-thumb { background-color:#DCDCDD; border-radius: 20px; border: 7px solid transparent; background-clip: content-box; }
                 @media all and (max-width: ${maxTablet}) {
                  &::-webkit-scrollbar {
                    width: 4px;
                    border-radius:8px;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: #001760;
                    border-radius: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
    display: flex;
    flex-direction: column-reverse;
    max-height: calc(100% - 60px);
    @media all and (max-width: ${maxTablet}) {
      max-height: calc(100% - 40px);
    }
    ${scrollbar};
    ::-webkit-scrollbar { width: 6px; } 
    ::-webkit-scrollbar-thumb { background:#DCDCDD; }  
    ::-webkit-scrollbar-track { background-color: transparent; }  
    overflow-x: hidden !important;
    border-radius: 0px !important;
  .infinite-scroll-component__outerdiv {
    .infinite-scroll-gilroy-chat {
      &.is-category-popover-open {
        min-height: 450px;
      }
      overflow-y: hidden !important;
      overflow-x: hidden !important;
      height: 100% !important;
      .query-js ,.output-js {
        position: relative;
        margin-right: 20px;
        @media all and (max-width: ${maxTablet}) {
          margin-right: 10px;
        }
      }
      .loader-div {
        min-height: 55vh;
        @media all and (max-width: ${maxWidth}), ${retina} {
          min-height: 50vh;
        }
        &.noChatLoader {
          min-height: calc(100vh - 500px);
          @media all and (max-width: ${maxWidth}), ${retina} {
            min-height: calc(100vh - 400px);
          }
          @media all and (min-height: ${maxTablet}) {
            min-height: calc(100vh - 500px);
          }
        }
        .loader-wrap-animated {
          display: flex;
          justify-content: center;
          margin-top: 58px;
          width: 100%;
          height: auto;
          min-height: 220px;
          .loader-gif {  
            width: 140px;
            height: 140px;
            @media all and (max-width: ${maxTablet}) {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
  }
  }
  .wrap-to-btn {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 6px);
    cursor: default;
    pointer-events: none;
    background: linear-gradient(rgba(246, 246, 246, 0.5) 20%, rgba(246, 246, 246, 1) 100%);
    height: 54px;
    border-radius: 8px;
  }
  .back-button-secondary-header {
    padding-bottom: 5px;
    padding-left: 4px;
  }
`;

type Props = {
  promptInProgress: string
  setInputText: (a: string) => void
  setIsStopGenerating: (a: boolean) => void
  isGilroyV3?: boolean
  isEditChatOpenFlag?: boolean,
};
const defaultProps = {
  isGilroyV3: false,
  isEditChatOpenFlag: false,
};
const ChatBotHistory = function ChatBotHistory(props: Props) {
  const {
    promptInProgress, setInputText, setIsStopGenerating, isGilroyV3, isEditChatOpenFlag,
  } = props;
  const dispatch = useDispatch();
  const isEditChatFlag = useSelector(isEditChatOpen);
  const chatHistory = useSelector(selectChatHistory);
  const chatHistoryLoading = useSelector(selectChatHistoryLoading);
  const chatLoading = useSelector(selectChatLoading);
  const selectedConvoId = useSelector(selectedConversationId);
  const pendingChatId = useSelector(selectPendingChatId);
  const generatingChannelId = useSelector(selectGeneratingChannelId);
  const conversationSections = useSelector(selectConversationSections);
  const [editOutputId, setEditOutputId] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [isOutputScroll, setOutputScroll] = useState(false);
  const startTime = getCurrentTime();
  const dynamicHeightMobileEdit = document && document.getElementById('editDivMobile')?.offsetHeight;
  const { showDisclaimer } = useSelector(selectGilroyDiscalimer);
  const customCategories = useSelector(selectCustomCategories);

  useEffect(() => {
    if (!customCategories?.length) {
      dispatch(actions.getPromptCategories({
        isCustomCategory: true,
      }));
    }
  }, []);

  useEffect(() => {
    if (!isEditChatFlag && editOutputId) {
      scrollToElement(editOutputId, scrollableRef.current);
    }
  }, [isEditChatFlag]);

  useEffect(() => {
    setTimeout(() => {
      const node = document.querySelector('.scroll-wrapper-chat-history');
      return isEditChatFlag ? node?.classList.add('infinite-scroll-gilroy-edit-toggle') : node?.classList.remove('infinite-scroll-gilroy-edit-toggle');
    }, 1000);
  }, [isEditChatFlag]);

  useEffect(() => {
    if (selectedConvoId && selectedConvoId !== -1 && !chatHistory?.length) {
      dispatch(actions.resetChatHistory());
      dispatch(actions.getChatHistory({
        channelId: selectedConvoId,
        offset: 0,
      }));
    }
  }, [selectedConvoId]);

  const onEditClick = (id: number) => {
    const targetId = `output-js-${id}`;
    setEditOutputId(targetId);
    dispatch(UiAction.isEditChatOpenInput(true));
    setTimeout(() => {
      scrollToElement(targetId, scrollableRef.current);
    }, 1000);
  };

  const fetchMoreChat = () => {
    if (selectedConvoId && !chatHistoryLoading) {
      dispatch(actions.getChatHistory({
        channelId: selectedConvoId,
        offset: chatHistory?.length,
      }));
    }
  };

  const handleBack = () => {
    dispatch(actions.setShowLibrary(true));
  };

  function handleSpanTags(html: string) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const spanTags = div.querySelectorAll('span');
    spanTags.forEach((span) => {
      const key = span.getAttribute('key');
      const id = span.getAttribute('id');
      const color = span.getAttribute('data-color');
      const image = showDisclaimer ? '' : span.getAttribute('data-image');
      const name = span.textContent?.substring(1);
      dispatch(actions.pushTagItem({
        id: parseInt(id || '0', 10), type: key, color, name, image,
      } as TagItem));
    });
  }

  const handleRegenerate = () => {
    if (chatLoading && selectedConvoId === generatingChannelId) {
      dispatch(actions.setStopGenerating(pendingChatId));
      setIsStopGenerating(true);
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_stop_generating', startTime, {}),
      ));
    } else if (!chatLoading) {
      dispatch(actions.setIsRegenerate(true));
      setInputText(chatHistory[chatHistory.length - 1]?.prompt);
      dispatch(UiAction.isGilroyTextHeightIncrease(true));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_regenerate_intent', startTime, {}),
      ));
      handleSpanTags(chatHistory[chatHistory.length - 1]?.prompt);
    }
  };

  const handleScroll = () => {
    if (scrollableRef.current && !chatLoading) {
      setScrollPosition(scrollableRef?.current?.scrollTop || 0);
    }
  };

  useEffect(() => {
    const scrollWrapperElement = scrollableRef.current;
    scrollWrapperElement?.addEventListener('scroll', handleScroll);
    return () => {
      scrollWrapperElement?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollableRef]);

  useEffect(() => {
    const scrollWrapperElement = scrollableRef.current;
    if (scrollWrapperElement && !chatLoading && !chatHistoryLoading
      && !isEditChatFlag && !isOutputScroll) {
      scrollWrapperElement.scrollTop = scrollPosition;
    }
  }, [scrollPosition, chatHistoryLoading]);

  useEffect(() => {
    setScrollPosition(0);
  }, [selectedConvoId]);

  useEffect(() => {
    if (!chatLoading) {
      setOutputScroll(true);
      scrollToTarget('.output-js', scrollableRef.current);
    } else if (chatLoading) {
      setOutputScroll(false);
      scrollToTarget('.query-js', scrollableRef.current);
    }
  }, [chatLoading]);

  useEffect(() => {
    if (chatHistoryLoading && chatHistory.length) {
      setOutputScroll(false);
    }
  }, [chatHistoryLoading]);

  const showEmptyStateHistory = () => !chatHistory.length && !chatLoading && !chatHistoryLoading
    && (selectedConvoId === 0 || (selectedConvoId === -1 && !conversationSections.length));

  return (
    <StyledWrapper
      className={classNames('chatbot-history', {
        emptyStateWrapper: !chatHistory.length && !chatLoading,
        globalGilroyV3Wrapper: isGilroyV3,
      })}
      editOutputId={editOutputId}
      dynamicHeightMobileEdit={dynamicHeightMobileEdit as number}
    >
      <BackButtonSecondaryHeader title="Chat" onClick={handleBack} />
      {showEmptyStateHistory() ? (
        <EmptyStateHistory />
      ) : (
        <div
          className="scroll-wrapper-chat-history"
          id="scrollWrapperChat"
          ref={scrollableRef}
          onScroll={handleScroll}
        >
          <InfiniteScroll
            dataLength={chatHistory?.length}
            next={fetchMoreChat}
            hasMore
            loader={false}
            className="infinite-scroll-gilroy-chat"
            inverse
            scrollableTarget="scrollWrapperChat"
          >
            {chatHistoryLoading && chatHistory.length
              ? <SkeletonCustom width="97%" height="60px" diameter="4px !important" logo logoSize="50px" /> : null}
            {chatHistory?.map((chatObject) => (
              <>
                <UserQuery
                  queryTitle={chatObject.prompt}
                  chatId={chatObject?.id}
                  isFavourite={chatObject?.isFavourite}
                  promptId={chatObject?.promptId}
                  categories={chatObject?.categories}
                />
                <GPTGeneratedOutput
                  chatObject={chatObject}
                  onEditClickFn={onEditClick}
                  selectedConvoId={selectedConvoId}
                  onRegenerate={handleRegenerate}
                />
              </>
            ))}
            {chatHistoryLoading && !chatHistory.length
              ? <ChatBotHistorySkeletonComponent /> : null}
            {chatLoading && generatingChannelId === selectedConvoId
              ? (
                <div className={classNames('loader-div', { noChatLoader: chatHistory.length === 0 })}>
                  <UserQuery queryTitle={promptInProgress} />
                  <div className="loader-wrap-animated">
                    <img src={loadingGifV3} alt="gillRoyAnimation" className="loader-gif" />
                  </div>
                </div>
              )
              : null}
          </InfiniteScroll>
        </div>
      )}
      {(chatLoading) && !isEditChatFlag && !chatHistoryLoading
        && !(chatLoading && selectedConvoId !== generatingChannelId)
        ? (
          <RegenerateBtnGilroyChat
            stopFlag={chatLoading && selectedConvoId === generatingChannelId}
            onClick={handleRegenerate}
            prompt={chatHistory[chatHistory.length - 1]?.prompt}
            chatBoxOpen={isEditChatOpenFlag}
          />
        ) : null}
    </StyledWrapper>
  );
};
ChatBotHistory.defaultProps = defaultProps;
export default ChatBotHistory;
