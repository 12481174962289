/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AddMemerClickType,
  BuyersHighlights, CategoryStages, MemberCategories, MemberCategoryData,
  RecommendedPeopleResponseType, SelectedGroupCategory, SetMemberCategoryData,
  ToolTipOfTopPeople, TopPeople, TopPeopleFilters,
} from 'Types/BuyerGroupsPageTypes';
import { BuyerGroupsPageState } from 'Types/GlobalTypes';
import { PersonAddedInGroup } from 'Types/GroupsTypes';
import { NonFollowed, PersonalityTypes } from 'Types/PersonTypes';
import {
  AddToFavoriteRequest,
  ChangeCategory,
  EditGroupTitleRequest,
  GetBuyerGroupHighlightsRequest,
  GetBuyerGroupPageMembersRequest,
  GetNotMembers,
  GetTopPeopleRequest,
  PersonData,
  SetChangeCategory,
} from 'Types/RequestBodies';
import { Filter } from 'Types/FilterTypes';
import { stringToCamelCase } from 'Utils/UtilityFunctions';
import { TopGroupPeopleData } from '../Types/BuyerGroupsPageTypes.d';

export const initialState: BuyerGroupsPageState = {
  topPeople: {
    title: '',
    type: '',
    groupName: '',
    groupId: '',
    data: [],
    groupShared: 0,
    sharedByFirstName: 'none',
    chatgptAllowed: 0,
  },
  topPeopleLoading: true,
  highlightsLoading: true,
  highlights: null,
  highlightsPageNumber: 1,
  highlightsEnded: false,

  membersLoading: true,
  membersInitialLoading: true,
  members: null,
  membersEnded: false,
  membersPageNumber: 1,
  filtersArrayLoading: true,
  filtersArray: [],

  peopleRemovedFlag: false,
  titleUpdateLoading: false,
  titleUpdateLoadingForce: false,
  noGroupsMembersLoad: false,
  recommendedLoad: false,
  noGroupsMembers: [],
  recommendedPeople: [],
  recommendedEnded: false,
  noGroupMembersEnded: false,
  noMembersPage: 1,
  addingPersonLoad: false,
  selectedFiltersArray: [],
  isAddRemovePerson: false,
  isError: false,
  reasonPopupLoading: false,
  groupDeleted: false,
  personRemoved: false,
  peopleDrawerOpen: false,
  totalMembers: 0,
  type: null,
  recommendedPage: null,
  recomendedpeopleCount: 0,
  followedPeopleCount: 0,
  memberCategories: {
    buyers: { } as CategoryStages,
    championBuyers: { } as CategoryStages,
    decisionMakers: { } as CategoryStages,
    technicalBuyers: { } as CategoryStages,
    economicBuyers: { } as CategoryStages,
  },
  buyerTooltip: {
    Doers: {} as ToolTipOfTopPeople,
    Coaches: {} as ToolTipOfTopPeople,
    Blockers: {} as ToolTipOfTopPeople,
  },
  memberCategoryLoading: {
    stage: '',
    category: '',
  },
  dragPersonPersonality: [],
};

const BuyerGroupsPage = createSlice({
  name: 'buyerGroupsPage',
  initialState,
  reducers: {
    getTopPeople(state, action: PayloadAction<GetTopPeopleRequest>) {
      state.topPeopleLoading = true;
    },
    setTopPeople(state, action: PayloadAction<TopGroupPeopleData>) {
      state.topPeopleLoading = false;
      state.topPeople = action.payload;
    },
    getHighlights(state, action: PayloadAction<GetBuyerGroupHighlightsRequest>) {
      state.highlightsLoading = true;
    },
    setHighlights(state, action: PayloadAction<BuyersHighlights>) {
      state.highlightsLoading = false;
      if (state.highlights === null || state.highlightsPageNumber === 1) {
        state.highlights = action.payload;
      } else {
        state.highlights.articles = [
          ...state.highlights.articles,
          ...action.payload.articles,
        ];
      }
      state.highlightsEnded = action.payload.articles.length === 0;
      state.highlightsPageNumber += 1;
      state.isAddRemovePerson = false;
    },
    getMembers(state, action: PayloadAction<GetBuyerGroupPageMembersRequest>) {
      state.membersLoading = true;
    },
    setMembers(state, action: PayloadAction<TopPeople>) {
      state.membersLoading = false;
      state.membersInitialLoading = false;
      if (state.members === null || state.membersPageNumber === 1) state.members = action.payload;
      else {
        state.members.data = state.members.data.map((memberGroup) => {
          const index = action.payload.data.findIndex(
            (memberGroupFromServer) => memberGroupFromServer.type === memberGroup.type,
          );
          if (index !== -1) {
            memberGroup.persons = [...memberGroup.persons,
              ...action.payload.data[index].persons];
          }
          return memberGroup;
        });
      }
      state.membersPageNumber += 1;
      state.membersEnded = action.payload.data.filter(
        (groupMember) => groupMember.persons.length === 0,
      ).length === action.payload.data.length;
      for (let i = 0; i < 3; i += 1) {
        state.members.data[i].persons = state.members.data[i].persons.filter(
          (person, index) => state?.members?.data[i].persons.map(
            (p) => p.personId,
          ).indexOf(person.personId) === index,
        );
      }
    },
    setTotalMembers(state, action: PayloadAction<number>) {
      state.totalMembers = action.payload;
    },
    setFilterArray(state, action: PayloadAction<TopPeopleFilters>) {
      state.filtersArrayLoading = false;
      state.filtersArray = action.payload.data;
    },
    setFilterArrayV2(state, action: PayloadAction<Filter[]>) {
      state.filtersArrayLoading = false;
      state.filtersArray = action.payload;
    },
    setMembersPageNumber(state, action: PayloadAction<number>) {
      state.membersPageNumber = action.payload;
    },
    resetMembersPageNumber(state, action) {
      state.membersPageNumber = 1;
    },
    resetMembers(state, action) {
      state.membersLoading = true;
      state.membersInitialLoading = true;
      state.members = null;
      state.membersEnded = false;
      state.membersPageNumber = 1;
      state.memberCategories = {
        buyers: { } as CategoryStages,
        championBuyers: { } as CategoryStages,
        decisionMakers: { } as CategoryStages,
        technicalBuyers: { } as CategoryStages,
        economicBuyers: { } as CategoryStages,
      };
      state.filtersArrayLoading = true;
      state.filtersArray = [];
    },
    resetHighlights(state, action) {
      state.highlightsLoading = true;
      state.highlights = null;
      state.highlightsPageNumber = 1;
      state.highlightsEnded = false;
    },
    handleRemovedPeople(state, action: PayloadAction<number[]>) {
      const ids = action.payload;
      state.peopleRemovedFlag = true;
      let length = state.members?.data ? state.members.data.length : 0;
      for (let i = 0; i < length; i += 1) {
        if (state.members?.data[i]) {
          const persons = state.members?.data[i].persons;
          state.members.data[i].persons = persons.filter(
            (person) => ids.findIndex((id) => person.personId === id) === -1,
          );
        }
      }
      if (state.members?.membersCount) state.members.membersCount -= ids.length;
      length = state.topPeople?.data ? state.topPeople?.data.length : 0;
      if (state.topPeople.data.length) {
        state.topPeople.data = state.topPeople?.data.filter(
          (person) => ids.findIndex((id) => person.personId === id) === -1,
        );
      }
      state.peopleRemovedFlag = true;
      state.totalMembers -= ids.length;
    },
    handleRemovedPeopleV2(state, action: PayloadAction<SelectedGroupCategory[]>) {
      const people = action.payload;
      // const ids = action.payload;
      state.peopleRemovedFlag = true;
      action.payload?.forEach((person) => {
        const { id, stage, category } = person;
        const updatedCategory = stringToCamelCase(category || '') as keyof MemberCategories;
        const updatedStage = stage as keyof CategoryStages;
        const { persons } = state.memberCategories[updatedCategory][updatedStage];
        state.memberCategories[updatedCategory][updatedStage].persons = persons.filter(
          (item) => item.personId !== id,
        );
        state.memberCategories[updatedCategory][updatedStage].membersCount -= 1;
      });
      if (state.topPeople.data.length) {
        state.topPeople.data = state.topPeople?.data.filter(
          (person) => action.payload.findIndex((item) => person.personId === item?.id) === -1,
        );
      }
      state.peopleRemovedFlag = true;
      state.totalMembers -= action.payload.length;
    },
    handlePersonNotes(state, action: PayloadAction<number>) {
      const id = action.payload;
      const members = state.members?.data ?? [];
      const filteredPerson = members
        .flatMap((member) => member.persons)
        .find((person) => person.personId === id);
      if (filteredPerson) {
        filteredPerson.personNotes = 0;
      }
    },
    handlePeopleRemovedFlag(state, action: PayloadAction<boolean>) {
      state.peopleRemovedFlag = action.payload;
    },
    editTitleOfGroup(state, action: PayloadAction<EditGroupTitleRequest>) {
      state.titleUpdateLoading = true;
    },
    handleEditedTitleOfGroup(state, action: PayloadAction<string>) {
      state.titleUpdateLoading = false;
      if (state.topPeople) state.topPeople.groupName = action.payload;
      if (state.highlights) state.highlights.groupName = action.payload;
    },
    titleUpdateLoadingForce(state, action: PayloadAction<boolean>) {
      state.titleUpdateLoadingForce = action.payload;
    },
    getMembersNotInGroup(state, action: PayloadAction<GetNotMembers>) {
      state.noGroupsMembersLoad = true;
      if (action.payload.page === 1) {
        state.noGroupsMembers = [];
        state.noMembersPage = 1;
        state.noGroupMembersEnded = false;
      }
      state.isError = false;
    },
    getRecommendedPeople(state, action: PayloadAction<GetNotMembers>) {
      state.recommendedLoad = true;
      if (action.payload.page === 1 || !action.payload.page) {
        state.recommendedEnded = false;
        state.recommendedPeople = [];
      }
      state.isError = false;
    },
    resetRecommendedPeople(state) {
      state.recommendedLoad = false;
      state.recommendedEnded = false;
      state.recommendedPeople = [];
      state.type = null;
      state.recommendedPage = null;
    },
    resetNoGroupMembers(state) {
      state.noGroupsMembersLoad = false;
      state.noGroupMembersEnded = false;
      state.noGroupsMembers = [];
      state.noMembersPage = 1;
    },
    NotMembersInGroup(state, action: PayloadAction<NonFollowed>) {
      if (state.noGroupsMembersLoad) {
        const membersTemp = action.payload.members.map((m) => ({ ...m, isAddClicked: false }));
        state.noGroupsMembers = state.noGroupsMembers.concat(membersTemp);
        state.noGroupsMembersLoad = false;
        if (state.followedPeopleCount === 0 && action.payload.followedPeople > 0) {
          state.followedPeopleCount = action.payload.followedPeople;
        }
        state.noMembersPage += 1;
        if (!action.payload.members.length) {
          state.noGroupMembersEnded = true;
        }
      }
    },
    recommendedPeopleInGroup(state, action: PayloadAction<RecommendedPeopleResponseType>) {
      const {
        members, type, page, peopleCount,
      } = action.payload;
      if (state.recommendedLoad) {
        const membersTemp = members.map((m) => ({ ...m, isAddClicked: false }));
        state.recommendedPeople = state.recommendedPeople?.concat(membersTemp);
        state.recommendedPage = page ?? 0;
        state.type = type ?? 0;
        if (state.recomendedpeopleCount === 0 && peopleCount) {
          state.recomendedpeopleCount = peopleCount ?? 0;
        }
        if (!page) {
          state.recommendedEnded = true;
        }
      }
      state.recommendedLoad = false;
    },
    removePersonAfterAdd(state, action: PayloadAction<PersonData>) {
      if (state.noGroupsMembers?.length) {
        state.noGroupsMembers = state.noGroupsMembers?.filter(
          (member) => member.personId !== action.payload?.entity_id,
        );
      }
      if (state.recommendedPeople?.length) {
        state.recommendedPeople = state.recommendedPeople?.filter(
          (member) => member.personId !== action.payload?.entity_id,
        );
      }
    },
    personAddedInGroup(state, action: PayloadAction<PersonAddedInGroup>) {
      const { results } = action.payload;
      if (results.length && results[0].personData && results[0].personData.length) {
        state.totalMembers += 1;
        state.topPeople.data = [
          ...results[0].personData[0].persons,
          ...state.topPeople.data,
        ];
        const { persons, stage, category } = results[0].personData && results[0].personData[0];
        const selectedFilter = state.filtersArray.map((item) => item.isSelected && item.filterName);
        if (selectedFilter.includes(persons[0].archeType.name)) {
          const updatedCategory = stringToCamelCase(category || '') as keyof MemberCategories;
          const updatedStage = stage as keyof CategoryStages;
          state.memberCategories[updatedCategory][updatedStage].persons.unshift(persons[0]);
          state.memberCategories[updatedCategory][updatedStage].membersCount += 1;
        }
      }
    },
    selectedFilter(state, action: PayloadAction<Filter[]>) {
      const { payload } = action;
      state.selectedFiltersArray = payload;
    },
    isAddRemovePersonInGroup(state, action: PayloadAction<boolean>) {
      state.isAddRemovePerson = action.payload;
    },
    setIsError(state, action: PayloadAction<boolean>) {
      state.isError = true;
    },
    changeCategory(state, action: PayloadAction<ChangeCategory>) {
      state.reasonPopupLoading = true;
    },
    changeCategoryV2(state, action: PayloadAction<ChangeCategory>) {
      state.reasonPopupLoading = true;
    },
    setChangeCategory(state, action: PayloadAction<SetChangeCategory>) {
      const {
        categoryPrev, categoryNext, stagePrev, stageNext, personId,
      } = action.payload;

      const personIndex = state.memberCategories[categoryPrev][stagePrev].persons.findIndex(
        (person) => person?.personId === personId,
      );

      if (personIndex !== -1) {
        const [person] = state.memberCategories[categoryPrev][stagePrev].persons
          .splice(personIndex, 1);
        person.changed = true;
        state.memberCategories[categoryNext][stageNext].persons.unshift(person);
        state.memberCategories[categoryPrev][stagePrev].membersCount -= 1;
        state.memberCategories[categoryNext][stageNext].membersCount += 1;
      }
    },
    setReasonPopupLoading(state, action: PayloadAction<boolean>) {
      state.reasonPopupLoading = action.payload;
    },
    setGroupDeleted(state, action: PayloadAction<boolean>) {
      state.groupDeleted = action.payload;
    },
    setPersonRemoved(state, action: PayloadAction<boolean>) {
      state.personRemoved = action.payload;
    },
    clearStates(state) {
      state.recomendedpeopleCount = 0;
      state.followedPeopleCount = 0;
    },
    setPeopleDraweropen(state, action: PayloadAction<boolean>) {
      state.peopleDrawerOpen = action.payload;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        if (state.highlights?.articles && state.highlights?.articles.length) {
          const matchedArticles = state.highlights?.articles.filter(
            (article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem) => {
              const article = arrayItem;
              article.isFavorite = favoriteFlag === 1;
            });
          }
        }
      }
    },
    setType(state, action: PayloadAction<number | null>) {
      state.type = action.payload;
    },
    setRecommendedPage(state, action: PayloadAction<number | null>) {
      const page = action.payload;
      state.recommendedPage = page;
      if (page === 0) {
        state.recommendedEnded = true;
      }
    },
    memberAddClicked(state, action: PayloadAction<AddMemerClickType>) {
      const { personId, clicked, isRecommended } = action.payload;
      state.recommendedPeople = state.recommendedPeople.map((member) => ({
        ...member,
        isAddClicked: member.personId === personId ? clicked : member.isAddClicked,
      }));
      state.noGroupsMembers = state.noGroupsMembers.map((member) => ({
        ...member,
        isAddClicked: member.personId === personId ? clicked : member.isAddClicked,
      }));
      if (isRecommended) {
        state.recomendedpeopleCount -= 1;
      } else {
        state.followedPeopleCount -= 1;
      }
    },
    getMembersV2(state, action: PayloadAction<GetBuyerGroupPageMembersRequest>) {
      const { stage, category } = action.payload;
      state.membersLoading = true;
      if (stage && category) {
        state.memberCategoryLoading = {
          stage, category,
        };
      }
    },
    setMembersV2(state, action: PayloadAction<SetMemberCategoryData>) {
      const {
        offset, members, tooltip, totalMembers,
      } = action.payload;
      state.membersLoading = false;
      state.membersInitialLoading = false;
      const handleStage = (category: MemberCategoryData, categoryType: keyof MemberCategories) => {
        if (category?.stage === 'Doers') {
          if (offset) {
            state.memberCategories[categoryType].Doers.persons = [
              ...state.memberCategories[categoryType].Doers.persons, ...category.persons];
          } else {
            state.memberCategories[categoryType].Doers = category;
          }
        } else if (category?.stage === 'Coaches') {
          if (offset) {
            state.memberCategories[categoryType].Coaches.persons = [
              ...state.memberCategories[categoryType].Coaches.persons, ...category.persons];
          } else {
            state.memberCategories[categoryType].Coaches = category;
          }
        } else if (category?.stage === 'Blockers') {
          if (offset) {
            state.memberCategories[categoryType].Blockers.persons = [
              ...state.memberCategories[categoryType].Blockers.persons, ...category.persons];
          } else {
            state.memberCategories[categoryType].Blockers = category;
          }
        }
      };
      members?.forEach((category) => {
        if (category?.category === 'Buyers') {
          handleStage(category, 'buyers');
        } else if (category?.category === 'Champion Buyers') {
          handleStage(category, 'championBuyers');
        } else if (category?.category === 'Decision Makers') {
          handleStage(category, 'decisionMakers');
        } else if (category?.category === 'Technical Buyers') {
          handleStage(category, 'technicalBuyers');
        } else if (category?.category === 'Economic Buyers') {
          handleStage(category, 'economicBuyers');
        }
      });
      if (!offset) {
        state.totalMembers = totalMembers;
        state.buyerTooltip = tooltip;
      }
      state.memberCategoryLoading = {
        stage: '',
        category: '',
      };
    },
    setDragPersonPersonality(state, action: PayloadAction<PersonalityTypes[]>) {
      state.dragPersonPersonality = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = BuyerGroupsPage;
