/* eslint-disable react/style-prop-object */
import React from 'react';

const XiqGptEmailGilroySvg = function XiqGptEmailGilroySvg() {
  return (
    <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg" className="gillRoy">
      <path d="M5.51904 23.5806C6.97252 25.268 9.1101 26.3358 11.4947 26.3358H19.8776C22.2021 26.3358 24.2932 25.3225 25.743 23.7092C25.7223 23.1492 25.6972 22.8213 25.6972 22.8213H5.5598C5.5598 22.8213 5.53848 23.0997 5.51904 23.5806Z" fill="url(#paint0_linear_80_3104)" />
      <path d="M14.9985 9.68971L15.5058 1.22167L15.7384 1.21729L16.3661 9.88472L14.9985 9.68971Z" fill="#001760" />
      <path d="M15.6179 1.34368C15.925 1.34368 16.174 1.0947 16.174 0.787564C16.174 0.480428 15.925 0.231445 15.6179 0.231445C15.3108 0.231445 15.0618 0.480428 15.0618 0.787564C15.0618 1.0947 15.3108 1.34368 15.6179 1.34368Z" fill="#26CF0A" />
      <path d="M27.8023 12.0249H25.1593C23.5687 12.0249 22.2793 13.3143 22.2793 14.9049V17.0048C22.2793 18.5953 23.5687 19.8847 25.1593 19.8847H27.8023C29.3928 19.8847 30.6823 18.5953 30.6823 17.0048V14.9049C30.6823 13.3143 29.3928 12.0249 27.8023 12.0249Z" fill="#001760" />
      <path d="M6.20509 12.0249H3.56212C1.97155 12.0249 0.682129 13.3143 0.682129 14.9049V17.0048C0.682129 18.5953 1.97155 19.8847 3.56212 19.8847H6.20509C7.79567 19.8847 9.08509 18.5953 9.08509 17.0048V14.9049C9.08509 13.3143 7.79567 12.0249 6.20509 12.0249Z" fill="#001760" />
      <path d="M21.0565 6.35352H10.3078C6.49849 6.35352 3.4104 9.44155 3.4104 13.2508V18.6609C3.4104 22.4701 6.49849 25.5582 10.3078 25.5582H21.0565C24.8659 25.5582 27.954 22.4701 27.954 18.6609V13.2508C27.954 9.44155 24.8659 6.35352 21.0565 6.35352Z" fill="#26CF0A" />
      <path d="M21.1542 8.88574H10.2111C7.85626 8.88574 5.94727 10.7947 5.94727 13.1495V18.7615C5.94727 21.1163 7.85626 23.0253 10.2111 23.0253H21.1542C23.5091 23.0253 25.4181 21.1163 25.4181 18.7615V13.1495C25.4181 10.7947 23.5091 8.88574 21.1542 8.88574Z" fill="white" />
      <path style={{ mixBlendMode: 'hard-light' }} opacity="0.3" d="M23.0938 11.5334H8.27061V10.9873H23.0938V11.5334ZM24.7179 11.9341H6.64721V12.4803H24.7179V11.9341ZM26.0836 12.8609H5.28152V13.407H26.0836V12.8609ZM26.9495 13.8071H4.41558V14.3532H26.9495V13.8071ZM27.5552 14.7532H3.80923V15.2994H27.5552V14.7532ZM28.075 15.6994H3.29004V16.2456H28.075V15.6994ZM28.075 16.6262H3.29004V17.1723H28.075V16.6262ZM27.5552 17.5724H3.80923V18.1185H27.5552V17.5724ZM26.6316 18.5029H4.73286V19.049H26.6316V18.5029ZM24.9292 19.4491H6.4359V19.9952H24.9292V19.4491ZM21.0039 20.3758H10.3612V20.922H21.0039V20.3758Z" fill="url(#paint1_radial_80_3104)" />
      <path d="M13.7031 18.7036C15.0274 19.2046 16.3361 19.2498 17.6623 18.8911C16.78 20.5132 14.7641 20.8111 13.7031 18.7036Z" fill="#26CF0A" />
      <path d="M12.7928 14.3163C12.8016 15.1584 12.1256 15.8475 11.2841 15.8569C10.442 15.8657 9.7529 15.1898 9.74349 14.3483C9.73471 13.5062 10.4107 12.8171 11.2521 12.8077C12.0943 12.7989 12.7834 13.4749 12.7928 14.3163Z" fill="url(#paint2_linear_80_3104)" />
      <path d="M11.9867 13.5903C11.9172 13.7143 11.6598 13.7011 11.4124 13.5621C11.165 13.4231 11.021 13.2095 11.0905 13.0861C11.16 12.9621 11.4174 12.9753 11.6648 13.1143C11.9121 13.2533 12.0562 13.4669 11.9867 13.5903Z" fill="#FDFDFD" />
      <path d="M11.2761 15.1204C10.5606 15.128 9.95744 14.6401 9.78563 13.9786C9.75741 14.0965 9.74174 14.22 9.74299 14.3461C9.75177 15.1882 10.4415 15.8635 11.2836 15.8547C12.1257 15.8459 12.8011 15.1562 12.7923 14.3141C12.7904 14.1868 12.7728 14.0645 12.7421 13.9473C12.5841 14.6132 11.9909 15.1129 11.2755 15.1204H11.2761Z" fill="#26CF0A" />
      <path d="M21.6221 14.2563C21.6309 15.0984 20.955 15.7875 20.1135 15.7969C19.2714 15.8057 18.5822 15.1297 18.5728 14.2883C18.5641 13.4462 19.24 12.757 20.0815 12.7476C20.9236 12.7389 21.6127 13.4148 21.6221 14.2563Z" fill="url(#paint3_linear_80_3104)" />
      <path d="M20.8158 13.5312C20.7463 13.6552 20.4889 13.642 20.2415 13.503C19.9941 13.364 19.8501 13.1504 19.9196 13.027C19.9891 12.903 20.2465 12.9162 20.4939 13.0552C20.7412 13.1943 20.8853 13.4078 20.8158 13.5312Z" fill="#FDFDFD" />
      <path d="M20.1054 15.0628C19.39 15.0703 18.7868 14.5825 18.615 13.921C18.5868 14.0389 18.5711 14.1624 18.5723 14.2884C18.5811 15.1305 19.2709 15.8059 20.113 15.7971C20.9551 15.7883 21.6304 15.0986 21.6216 14.2565C21.6197 14.1292 21.6022 14.0069 21.5715 13.8896C21.4135 14.5556 20.8203 15.0553 20.1048 15.0628H20.1054Z" fill="#26CF0A" />
      <defs>
        <linearGradient id="paint0_linear_80_3104" x1="15.625" y1="26.2837" x2="15.6307" y2="23.7756" gradientUnits="userSpaceOnUse">
          <stop stopColor="#21AD04" />
          <stop offset="1" stopColor="#26CF0A" />
        </linearGradient>
        <radialGradient id="paint1_radial_80_3104" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.7081 15.9546) scale(10.2589 6.79387)">
          <stop stopColor="#26CF0A" />
          <stop offset="0.23" stopColor="#43B430" />
          <stop offset="0.52" stopColor="#64985B" />
          <stop offset="0.75" stopColor="#788676" />
          <stop offset="0.88" stopColor="#808080" />
        </radialGradient>
        <linearGradient id="paint2_linear_80_3104" x1="9.74349" y1="14.3314" x2="12.7928" y2="14.3314" gradientUnits="userSpaceOnUse">
          <stop stopColor="#21AD04" />
          <stop offset="0.83" stopColor="#26CF0A" />
          <stop offset="0.99" stopColor="#26CF0A" />
        </linearGradient>
        <linearGradient id="paint3_linear_80_3104" x1="21.6215" y1="14.2713" x2="18.5722" y2="14.2713" gradientUnits="userSpaceOnUse">
          <stop stopColor="#21AD04" />
          <stop offset="1" stopColor="#26CF0A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default XiqGptEmailGilroySvg;
