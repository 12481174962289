import SingleFavoriteIcon from 'Components/common/assets/svgIcons/SingleFavoriteIcon';
import CategoryPopover from 'Components/common/components/CategoryPopover';
import IconTooltipGlobal from 'Components/common/components/IconTooltipGlobal';
import PeopleWidgetWithOutHolo from 'Components/common/components/PeopleWidgetWithOutHolo';
import { actions } from 'Slice/ChatbotV2Slice';
import { translateSkipAreas } from 'Utils/Constants';
import { getLocalStorageValue, isFreeUser, replaceTags } from 'Utils/UtilityFunctions';
import {
  SecondaryColor, maxTablet, primaryColor, primaryFont, wLight, wMBold,
} from 'Utils/utilsStyle';
import HTMLReactParser from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PromptCategoryV2 } from 'Types/ChatBotV2Types';
import { selectCategoryPermission, selectChatLoading } from 'Selector/ChatBotV2Selector';
import CategoriesOfExamplePrompt from '../../chatbotLibrary/component/CategoriesOfExamplePrompt';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 22px 20px 22px 14px;
  background: #F9F8F6;
  border-radius: 8px;
  @media all and (max-width: ${maxTablet}) {
    padding: 8px 14px 8px 14px;
  }
  .left-side {
    .person-without-holo {
      @media all and (max-width: ${maxTablet}) {
        width: 24px;
        height: 24px;
        min-width: 24px;
        margin: 1.5px 0px;
        padding: 1px !important;
      }
    }
  }
  .right-side {
    font-family: ${primaryFont};
    font-weight: ${wLight};
    font-size: 16px;
    line-height: 24px;
    color: #0D0D0D;
    margin-left: 15px;
    word-break: break-word;
    margin-top: 8px;
    @media all and (max-width: ${maxTablet}) {
      font-size: 12px;
      line-height: 24px;
      margin-left: 5px;
      padding-left: 3px;
      margin-top: 0px;
    }
    .actions-div-query {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 6px;
      .icon-tooltip-global-wrapper {
        height: 16px;
        @media all and (max-width: ${maxTablet}) {
          height: 20px;
        }
        .icon-tooltip-global {
          @media all and (max-width: ${maxTablet}) {
            height: 20px;
          }
          .custom-favorite-icon {
            &:hover {
              path {
                stroke: ${primaryColor};
              }
            }
            cursor: pointer;
            width: 16px;
            height: 17px;
            @media all and (max-width: ${maxTablet}) {
              height: 12px;
              width: 12px;
            }
            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }
      .prompt-category-popover {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        @media all and (max-width: ${maxTablet}) {
          height: 20px;
        }
        .icon-tooltip-global-wrapper {
          height: 16px;
          @media all and (max-width: ${maxTablet}) {
            height: 20px;
          }
          .icon-tooltip-global {
            @media all and (max-width: ${maxTablet}) {
              height: 20px;
            }
            .plus-icon-svg {
              height: 17px;
              @media all and (max-width: ${maxTablet}) {
                height: 10px;
                width: 12px;
              }
            }
          }
        }
      }
      .category-type {
        height: 18px;
        line-height: 14px;
        margin-left: 8px;
        @media all and (max-width: ${maxTablet}) {
          line-height: 16px;
        }
      }
    }
    .prompt-input-style {
      color: ${SecondaryColor};
      font-family: ${primaryFont};      
      font-weight: ${wMBold};
      font-style: italic;
      &.highlight {
        background: unset;
        border: 0;
      }
    }
  }
`;

type Props = {
  isFavourite?: boolean;
  chatId?: number
  queryTitle?: string
  promptId?: number
  categories?: PromptCategoryV2[]
};

const defaultProps = {
  isFavourite: false,
  chatId: 0,
  queryTitle: '',
  promptId: 0,
  categories: [],
};

const UserQuery = function UserQuery(props: Props) {
  const {
    chatId, queryTitle, isFavourite, promptId, categories,
  } = props;
  const categoryPermissions = useSelector(selectCategoryPermission);
  const chatLoading = useSelector(selectChatLoading);
  const profilePic = getLocalStorageValue('profilePic');
  const personalityColor = getLocalStorageValue('personalitycolors');
  const dispatch = useDispatch();

  const handleFavoritePrompt = () => {
    dispatch(actions.markFavoritePrompt({
      isNew: !promptId,
      isFavourite: !isFavourite,
      ...(chatId && { chatId }),
      prompt: replaceTags(queryTitle || ''),
      ...(promptId && { promptId }),
    }));
  };

  return (
    <Wrapper className="query-js skiptranslate">
      <div className="left-side">
        <PeopleWidgetWithOutHolo
          logo={profilePic || ''}
          diameter="35px"
          diameterSm="35px"
          diameterXsm="35px"
          ringPadding="2px"
          ringPaddingSm="2px"
          margin="0px 3px 0px"
          wrapperColor={personalityColor || ''}
        />
      </div>
      <div className={`right-side ${translateSkipAreas}`}>
        {HTMLReactParser(queryTitle || '')}
        {!chatLoading ? (
          <div className="actions-div-query">
            <IconTooltipGlobal
              tooltipPlacement="bottom"
              tooltipTitle={isFavourite ? 'Remove from Favorites' : 'Add to Favorites'}
              icon={(
                <SingleFavoriteIcon
                  fillColor={isFavourite ? primaryColor : '#F9F8F6'}
                  strokeColor={isFavourite ? primaryColor : '#667982'}
                  onClick={handleFavoritePrompt}
                  isPrompt
                />
              )}
            />
            {categoryPermissions && !isFreeUser()
              ? (
                <CategoryPopover
                  chatId={chatId}
                  prompt={queryTitle}
                  promptId={promptId}
                  promptCategories={categories}
                  popoverPlacement="bottomLeft"
                  customClassName="user-query-popover"
                />
              )
              : null}
            <CategoriesOfExamplePrompt promptCategories={categories?.map((c) => c.title) || []} />
          </div>
        ) : null}
      </div>
    </Wrapper>
  );
};

UserQuery.defaultProps = defaultProps;
export default UserQuery;
