/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/PersonProfileSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';
import _ from 'lodash';
import {
  API, COMPANY_OVERVIEW_ROUTE, DIGEST_FAILED_TOASTER,
  FILE_DOWNLOAD_FAIL, FILE_DOWNLOAD_SUCCESS, SEARCH_ROUTE,
} from 'Utils/Constants';
import {
  CreateAIDigestRequest,
  EditPhoneEmailRequest,
  ExportSimilarPeopleRequest,
  GenerateEmail,
  GetPersonProfileRequest,
  GetPersonProfileSocialRequest, PersonCompanyCareer, UpdatePersonProfileFollow,
} from 'Types/RequestBodies';
import {
  AIDigestResponse,
  Compatibility,
  EmailFeedbackResponse,
  Executive,
  ExportFollowPeopleResponse,
  GenerateEmailResponse,
  PersonalityInsights,
  PersonalityInsightsResponse,
  PersonalityTypes,
  PersonCareerCompany,
  PersonCareerResponse,
  PersonDataResponse, PersonIntentDataResponse, PersonNewsResponseType,
  PersonPersonalDetailsResponse, PersonProfileDownloadResponse,
  PersonSocialActivityResponseType, PersonThemeResponse, SearchedPersonalityInsightsResponse,
  WorkbenchStatsResponse,
} from 'Types/PersonTypes';
import { actions as helperActions, actions as flurryActions } from 'Slice/HelperSlice';
import { actions as affinityActions } from 'Slice/AffinitySearchResultsSlice';
import { actions as branchActions } from 'Slice/BranchSlice';

import {
  backgroundColorParsing, getCurrentTime,
  isFreeUser, setLocalStorageValue, valueToObject,
} from 'Utils/UtilityFunctions';
import { GlobalResponse } from 'Types/ResponseBodies';

export function* getPersonProfile(dispatched: PayloadAction<GetPersonProfileRequest>) {
  const startTime = getCurrentTime();
  try {
    const { payload } = dispatched;
    const parameters = { ...payload };
    if ('source' in parameters) {
      _.unset(parameters, 'source');
    }
    if ('entityName' in parameters) {
      _.unset(parameters, 'entityName');
    }
    const response: PersonDataResponse = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_DATA,
      parameters,
    );
    if (response.status === true) {
      let executiveName = '';
      if (response.response.result.length && response.response.result[1].details.length) {
        executiveName = 'personName' in response.response.result[1].details[0]
          ? response.response.result[1].details[0].personName : '';
      }
      yield put(actions.setPersonProfile(response.response));
      if (response.response.popUpResponse.showPlanPopup === 0) {
        yield put(helperActions.callFlurryEvent(
          valueToObject('executive_page_load', startTime, {
            ...payload,
            ...(payload.source && {
              article_entity_name: executiveName,
            }),
            person_name: executiveName,
          }),
        ));
      }
      if (payload?.source) { yield put(branchActions.reset()); }
    } else {
      yield put(actions.setProfileLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPersonTheme(dispatched: PayloadAction<GetPersonProfileRequest>) {
  try {
    const { payload } = dispatched;
    const parameters = { ...payload };
    const response: PersonThemeResponse = yield call(
      axios.post,
      API.GET_PERSON_THEME,
      parameters,
    );
    if (response.status === true) {
      yield put(actions.setPersonTheme(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* createAIDigest(dispatched:PayloadAction<CreateAIDigestRequest>) {
  try {
    const { payload } = dispatched;
    const parameters = { ...payload };
    const response : AIDigestResponse = yield call(
      axios.post,
      API.CREATE_AI_DIGEST,
      parameters,
    );
    if (response.success === true) {
      yield put(actions.workbenchRedirect(true));
      yield put(actions.setShowDigestPopup(false));
      yield put(actions.setPersonDigest(response.data));
    } else {
      yield put(actions.workbenchRedirect(false));
      yield put(actions.setShowDigestPopup(false));
      yield put(flurryActions.setNotification({
        message: DIGEST_FAILED_TOASTER,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* updatePersonProfileFollow(dispatched: PayloadAction<UpdatePersonProfileFollow>) {
  const startTime = getCurrentTime();
  try {
    const response: PersonDataResponse = yield call(
      axios.post,
      API.UPDATE_PERSON_PROFILE_FOLLOW,
      dispatched?.payload,
    );
    if (response.status === true) {
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: response?.response?.groupId,
        classnameFlag: true,
      }));
      yield put(actions.setPersonProfileFollowSuccess(dispatched?.payload));
      if (dispatched?.payload.person_data && dispatched?.payload.person_data.length) {
        yield put(helperActions.callFlurryEvent(
          valueToObject('person_follow_succeeded', startTime, dispatched?.payload),
        ));
      } else {
        yield put(helperActions.callFlurryEvent(
          valueToObject('person_unfollow_succeeded', startTime, dispatched?.payload),
        ));
      }
    } else {
      yield put(actions.setPersonProfileFollowFailure(dispatched?.payload));
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPersonProfileIntentData(dispatched: PayloadAction<GetPersonProfileRequest>) {
  try {
    const data: PersonIntentDataResponse = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_INTENT_DATA,
      dispatched?.payload,
    );
    if (data.status) {
      yield put(actions.setPersonProfileIntentData(data.response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPersonProfilePersonalDetails(
  dispatched: PayloadAction<GetPersonProfileRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const res: PersonPersonalDetailsResponse = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_PERSONAL_DETAILS,
      dispatched?.payload,
    );
    if (res.status) {
      yield put(actions.setPersonProfilePersonalDetails(res.response));
      yield put(helperActions.callFlurryEvent(
        valueToObject('executive_profile_details', startTime, dispatched?.payload),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPersonProfileCareer(dispatched: PayloadAction<GetPersonProfileRequest>) {
  const startTime = getCurrentTime();
  try {
    const res: PersonCareerResponse = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_CAREER_DETAILS,
      dispatched?.payload,
    );
    if (res.status) {
      yield put(actions.setPersonProfileCareer(res.response));
      yield put(helperActions.callFlurryEvent(
        valueToObject('executive_career', startTime, dispatched?.payload),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPersonProfileNews(dispatched: PayloadAction<GetPersonProfileRequest>) {
  const startTime = getCurrentTime();
  try {
    const data: PersonNewsResponseType = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_NEWS,
      dispatched?.payload,
    );
    if (data.status === true) {
      yield put(actions.setPersonProfileNews(data.response));
      if (dispatched.payload.page === 1) {
        yield put(actions.setNewsFlurry({
          ...dispatched?.payload,
          personName: data.response.personName,
          loading_time: _.round((getCurrentTime() - startTime), 2),
        }));
      } else {
        yield put(helperActions.callFlurryEvent(
          valueToObject('executive_news', startTime, {
            ...dispatched?.payload,
            personName: data.response.personName,
          }),
        ));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPersonProfileVideos(dispatched: PayloadAction<GetPersonProfileSocialRequest>) {
  const startTime = getCurrentTime();
  try {
    const data: PersonSocialActivityResponseType = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_VIDEO,
      dispatched?.payload,
    );
    if (data.status === true) {
      if (dispatched.payload.page !== 1) {
        yield put(actions.setMorePersonProfileVideos(data.response));
      } else { yield put(actions.setPersonProfileVideos(data.response)); }

      if (dispatched.payload.page === 1) {
        yield put(actions.setVideoFlurry({
          ...dispatched?.payload,
          personName: data.response.personName,
          loading_time: _.round((getCurrentTime() - startTime), 2),
        }));
      } else {
        yield put(helperActions.callFlurryEvent(
          valueToObject('person_videos', startTime, {
            ...dispatched?.payload,
            personName: data.response.personName,
          }),
        ));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPersonProfileInsights(dispatched: PayloadAction<GetPersonProfileRequest>) {
  try {
    const res: PersonalityInsightsResponse = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_INSIGHTS,
      dispatched?.payload,
    );
    if (res.status === true) {
      yield put(actions.setPersonProfileInsights(res.response));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getSimilarPeople(dispatched: PayloadAction<GetPersonProfileRequest>) {
  try {
    const res: PersonalityInsightsResponse = yield call(
      axios.post,
      API.GET_SIMILAR_PEOPLE,
      dispatched?.payload,
    );
    if (res.status === true) {
      yield put(actions.setSimilarPeople(res.response[0]));
    } else {
      yield put(actions.setEmptySimilarPeople([]));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getSearchedPersonProfileInsights(
  dispatched: PayloadAction<GetPersonProfileRequest>,
) {
  const startTime = getCurrentTime();
  try {
    const res: SearchedPersonalityInsightsResponse = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_DATA,
      dispatched?.payload,
    );
    if (res.status === true) {
      const results: PersonalityInsights[] = res.response.result;
      const { popUpResponse } = res.response;
      if (popUpResponse.showPlanPopup) {
        yield put(actions.setSearchedPersonPopUp(popUpResponse));
        yield put(actions.resetSelectedSearchedPerson());
        yield put(affinityActions.affinitySearchResultReset());
      }
      if (results.length) {
        if (dispatched?.payload?.type === 'LIVESEARCH') {
          const searchResult: PersonalityInsights = results.find((result) => result.type === 'compatibility') as PersonalityInsights;
          if (searchResult) {
            const data = searchResult.data as Compatibility[];
            const matchResult: Compatibility = data.find((result) => result.type === 'match') as Compatibility;
            const { persons } = matchResult;
            const searchedPerson:
            Executive = persons
              ?.find(
                (person: Executive) => person.personId !== dispatched?.payload?.affinity_person,
              ) as Executive;
            yield put(actions.setSelectedSearchedPerson(searchedPerson));
          }
        }
        if (dispatched.payload && dispatched.payload.map_personality === 1) {
          yield put(actions.setPersonProfileInsightsInitial(results));
          yield put(affinityActions.affinitySearchResultReset());
          if (results.length && results[0].data.length) {
            const data: Compatibility = results[0].data[0] as Compatibility;
            if (data.persons.length > 1) {
              const image: string = data.persons[0].personImage;
              const colors: PersonalityTypes[] = data.persons[0].personalityTypes;
              setLocalStorageValue('profilePic', image);
              setLocalStorageValue('personalitycolors', backgroundColorParsing(colors));
            }
          }
        } else {
          yield put(actions.setSearchedPersonProfileInsights(results));
        }
      } else {
        yield put(actions.setPersonProfileInsightsInitial(results));
      }

      yield put(helperActions.callFlurryEvent(
        valueToObject(
          'salesAlignment_search_person_clicked',
          startTime,
          {
            ...dispatched?.payload,
            personName: '',
            type: dispatched.payload.type,
          },
        ),
      ));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPersonProfileDownload(dispatched: PayloadAction<GetPersonProfileRequest>) {
  const startTime = getCurrentTime();
  try {
    const res: PersonProfileDownloadResponse = yield call(
      axios.post,
      API.GET_DOWNLOAD_PERSON_PROFILE,
      dispatched?.payload,
    );
    if (res.status === true) {
      yield put(actions.setPersonProfileDownload(res.response));
      if (res.response.showPlanPopup !== 3) {
        yield put(helperActions.callFlurryEvent(
          valueToObject('executive_pdf', startTime, dispatched?.payload),
        ));
      }

      if (res.response.link) {
        yield put(helperActions.setNotification({
          message: FILE_DOWNLOAD_SUCCESS,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: true,
        }));
      } else if (!isFreeUser() && res.response.showPlanPopup !== 3) {
        yield put(helperActions.setNotification({
          message: FILE_DOWNLOAD_FAIL,
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: false,
        }));
      }
    } else {
      yield put(actions.failPersonProfileDownload('something went wrong'));
    }
  } catch (err) {
    yield put(actions.failPersonProfileDownload('something went wrong'));
    console.log(err);
  }
}

export function* getPersonWorkbenchStats(dispatched: PayloadAction<number>) {
  const data = {
    personId: dispatched?.payload,
  };
  try {
    const res: WorkbenchStatsResponse = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_WB_STATS,
      data,
    );
    if (res?.status === true) {
      const statTiles = res?.response?.filter((stat) => stat.type !== 'engagement' && stat.type !== 'contactId');
      const engagementGraph = res?.response?.find((stat) => stat.type === 'engagement');
      if (statTiles && engagementGraph) {
        const { data: seriesData } = engagementGraph;
        if (seriesData) {
          const allZero = statTiles.every((tile) => tile.value === 0);
          if (allZero) {
            yield put(actions.setEmptyPersonWorkbenchStats());
          } else if (!isFreeUser()) {
            yield put(actions.setPersonWorkbenchStats(res?.response));
          }
        }
      }
    } else {
      yield put(actions.failPersonWorkbenchStats('something went wrong'));
    }
  } catch (err) {
    yield put(actions.failPersonWorkbenchStats('something went wrong'));
    console.log(err);
  }
}
export function* getPersonCareerCompany(dispatched: PayloadAction<PersonCompanyCareer>) {
  const { payload } = dispatched;
  try {
    const response: PersonCareerCompany = yield call(
      axios.post,
      API.GET_PERSON_CAREER_COMPANY_ID,
      payload,
    );

    const { companyId } = response.response;
    if (response?.status === true) {
      yield put(actions.setPersonCareerCompany({
        ...dispatched?.payload,
        companyId,
      }));
      if (companyId > 0) {
        window.open(`${COMPANY_OVERVIEW_ROUTE}${companyId}`);
      } else {
        window.open(`${SEARCH_ROUTE}${encodeURIComponent(payload.companyName || payload.companyUrl)}&extend=company`);
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* generateEmail(dispatched: PayloadAction<GenerateEmail>) {
  const { payload } = dispatched;
  try {
    const response: GenerateEmailResponse = yield call(
      axios.post,
      API.GENERATE_EMAIL,
      payload,
    );
    const { result } = response.response;
    if (response?.status === true && result?.length) {
      yield put(actions.setChat(response?.response?.result[0]));
    } else {
      yield put(actions.setGenerateEmailLoading(false));
      yield put(helperActions.setNotification({
        message: 'Something went wrong. Please try again',
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.setGenerateEmailLoading(false));
    yield put(helperActions.setNotification({
      message: 'Something went wrong. Please try again',
      proTip: false,
      rightPlacement: true,
      groupId: 0,
      classnameFlag: false,
    }));
  }
}

export function* sendFeedback(dispatched: PayloadAction<GenerateEmail>) {
  const { payload } = dispatched;
  try {
    const response: EmailFeedbackResponse = yield call(
      axios.post,
      API.EMAIL_FEEDBACK,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.setFeedbackSubmitted(true));
      yield put(helperActions.setNotification({
        message: 'Feedback Recieved',
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: true,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSimilarPeopleExport(dispatched: PayloadAction<ExportSimilarPeopleRequest>) {
  const { payload } = dispatched;
  try {
    const data = {
      ...payload.companyIds && { companyIds: payload.companyIds },
      personId: payload.personId,
    };
    const response: ExportFollowPeopleResponse = yield call(
      axios.post,
      API.GET_EXPORT_SIMILAR_PEOPLE,
      data,
    );
    if (response?.status) {
      yield put(actions.setSimilarPeopleExport(response.response));
      yield put(helperActions.setNotification({
        message: FILE_DOWNLOAD_SUCCESS,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    } else {
      yield put(helperActions.setNotification({
        message: 'File download failed',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* editPhoneEmail(dispatched: PayloadAction<EditPhoneEmailRequest>) {
  const { payload } = dispatched;
  const text = payload?.phone ? 'Phone number' : 'Email address';
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.EDIT_PHONE_EMAIL,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.phoneEmailEdited(payload));
      yield put(helperActions.setNotification({
        message: `${text} updated successfully.`,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    } else {
      yield put(actions.setEditPhoneEmailLoading(false));
      yield put(helperActions.setNotification({
        message: `${text} not updated successfully.`,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* personProfileSaga() {
  yield takeLatest(actions.getPersonProfile.type, getPersonProfile);
  yield takeLatest(actions.updatePersonProfileFollow.type, updatePersonProfileFollow);
  yield takeLatest(actions.getPersonProfileIntentData.type, getPersonProfileIntentData);
  yield takeLatest(actions.getPersonProfilePersonalDetails.type, getPersonProfilePersonalDetails);
  yield takeLatest(actions.getPersonProfileCareer.type, getPersonProfileCareer);
  yield takeLatest(actions.getPersonProfileNews.type, getPersonProfileNews);
  yield takeLatest(actions.getPersonProfileInsights.type, getPersonProfileInsights);
  yield takeLatest(actions.getSimilarPeople.type, getSimilarPeople);
  yield takeLatest(actions.getSimilarPeopleExport.type, getSimilarPeopleExport);
  yield takeLatest(actions.getSearchedPersonProfileInsights.type, getSearchedPersonProfileInsights);
  yield takeLatest(actions.getPersonProfileVideos.type, getPersonProfileVideos);
  yield takeLatest(actions.getPersonProfileDownload.type, getPersonProfileDownload);
  yield takeLatest(actions.getPersonWorkbenchStats.type, getPersonWorkbenchStats);
  yield takeLatest(actions.getPersonCareerCompany.type, getPersonCareerCompany);
  yield takeLatest(actions.generateEmail.type, generateEmail);
  yield takeLatest(actions.sendFeedback.type, sendFeedback);
  yield takeLatest(actions.editPhoneEmail.type, editPhoneEmail);
  yield takeLatest(actions.getPersonTheme.type, getPersonTheme);
  yield takeLatest(actions.createAIDigest.type, createAIDigest);
}
