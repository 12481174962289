/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Executive } from 'Types/PersonTypes';
import { GroupsState } from 'Types/GlobalTypes';
import {
  DeleteGroupResult, MostActiveGroups, PersonAddedInGroup,
  StaffBuyerGroupData, CreateGroupSuccess, SelectedElements,
  ShareGroupRequest, ExecutivesResponse, CreatedGroup,
} from 'Types/GroupsTypes';
import {
  AddPersonInGroup, CreateGroup, DeleteGroup, GetAllGroups,
} from 'Types/RequestBodies';

export const initialState: GroupsState = {
  title: '',
  mostActiveGroups: [],
  mostActiveGroupsLoading: false,
  allGroupsLoading: false,
  allGroupsLoaded: false,
  buyerGroupsLoading: false,
  groupCount: 0,
  allGroups: [],
  searchedGroups: [],
  groupsCount: 0,
  newlycreatedGroupId: 0,
  groupsEnded: false,
  searchedGroupsEnded: false,
  deletingLoader: false,
  creatingGroupLoader: false,
  addingPerson: false,
  notification: 0,
  newGroupId: 0,
  message: '',
  groupCreateSuccessfully: false,
  companyId: 0,
  deleted: false,
  // for closing the group drawer
  dropComplete: true,
  executivesLoading: false,
  executivesLoaded: false,
  organizationName: '',
  organizationExecutives: [],
  categoryOrganizationExecutives: [],
  organizationExecutiveForGilroy: false,
  totalExecutives: 0,
  executivesEnded: false,
  executivesPage: 1,
  executivesSearched: [],
  categoryExecutivesSearched: [],
  searchedEnded: false,
  searchedPage: 1,
  searchedCount: 0,
  selectedGroups: [],
  selectedExeutives: [],
  shareLoading: false,
  groupShared: false,
  showMergePopup: false,
  isDuplication: false,
  searchedGroupsCount: 0,
  groupDrawerOpen: false,
};

const GroupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    getMostActiveGroups(state) {
      state.mostActiveGroupsLoading = true;
    },
    setMostActiveGroups(state, action: PayloadAction<MostActiveGroups>) {
      const { data, title, groupCount } = action.payload;
      state.mostActiveGroupsLoading = false;

      state.mostActiveGroups = data;
      state.title = title;
      if (groupCount) {
        state.groupCount = groupCount;
      }
    },
    getAllGroups(state, action: PayloadAction<GetAllGroups>) {
      state.allGroupsLoading = true;
      if (state.allGroups.length === 0) {
        state.buyerGroupsLoading = true;
      }
      state.groupsEnded = false;
      state.searchedGroupsEnded = false;
      if (action.payload.offset === 0) {
        if (action.payload.searchTerm && action.payload.searchTerm !== '') {
          state.searchedGroups = [];
        } else {
          state.groupsCount = 0;
          state.allGroups = [];
        }
      }
    },
    setAllGroups(state, action: PayloadAction<MostActiveGroups>) {
      const { payload } = action;
      state.allGroupsLoading = false;
      state.buyerGroupsLoading = false;
      if (payload.notificationCount) { state.notification = payload.notificationCount; }
      if (state.groupsCount === 0 && payload.groupCount) {
        state.groupsCount = payload.groupCount;
      }
      if (payload.data && payload.data.length > 0) {
        state.allGroups = state.allGroups.concat(payload?.data);
      } else state.groupsEnded = true;
      state.allGroupsLoaded = true;
    },
    setSearchedGroups(state, action: PayloadAction<MostActiveGroups>) {
      const { payload } = action;
      state.allGroupsLoading = false;
      state.searchedGroupsCount = payload.groupCount || 0;
      if (payload.data && payload.data.length > 0) {
        state.searchedGroups = state.searchedGroups.concat(action.payload?.data);
      } else state.searchedGroupsEnded = true;
    },
    deleteGroup(state, action: PayloadAction<DeleteGroup>) {
      state.deletingLoader = true;
    },
    groupDeletionFailure(state) {
      state.deletingLoader = false;
    },
    groupDeleted(state, action: PayloadAction<DeleteGroupResult>) {
      const { payload } = action;
      state.groupsCount = payload.groupCount;
      state.groupCount = payload.groupCount;
      state.deletingLoader = false;
      state.searchedGroups = state.searchedGroups.filter(
        (g) => g.groupId !== payload?.groupId,
      );
      state.allGroups = state.allGroups.filter(
        (g) => g.groupId !== payload?.groupId,
      );
      state.mostActiveGroups = state.mostActiveGroups.filter(
        (g) => g.groupId !== payload?.groupId,
      );
      state.deleted = true;
    },
    creatingGroup(state, action: PayloadAction<CreateGroup>) {
      if (action.payload.action === 'copy'
      || action.payload.action === 'merge') {
        state.isDuplication = true;
      }
      state.creatingGroupLoader = true;
      state.newlycreatedGroupId = 0;
      state.newGroupId = 0;
      state.message = '';
      state.groupCreateSuccessfully = false;
      state.companyId = 0;
    },
    createdGroup(state, action: PayloadAction<CreatedGroup>) {
      const { response } = action.payload;
      if (response.length > 0 && action.payload?.action !== 'merge') {
        state.allGroups.unshift(response[0]);
        state.allGroups.pop();
        if (action.payload && response[0].persons.length < 1) {
          state.newlycreatedGroupId = response[0].groupId;
        }
        state.groupCreateSuccessfully = true;
        state.groupsCount += 1;
      }
      state.creatingGroupLoader = false;
    },
    addPersonInGroup(state, action: PayloadAction<AddPersonInGroup>) {
      state.addingPerson = true;
      state.newGroupId = 0;
      state.message = '';
    },
    personAddedInGroup(state, action: PayloadAction<PersonAddedInGroup>) {
      const { payload } = action;
      state.addingPerson = false;
      state.allGroups = state.allGroups.map((group) => {
        if (group.groupId === payload.groupId) {
          return payload.groupData;
        }
        return group;
      });
      state.searchedGroups = state.searchedGroups.map((group) => {
        if (group.groupId === payload.groupId) {
          return payload.groupData;
        }
        return group;
      });
    },
    setDropComplete(state, action: PayloadAction<boolean>) {
      const dropComplete = action.payload;
      state.dropComplete = dropComplete;
    },
    getOrganizationExecutives(state, action: PayloadAction<GetAllGroups>) {
      state.executivesLoading = true;
      state.executivesEnded = false;
      state.executivesLoaded = false;
      if (action.payload.page === 1) {
        state.organizationExecutiveForGilroy = !!action.payload.customCategoryUsers;
        if (action.payload.query && action.payload.query !== '') {
          if (action.payload.customCategoryUsers) {
            state.categoryExecutivesSearched = [];
          } else {
            state.executivesSearched = [];
          }
        } else {
          if (action.payload.customCategoryUsers) {
            state.categoryOrganizationExecutives = [];
          } else {
            state.organizationExecutives = [];
          }
          state.executivesPage = 1;
          state.totalExecutives = 0;
        }
      }
    },
    setOrganizationExecutives(state, action: PayloadAction<ExecutivesResponse>) {
      const { payload } = action;
      state.organizationName = payload.title;
      state.executivesLoading = false;
      state.executivesLoaded = true;
      if (payload.executives && payload.executives.length) {
        if (state.organizationExecutiveForGilroy) {
          state.categoryOrganizationExecutives = state.categoryOrganizationExecutives.concat(
            payload.executives,
          );
        } else {
          state.organizationExecutives = state.organizationExecutives.concat(payload.executives);
        }
      } else state.executivesEnded = true;
      if (state.executivesPage === 1) { state.totalExecutives = payload.activeUsersCount; }
      state.executivesPage += 1;
    },
    setSearchedExecutives(state, action: PayloadAction<ExecutivesResponse>) {
      const { payload } = action;
      state.executivesLoading = false;
      state.executivesLoaded = true;
      if (payload.executives && payload.executives.length) {
        if (state.organizationExecutiveForGilroy) {
          state.categoryExecutivesSearched = state.categoryExecutivesSearched.concat(
            payload.executives,
          );
        } else {
          state.executivesSearched = state.executivesSearched.concat(payload.executives);
        }
      } else state.searchedEnded = true;
      if (state.searchedPage === 1) { state.searchedCount = payload.searchedCount; }
      state.searchedPage += 1;
    },
    resetSearchedExecutives(state) {
      state.executivesSearched = [];
      state.searchedEnded = false;
      state.searchedPage = 1;
      state.searchedCount = 0;
    },
    resetSearchedExecutivesForNotes(state) {
      state.executivesSearched = [];
      state.organizationExecutives = [];
      state.searchedEnded = false;
      state.searchedPage = 1;
      state.searchedCount = 0;
      state.executivesLoaded = false;
    },
    orgExecutivesFailed(state) {
      state.executivesLoading = false;
      state.organizationExecutives = [];
      state.executivesLoaded = false;
    },
    setSelectedGroups(state, action: PayloadAction<SelectedElements[]>) {
      const { payload } = action;
      state.selectedGroups = payload;
    },
    setSelectedExecutives(state, action: PayloadAction<Executive[]>) {
      const { payload } = action;
      state.selectedExeutives = payload;
    },
    shareGroup(state, action: PayloadAction<ShareGroupRequest>) {
      state.shareLoading = true;
      state.groupShared = false;
    },
    groupShared(state) {
      state.shareLoading = false;
      state.groupShared = true;
    },
    groupSharedFailed(state) {
      state.shareLoading = false;
      state.groupShared = false;
    },
    setShowMergePopup(state, action: PayloadAction<boolean>) {
      state.showMergePopup = action.payload;
    },
    setIsDuplication(state, action: PayloadAction<boolean>) {
      state.isDuplication = action.payload;
    },
    setGroupDrawerOpen(state, action: PayloadAction<boolean>) {
      state.groupDrawerOpen = action.payload;
    },
    exportGroupMembers(state, action: PayloadAction<number>) {

    },
    reset: (state) => ({
      ...initialState,
      notification: state.notification,
      organizationExecutives: state.organizationExecutives,
      totalExecutives: state.totalExecutives,
      organizationName: state.organizationName,
      executivesEnded: state.executivesEnded,
      executivesPage: state.executivesPage,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = GroupsSlice;
