/* eslint-disable no-nested-ternary */
import {
  SecondaryColor,
  maxTablet,
  maxWidth,
  primaryColor, primaryFont, retina, scrollbar, wMedium,
} from 'Utils/utilsStyle';
import React, {
  RefObject, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChatLoading,
  selectConversationSections, selectConversationsLoading,
  selectDeleteConversationLoaded, selectDeleteConversationLoading,
  selectFavPromptAdded,
  selectFavPromptRemoved,
  selectRemovedPromptId,
  selectScrollOnConvoUpdate, selectedConversationId,
} from 'Selector/ChatBotV2Selector';
import { actions } from 'Slice/ChatbotV2Slice';
import { actions as globalSearchContentActions } from 'Slice/GlobalSearchContentSlice';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import { actions as UiAction } from 'Slice/UISlice';
import UploadDocumentIcon from 'Components/common/assets/svgIcons/UploadDocumentIcon';
import { useNavigate } from 'react-router-dom';
import { actions as documentHubActions } from 'Slice/DocumentHubSlice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import {
  IsTablet,
  getCurrentDate,
  getCurrentTime, getLangCookie, getLocalStorageValue,
  getTimezoneInGMT, setLocalStorageValue, valueToObject,
} from 'Utils/UtilityFunctions';

import {
  Section, Conversation, TagItem, PromptCategoryV2,
} from 'Types/ChatBotV2Types';
import { selectDisclessPersonality, selectGilroyDiscalimer, selectShowUploadDocument } from 'Selector/GlobalSearchContentSelector';
import { Divider } from 'antd';
import { DOCUMENTS_UPLOAD_ROUTE } from 'Utils/Constants';
import ExpandIconGilroy from 'Components/common/assets/svgIcons/ExpandIconGilroy';
import { isExpandFlag } from 'Selector/UISelector';
import PlusIconSvg from 'Components/common/assets/svgIcons/PlusIconSvg';
import IconTooltipGlobal from 'Components/common/components/IconTooltipGlobal';
import ChatBotHistoryTitleTile from './components/ChatBotHistoryTitleTile';
import EmptyStatePanel from './components/EmptyStatePanel';

const StyledWrapper = styled.div`
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.08);
  height: calc(100% - 100px);
  @media all and (max-width: ${maxWidth}), ${retina} {
    height: calc(100% - 98px);
  }
  @media all and (max-width: 1300px) and (min-width: 1275px) {
    border-radius: 8px 8px 8px 0px;
  }
  @media all and (max-width: ${maxTablet}) {
    height: unset;
    border-radius: 6px;
  }
  .new-chat-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 16px 20px;
    @media all and (max-width: ${maxTablet}) {
      display: flex;
      align-items: center;
      justify-content: unset;
      padding: 8px 18px;
    }
    .expand-icon-gilroy-svg {
      &:hover {
        path ,circle {
          fill: ${primaryColor};
        }
      }
      cursor: pointer;
    }
    .header-panel {
      &:hover {
        color: ${primaryColor};
        .plus-icon-svg {
          path {
            fill: ${primaryColor};
            stroke: ${primaryColor};
          }
        }
      }
      font-family: ${primaryFont};
      font-weight: ${wMedium};
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.4px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      padding: 0px;
      color: ${SecondaryColor};
      cursor: pointer;
      @media all and (max-width: ${maxWidth}), ${retina} {
        font-size: 14px;
        line-height: 18px;
      }
      @media all and (min-height: ${maxTablet}) {
        font-size: 16px;
        line-height: 20px;
      }
      @media all and (max-width: ${maxTablet}) {
        padding: 0px !important;
        font-size: 14px;
      }
      .plus-icon-svg {
        width: 12px;
        height: 12px;
        margin-right: 10px;
        path {
          fill:  ${SecondaryColor};
        }
      }
    }
    .icon-tooltip-global-wrapper {
      height: 16px;
      @media all and (max-width: ${maxTablet}) {
        height: 20px;
      }
      .icon-tooltip-global {
        height: 16px;
        @media all and (max-width: ${maxTablet}) {
          height: 20px;
        }
      }
    }
    .upload-panel-div {
      display: flex;
      align-items: center;
      .ant-divider {
        border-left: 1px solid #D8D8D8;
      }
      .upload-document-btn-tab {
        display: flex;
        align-items: center;
        font-family: ${primaryFont};
        font-weight: ${wMedium};
        font-size: 14px;
        line-height: 20px;
        color: ${SecondaryColor};
        cursor: pointer;
        svg {
          width: 13px;
          min-width: 13px;
          height: 13px;
          margin-right: 10px;
          path {
            fill: ${SecondaryColor};
            stroke: ${SecondaryColor};
          }
        }
      }
    }
  }
  .scroll-wrapper {
    max-height: 505px;
    margin-right: 2px;
    ${scrollbar};
    ::-webkit-scrollbar { width: 4px; } 
    ::-webkit-scrollbar-thumb { background-color: #DCDCDD; }
    ::-webkit-scrollbar-track { background-color: transparent; } 
    border-radius: 0px !important;
    @media all and (max-width: ${maxWidth}), ${retina} {
      max-height: 334px;
    }
    @media all and (min-height: ${maxTablet}) {
      max-height: 505px;
    }
    .infinite-scroll-component__outerdiv {
      height: calc(100% - 42px);
      .infinite-scroll-component {
        height: 100% !important;
        min-height: 505px;
        overflow: hidden !important;
        @media all and (max-width: ${maxWidth}), ${retina} {
          min-height: 334px;
        }
        @media all and (min-height: ${maxTablet}) {
          min-height: 505px;
        }
      }
    }
  }
`;
const DateStyle = styled.div`
  font-family: ${primaryFont};
  font-size: 14px;
  font-weight: ${wMedium};
  letter-spacing: -0.43px;
  color: #667982;
  line-height: normal;
  margin-bottom: 6px;
  margin-top: 9px;
  margin-left: 20px;
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 6px;
`;

const ToasterWrapper = styled.div`
  .link {
    cursor: pointer;
    text-decoration: underline;
    margin-left: 5px;
  }
  .undo-btn {
    border: 1px solid #fff;
    border-radius: 6px;
    padding: 2px 6px;
    margin-left: 12px;
    cursor: pointer;
    font-size: 14px;
  }
`;

type Props = {
  contentEditableRef: RefObject<HTMLDivElement> | null
  tagItem: TagItem
  showUploadBtnWithChat: boolean
};

const ChatBotHistoryPanel = function ChatBotHistoryPanel(props: Props) {
  const { contentEditableRef, tagItem, showUploadBtnWithChat } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const conversationSections = useSelector(selectConversationSections);
  const conversationsLoading = useSelector(selectConversationsLoading);
  const deleteConversationLoading = useSelector(selectDeleteConversationLoading);
  const deleteConversationLoaded = useSelector(selectDeleteConversationLoaded);
  const scrollOnConvoUpdate = useSelector(selectScrollOnConvoUpdate);
  const selectConvoId = useSelector(selectedConversationId);
  const chatLoading = useSelector(selectChatLoading);
  const noConversations = conversationSections.every((section) => section.sectionData.length === 0);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const isTab = IsTablet();
  const timezone = getTimezoneInGMT();
  const startTime = getCurrentTime();
  const showUploadDocument = useSelector(selectShowUploadDocument);
  const { showDisclaimer } = useSelector(selectGilroyDiscalimer);
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const host = window.location.href;
  const isExpand = useSelector(isExpandFlag);
  const favPromptAdded = useSelector(selectFavPromptAdded);
  const favPromptRemoved = useSelector(selectFavPromptRemoved);
  const removedPromptId = useSelector(selectRemovedPromptId);

  const isGilroyV3 = disclessPersonality ? (!window.location.pathname.includes('feed') && !window.location.pathname.includes('person'))
    : !window.location.pathname.includes('feed'); // add ere neutanix check

  const pathName = window.location.pathname;
  const pageType = () => {
    switch (true) {
      case pathName.includes('company'):
        return 'company';
      case pathName.includes('group'):
        return 'BRM';
      case pathName.includes('industry'):
        return 'industry';
      case pathName.includes('person'):
        return 'people';
      case pathName.includes('document'):
        return 'doc hub';
      default:
        return 'feed';
    }
  };

  useEffect(() => {
    if (!conversationSections.length) {
      dispatch(actions.getConversations({
        timezone,
        offset: 0,
      }));
    }
    dispatch(actions.getGilroyData());
  }, []);

  useEffect(() => {
    if (scrollOnConvoUpdate && selectConvoId && scrollRef?.current) {
      scrollRef.current.scrollTop = 0;
      dispatch(actions.setScrollOnConvoUpdate(false));
    }
  }, [scrollOnConvoUpdate]);

  const handleNewChat = (increaseHeight: boolean) => {
    if (selectConvoId !== 0) {
      dispatch(actions.setIsNewChat(true));
      dispatch(actions.setShowLibrary(true));
      dispatch(actions.setShowExamplePrompts(false));
      dispatch(actions.resetChatHistory());
      dispatch(actions.setSelectedConversationId(0));
      dispatch(actions.setTaggedItem(null));
      dispatch(actions.resetTagItemsList());
      dispatch(actions.setPlaceholderToReplace(''));
      dispatch(actions.setIsRegenerate(false));
      dispatch(UiAction.isEditChatOpenInput(false));
      dispatch(UiAction.isGilroyTextHeightIncrease(increaseHeight));
      if (contentEditableRef?.current && increaseHeight) {
        contentEditableRef.current.focus();
      }
      if (increaseHeight) {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_newChat_clicked', startTime, {
            host,
          }),
        ));
      }
      if (isTab) {
        dispatch(actions.setHistoryPanelOpened(false));
      }
      dispatch(UiAction.isDrawerPanelOpen(false));
    }
  };

  useEffect(() => {
    if (!deleteConversationLoading && deleteConversationLoaded) {
      const latestConversationId = conversationSections[0]?.sectionData[0]?.channelId;
      if (latestConversationId) {
        dispatch(actions.setShowLibrary(true));
        dispatch(actions.setShowExamplePrompts(false));
        dispatch(actions.resetChatHistory());
        dispatch(actions.setTaggedItem(null));
        dispatch(actions.resetTagItemsList());
        dispatch(actions.setPlaceholderToReplace(''));
        dispatch(actions.setSelectedConversationId(latestConversationId));
      } else {
        handleNewChat(false);
      }
      dispatch(actions.setDeleteConversationLoaded(false));
    }
  }, [deleteConversationLoading]);

  const fetchMore = () => {
    dispatch(actions.getConversations({
      timezone,
      offset: conversationSections.reduce(
        (sum, section) => sum + section.sectionData.length,
        0,
      ),
    }));
  };

  const handleUploadDocClick = () => {
    dispatch(flurryActions.callFlurryEvent(valueToObject('documentHub_uploadDocumentBanner_clicked_Gilroy', 0, {
      host,
    })));
    dispatch(actions.setSaveUrl(window.location.href));
    if (pageType() === 'BRM') {
      dispatch(actions.setSaveProfileName(`${tagItem?.name || ''} Buyer Group`));
    } else if (pageType() === 'company') {
      dispatch(actions.setSaveProfileName(`${tagItem?.name || ''}'s`));
    } else if (pageType() === 'people') {
      dispatch(actions.setSaveProfileName(`${tagItem?.name || ''}'s Profile`));
    } else if (pageType() === 'feed') {
      dispatch(actions.setSaveProfileName('Feed'));
    }
    navigate(DOCUMENTS_UPLOAD_ROUTE);
    dispatch(UiAction.isShowGilroyGlobalToggle(false));
    dispatch(documentHubActions.setDrawerOpen(true));
  };
  const onClickExpand = () => {
    dispatch(UiAction.isExpandFlag(!isExpand));
  };

  const isAfterMidnight = (): boolean => {
    const storedDate = getLocalStorageValue('lastResetDate');
    const currentDate = getCurrentDate();

    return !storedDate || new Date(currentDate) > new Date(storedDate);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (showDisclaimer) {
      const checkAndRun = () => {
        if (isAfterMidnight()) {
          setLocalStorageValue('lastResetDate', getCurrentDate());
          dispatch(actions.reset());
          dispatch(globalSearchContentActions.resetRecentSearches());
        }
      };
      checkAndRun();
      timer = setInterval(checkAndRun, 10000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [showDisclaimer]);

  const redirectToFav = () => {
    dispatch(actions.setShowLibrary(true));
    dispatch(actions.setShowExamplePrompts(true));
    dispatch(actions.setShowFavorite(true));
    dispatch(UiAction.isCompanyPrompt(false));
    dispatch(actions.setSelectedPromptCategory({} as PromptCategoryV2));
    dispatch(actions.getFavoritePrompts({
      offset: 0,
      limit: 30,
      defaultLanguage: getLangCookie(),
    }));
    dispatch(flurryActions.setNotification({
      message: '', proTip: false, rightPlacement: false, groupId: 0, classnameFlag: false, className: '',
    }));
  };

  const handleUndo = () => {
    if (removedPromptId) {
      dispatch(actions.markFavoritePrompt({
        isUndo: true,
        isNew: false,
        isFavourite: true,
        promptId: removedPromptId,
      }));
      dispatch(flurryActions.setNotification({
        message: '', proTip: false, rightPlacement: false, groupId: 0, classnameFlag: false, className: '',
      }));
    }
  };

  const favoriteAdded = (
    <ToasterWrapper>
      Prompt has been sucessfully added to
      <span
        role="button"
        className="link"
        onClick={redirectToFav}
        onKeyPress={redirectToFav}
        tabIndex={0}
        aria-label="My Favorite"
      >
        My Favorites
      </span>
    </ToasterWrapper>
  );

  const favoriteRemoved = (
    <ToasterWrapper>
      Prompt has been removed from My Favorites.
      <span
        role="button"
        className="undo-btn"
        onClick={handleUndo}
        onKeyPress={handleUndo}
        tabIndex={0}
        aria-label="undo"
      >
        Undo
      </span>
    </ToasterWrapper>
  );

  useEffect(() => {
    if (favPromptAdded) {
      dispatch(flurryActions.setNotification({
        message: favoriteAdded,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
        getContainerEle: 'chatContainer',
        className: 'fav-prompt',
      }));
      dispatch(actions.setFavPromptAdded(false));
    }
  }, [favPromptAdded]);

  useEffect(() => {
    if (favPromptRemoved) {
      dispatch(flurryActions.setNotification({
        message: favoriteRemoved,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
        getContainerEle: 'chatContainer',
        className: 'fav-prompt undo-prompt',
      }));
      dispatch(actions.setFavPromptRemoved(false));
    }
  }, [favPromptRemoved]);

  return (
    <StyledWrapper className={classNames('chat-bot-history-panel')}>
      <div className="new-chat-div">
        {isExpand && !isGilroyV3 ? (
          chatLoading || selectConvoId === 0 ? (
            <span
              role="button"
              className={classNames('header-panel', { disabled: chatLoading || selectConvoId === 0 })}
              onClick={() => handleNewChat(true)}
              onKeyPress={() => handleNewChat(true)}
              tabIndex={0}
              aria-label="Start new chat"
            >
              <PlusIconSvg className="plus-icon-svg" onClick={() => handleNewChat(true)} />
            </span>
          ) : (
            <IconTooltipGlobal
              disableBtn={chatLoading || selectConvoId === 0}
              tooltipPlacement="bottom"
              tooltipTitle="New Chat"
              icon={<PlusIconSvg className="plus-icon-svg" onClick={() => handleNewChat(true)} />}
            />
          )
        ) : (
          <span
            className={classNames('header-panel', { disabled: chatLoading || selectConvoId === 0 })}
            onClick={() => handleNewChat(true)}
            onKeyPress={() => handleNewChat(true)}
            tabIndex={0}
            role="button"
          >
            <PlusIconSvg className="plus-icon-svg" />
            <span className="header-panel-text">New Chat</span>
          </span>
        )}
        {isGilroyV3 || isTab ? null : (
          <IconTooltipGlobal
            tooltipPlacement="bottom"
            tooltipTitle={!isExpand ? 'Close sidebar' : 'Open sidebar'}
            icon={<ExpandIconGilroy onClick={onClickExpand} />}
          />
        )}
        {showUploadBtnWithChat && showUploadDocument ? (
          <div className="upload-panel-div">
            <Divider type="vertical" />
            <span
              tabIndex={0}
              role="button"
              className="upload-document-btn-tab"
              onClick={handleUploadDocClick}
              onKeyPress={handleUploadDocClick}
            >
              <UploadDocumentIcon />
              Upload Document
            </span>
          </div>
        ) : null}
      </div>
      {noConversations && !conversationsLoading ? (
        <EmptyStatePanel />
      ) : (
        <div
          className="scroll-wrapper"
          id="scrollWrapper"
          ref={scrollRef}
        >
          <InfiniteScroll
            scrollableTarget="scrollWrapper"
            dataLength={conversationSections.reduce(
              (sum, section) => sum + section.sectionData.length,
              0,
            )}
            next={fetchMore}
            hasMore
            loader={false}
            className="infinite-scroll"
          >
            {conversationSections?.map((section: Section) => (
              <>
                <DateStyle className="panel-date">{section.sectionName}</DateStyle>
                <>
                  {section.sectionData.map((conversation: Conversation) => (
                    <ChatBotHistoryTitleTile
                      conversation={conversation}
                    />
                  ))}
                </>
              </>
            ))}
            {conversationsLoading ? (
              <div className="d-flex flex-column px-1">
                {[1, 2, 3, 4].map(() => (
                  <SkeletonCustom
                    height="33px"
                    margin="0px 0px 5px 0px"
                    marginSm="0px 0px 5px 0px"
                  />
                ))}
              </div>
            ) : null}
          </InfiniteScroll>
        </div>
      )}
    </StyledWrapper>
  );
};

export default ChatBotHistoryPanel;
