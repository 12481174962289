import React, {
  useState, useEffect, RefObject, useRef,
} from 'react';
import styled from 'styled-components';
import LibraryIcon from 'Components/common/assets/svgIcons/LibraryIcon';
import HistoryIcon from 'Components/common/assets/svgIcons/HistoryIcon';
import {
  isEditChatOpen, isGilroyTextHeight, isShowAskGif, isShowGilroyGlobal,
} from 'Selector/UISelector';
import { useDispatch, useSelector } from 'react-redux';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import classNames from 'classnames';
import ContentPopOver from 'Components/common/components/ContentPopOver';
import {
  SecondaryColor, askGilroyAnimationV7, boxShadow,
  maxMobile, maxTablet, maxWidth,
  maxWidth1300,
  primaryFont,
  retina, smMobile400, wMedium,
} from 'Utils/utilsStyle';
import {
  selectChatLoading,
  selectClickedCertificationPrompt,
  selectConversationSections, selectDeleteChat,
  selectDrawerPreviewOpen,
  selectHistoryPanelOpened,
  selectLimitPopup,
  selectSaveProfileNameV2,
  selectSaveUrlV2,
  selectShowLibrary, selectedConversationId,
} from 'Selector/ChatBotV2Selector';
import { actions } from 'Slice/ChatbotV2Slice';
import { useNavigate } from 'react-router-dom';
import { actions as documentHubActions } from 'Slice/DocumentHubSlice';
import { actions as UiAction } from 'Slice/UISlice';
import { TagItem } from 'Types/ChatBotV2Types';
import {
  IsMobile,
  IsTablet,
  getCurrentTime, isEnterpriseUser, isSafariBrowser, valueToObject,
} from 'Utils/UtilityFunctions';
import OutsideClickHandler from 'react-outside-click-handler';
import { actions as flurryActions } from 'Slice/HelperSlice';
import CrossCircle from 'Components/common/assets/svgIcons/CrossCircle';
import ChatBotHistoryPanel from 'Components/chatBotV2/mainComponents/chatbotHistory/ChatBotHistoryPanel';
import UploadDocumentIcon from 'Components/common/assets/svgIcons/UploadDocumentIcon';
import { selectGilroyDiscalimer, selectShowDocumentHub, selectShowUploadDocument } from 'Selector/GlobalSearchContentSelector';
import ProfilePopups from 'Components/common/modal/ProfilePopups';
import {
  selectActivetype, selectAiCertificationData,
  selectIsCompletionPopupOpen,
  selectIsHighlighted, selectIsScroll, selectShowAccordion,
} from 'Selector/CertificationSelector';
import { ChatbotCertificationTypes, DOCUMENTS_UPLOAD_ROUTE, SMART_REDIRECTION_TEXT } from 'Utils/Constants';
import DisclaimerCmpFooter from 'Components/chatBotV2/mainComponents/DisclaimerCmpFooter';
import CustomTabGilroy from '../chatBotV2/mainComponents/CustomTabGilroy';
import ChatBotLibrary from '../chatBotV2/mainComponents/chatbotLibrary/ChatBotLibrary';
import ChatBotHistory from '../chatBotV2/mainComponents/chatbotHistory/ChatBotHistory';
import CustomTextAreaGilroy from '../chatBotV2/mainComponents/CustomTextAreaGilroy';
import GilroyHeaderV3 from './components/GilroyHeaderV3';
import ToggleIconClose from './assets/ToggleIconClose';
import ToggleIconOpen from './assets/ToggleIconOpen';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ClosePanelIcon from '../chatBotV2/mainComponents/chatbotHistory/components/ClosePanelIcon';

const HeaderArea = styled.div``;
const BodyArea = styled.div``;
const FooterArea = styled.div``;
const CloseIcon = styled.span``;

const StyleWrapper = styled.div`
&.samsungDisclaimer {
   .chatbot-v3-container {
      .container-inner-area {
        .chatbot-v3-footer-area {
          .custom-text-area-gilroy {
            &.changeHeight {
              padding-bottom: 55px;
              @media all and (max-width: ${maxWidth}), ${retina} {
                padding-bottom: 55px;
              }
              @media all and (min-height: ${maxTablet}) {
                padding-bottom: 50px;
              }
              @media all and (max-width: ${maxTablet}) {
                padding: 6px 12px 39px !important;
              }
              .textarea-input-gilroy {
                height: 198px;
                max-height: 198px;
                @media all and (max-width: ${maxWidth}), ${retina} {
                  height: 101px;
                  max-height: 101px;
                }
                @media all and (min-height: ${maxTablet}) {
                  height: 338px;
                  max-height: 338px;
                }
                @media all and (max-width: ${maxTablet}) {
                  height: 42px !important;
                  max-height: 42px !important;
                }
              }
            }
          }
        }
      }
    }
}
  &.safariHeightWrapper {
    .chatbot-v3-container {
      .container-inner-area {
        .chatbot-v3-body-area {
          &.bodyContentHeight {
            height: calc(100vh - 416px);
            @media all and (max-width: ${maxWidth}), ${retina} {
              height: calc(100vh - 305px);
            }
            @media all and (min-height: ${maxTablet}) {
              height: calc(100vh - 537px);
            }
            @media all and (max-width: ${maxTablet}) {
              height: calc(100vh - 323px) !important;
            }
            @media all and (max-width: ${maxMobile}) {
              height: calc(100vh - 319px) !important;
            }
          }
          height: calc(100vh - 200px);
          @media all and (max-width: ${maxWidth}), ${retina} {
            height: calc(100vh - 186px);
          }
          @media all and (max-width: ${maxTablet}) {
            height: calc(100vh - 270px) !important;
          }
          @media all and (max-width: ${maxMobile}) {
            height: calc(100vh - 266px) !important;
          }
        }
      }
    }
  }
  &.hideDocumentBtn {
    div {
      .chat-container {
        border-radius: 12px;
        .container-inner-area {
          border-radius: 12px;
        }
      }
    }
  }
  .cross-icon-x3 {
    position: absolute;
    right: 24px;
    top: 20px;
    cursor: pointer;
    z-index: 1;
    svg {
      width: 28px;
      height: 28px;
    }
  }
  .chat-container {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: ${boxShadow};
    z-index: 11;
    border-radius: 12px;
    border-bottom-left-radius: 0px;
    width: 821px;

    @media all and (max-width: ${maxWidth}), ${retina}{ 
      width: 800px;
    }

    @media all and (max-width: ${maxWidth1300}) { 
      width: 54%;
    }

    @media all and (max-width: ${maxTablet}) { 
      width: 514px;
    }
    @media all and (max-width: ${maxMobile}) { 
      width: 380px;
      right: 10px;
      border-bottom-left-radius: 12px;
    }

    @media all and (max-width: ${smMobile400}) { 
      width: 340px;
    }

    .body-content, .upload-document-btn, .aside-toggler, .header-gill-roy, .cross-icon-x3, .chatbot-v3-footer-area {
      visibility: hidden;
    }

    &.out {
      animation: slide-out-100 0.3s ease-out forwards;

      @media all and (max-width: ${maxWidth}), ${retina}{ 
        animation: slide-out-125 0.3s ease-out forwards;
      }
      
      @media all and (max-width: ${maxTablet}) { 
        animation: slide-out-res 0.3s ease-out forwards;
      }

      @media all and (max-width: ${maxMobile}) { 
        animation: slide-out-res 0.3s ease-out forwards;
      }

      @media all and (max-width: ${smMobile400}) { 
        animation: slide-out-res-400 0.3s ease-out forwards;
      }
    }


    &.show {
      animation: slide-in-100 0.3s ease-out forwards;

      @media all and (max-width: ${maxWidth}), ${retina}{ 
        animation: slide-in-125 0.3s ease-out forwards;
      }

      @media all and (max-width: ${maxTablet}) { 
        animation: slide-in-res 0.3s ease-out forwards;
      }

      @media all and (max-width: ${maxMobile}) { 
        animation: slide-in-res 0.3s ease-out forwards;
      }

      @media all and (max-width: ${smMobile400}) { 
        animation: slide-in-res-400 0.3s ease-out forwards;
      }


      .body-content, .aside-toggler, .header-gill-roy, .cross-icon-x3, .upload-document-btn, .chatbot-v3-footer-area {
        visibility: visible;
      }

      .upload-document-btn {
        display: flex;
      }
      
      ~ .content-popover-c {
          .tip-area {
          display: none;
          }
      }
    }
  }

  @keyframes aside-show-in {
    from {
      left: 0px;
    }
    to {
      left: -271px;
      visibility: visible;
    }
  }

  @keyframes aside-show-out {
    from {
      left: -271px;
    }
    to {
      left: 0px;
    }
  }

  @keyframes slide-in-100 {
    from {
      height: 0px;
    }
    to {
      height: calc(100vh - 40px);
      
    }
  }

    @keyframes slide-out-100 {
    from {
      height: calc(100vh - 40px);
    }
    to {
      height: 0px;
    }
  }

  @keyframes slide-in-125 {
    from {
      height: 0px;
    }
    to {
      height: calc(100vh - 30px);
    }
  }

    @keyframes slide-out-125 {
    from {
      height: calc(100vh - 30px);
    }
    to {
      height: 0px;
    }
  }

  @keyframes slide-in-res {
    from {
      height: 0px;
    }
    to {
      height: calc(100vh - 80px);
    }
  }

  @keyframes slide-out-res {
    from {
      height: calc(100vh - 80px);
    }
    to {
      height: 0px;
    }
  }

  @keyframes slide-in-res-400 {
    from {
      height: 0px;
    }
    to {
      height: calc(100vh - 80px);
    }
  }

  @keyframes slide-out-res-400 {
    from {
      height: calc(100vh - 80px);
    }
    to {
      height: 0px;
    }
  }
`;

const ContentPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .container-inner-area {
    background: #fff;
    box-shadow: 0px 4.4px 17.6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 12px 12px 12px 0px;
  }
  .ant-notification {
    position: absolute;
    top: 170px !important;
    left: 50%;
    transform: translateX(-50%);
    @media all and (max-width: ${maxWidth}), ${retina}{ 
      top: 130px !important;
    }
  }
  .body-content {
    position: relative; 
    min-height: calc(100% - 396px);
    height: calc(100% - 259px);
    user-select: text;
    padding: 0px;
    border-radius: unset !important;
    @media all and (max-width: ${maxWidth}), ${retina} {
      min-height: calc(100% - 365px);
    }
    @media all and (max-width: 1024px) {
      height: calc(100vh - 300px);
    }
    .ant-modal-mask, .ant-modal-wrap {
      position: absolute;
      left: 0px;
      top: 0px; 
    }
  }
  .upload-document-btn {
    &.showBtnWithDrawer {
      left: -374px;
      animation: slide-out 0.3s ease-out backwards;
    }
    display: flex;
    align-items: center;
    position: absolute;
    padding: 6px 12px;
    background-color: #fff;
    border-top-right-radius: 13px;
    border-top-left-radius: 13px;
    transform: rotate(270deg);
    box-shadow: ${boxShadow};
    font-family: ${primaryFont};
    font-weight: ${wMedium};
    font-size: 14px;
    line-height: 20px;
    color: ${SecondaryColor};
    cursor: pointer;
    @media all and (max-width: ${maxMobile}) {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 13px;
    }
    &:hover {
      background-color: ${SecondaryColor};
      color: #fff;
      svg {
        path {
          fill: #fff;
        }
      }
    }
    svg {
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin-right: 8px;
      transform: rotate(90deg);
      path {
        fill: ${SecondaryColor};
      }
    }
  }
`;

const ChatHistoryAside = styled.div<{ refWidth: number }>`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 256px;
  z-index: -1;
  border-radius: 12px 0px 0px 0px;
  background: #FFF;
  transform: translateX(45px);
  @media all and (max-width: ${maxTablet}) {
    transform: unset;
  }
  @media all and (max-width: ${maxMobile}) {
    width: ${(props) => props.refWidth}px;
    left: unset;
    right: 0px;
    z-index: 1;
    border-radius: 12px;
  }
  @media all and (max-width: ${maxTablet}) {
    right: -1300px;
  }
  &.show {
    @media all and (min-width: ${maxTablet}) {
      animation: aside-show-in 0.3s ease-in forwards;
      box-shadow: 0px 0px 50.206px 0px rgba(0, 0, 0, 0.40);
    }
    @media all and (max-width: ${maxTablet}) {
      right: 0px;
      z-index: 1;
      width: 100%;
    }
  }
  &.out {
    @media all and (min-width: ${maxTablet}) {
      animation: aside-show-out 0.3s ease-out forwards;
      box-shadow: none;
      display: none;
    }
  }
  .chat-bot-history-panel {
    height: 100%;
    box-shadow: none;
    padding-right: 29px;
    @media all and (max-width: ${maxMobile}) {
      padding-right: 0px;
    }
    .scroll-wrapper {
      max-height: 95%;
    }
  }
  .close-panel-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;

const AsideToggler = styled.span`
&.openAside {
  left: -45px;
  /* left: -315.8px;
  animation: slide-out 0.3s ease-out backwards; */
  @media all and (max-width: ${maxTablet}) {
    left: -45px;
  }
  @media all and (max-width: ${maxMobile}) {
    left: -12px;
  }
}
  display: inline-block;
  position: absolute;
  left: -48px;
  top: 66px;
  cursor: pointer;
  @media all and (max-width: ${maxMobile}) {
    left: -11px;
    top: 86px;
  }
`;

type Props = {
  tagItem?: TagItem
};
const defaultProps = {
  tagItem: {},
};

const ChatBotV3 = function ChatBotV3(props: Props) {
  const { tagItem } = props;
  const [promptInProgress, setPromptInProgress] = useState('');
  const [inputText, setInputText] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [contentEditableRef,
    setContentEditableRef] = useState<RefObject<HTMLDivElement> | null>(null);
  const showAskGif = useSelector(isShowAskGif);
  const chatContainer = document.querySelector('.chatContainer');
  const botBubble = document.querySelector('.botBubble');
  const [isChatOpen, setIsChatOpen] = useState(false);
  const isEditChatOpenFlag = useSelector(isEditChatOpen);
  const startTime = getCurrentTime();
  const [isStopGenerating, setIsStopGenerating] = useState(false);
  const isHeightContent = useSelector(isGilroyTextHeight);
  const showLibrary = useSelector(selectShowLibrary);
  const deleteChat = useSelector(selectDeleteChat);
  const selectedChannelId = useSelector(selectedConversationId);
  const showDocumentHub = useSelector(selectShowDocumentHub);
  const currentUrl = window.location.href;
  const saveUrl = useSelector(selectSaveUrlV2);
  const saveProfile = useSelector(selectSaveProfileNameV2);
  const toggleBtn = useSelector(selectHistoryPanelOpened);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const isEnterprise = isEnterpriseUser();
  const chatLoading = useSelector(selectChatLoading);
  const limitPopup = useSelector(selectLimitPopup);
  const conversationSections = useSelector(selectConversationSections);
  const currentType = useSelector(selectActivetype);
  const isScroll = useSelector(selectIsScroll);
  const isHighlighted = useSelector(selectIsHighlighted);
  const showAccordion = useSelector(selectShowAccordion);
  const aiCertificationData = useSelector(selectAiCertificationData);
  const certificationPrompt = useSelector(selectClickedCertificationPrompt);
  const isCompletionPopupOpen = useSelector(selectIsCompletionPopupOpen);
  const drawerPreviewOpen = useSelector(selectDrawerPreviewOpen);
  const openGilroy = useSelector(isShowGilroyGlobal);
  const { showDisclaimer, disclaimerText } = useSelector(selectGilroyDiscalimer);
  const isSafariFlag = isSafariBrowser();
  const showUploadDocument = useSelector(selectShowUploadDocument);
  const isTab = IsTablet();
  const isMob = IsMobile();

  const {
    showPlanPopup, callToAction, contactUsEmail, popupText,
  } = limitPopup;

  const buyerGrpCertification = ['open_buyer_group', 'buyer_group_execute_prompt_2', 'buyer_group_execute_prompt_3'];
  const host = window.location.href;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const hideDocumentBtnFlag = pathName.includes('documents');
  const pageType = () => {
    switch (true) {
      case pathName.includes('company') && !pathName.includes('comparecompany'):
        return 'company';
      case pathName.includes('group'):
        return 'BRM';
      case pathName.includes('person'):
        return 'people';
      case pathName.includes('document'):
        return 'doc hub';
      case pathName.includes('feed'):
        return 'feed';
      default:
        return 'other';
    }
  };

  const aiCertification = () => {
    switch (true) {
      case pathName.includes('company'):
        return {
          type: 'ask_gilroy_company_profile',
          source: 'company_gilroy',
        };
      case pathName.includes('group'):
        return {
          type: 'ask_gilroy_buyer_group',
          source: 'group_gilroy',
        };
      case pathName.includes('person'):
        return {
          type: 'ask_gilroy_person_profile',
          source: 'person_gilroy',
        };
      case pathName.includes('document'):
        return {
          type: 'ask_gilroy_document_hub',
          source: 'doc_gilroy',
        };
      default:
        return {
          type: 'ask_gilroy',
          source: 'gilroy',
        };
    }
  };

  useEffect(() => {
    if (!isHighlighted && !isScroll && ChatbotCertificationTypes.includes(currentType)
      && !(tagItem?.type === 'company' && (tagItem?.id.toString() || '') === '238'
        && buyerGrpCertification.includes(currentType))) {
      dispatch(certificationActions.setHighlight(true));
      dispatch(certificationActions.setIsHighlighted(false));
    }
  }, [isScroll, currentType]);

  useEffect(() => () => {
    dispatch(actions.resetTagging());
    dispatch(actions.setShowLibrary(true));
    dispatch(actions.setIsRegenerate(false));
  }, []);

  const toggleTabs = (isLibrary: boolean) => {
    if (isEditChatOpenFlag) {
      dispatch(UiAction.isEditChatOpenInput(false));
    }
    dispatch(actions.setShowLibrary(isLibrary));
  };

  useEffect(() => {
    if (deleteChat.chatId) {
      dispatch(actions.deleteChat({
        isStopGenerating: true,
        channelId: deleteChat.convoId,
        chatIds: deleteChat.chatId.toString(),
      }));
    }
  }, [deleteChat]);

  useEffect(() => {
    const node = document.querySelector('body');
    return isChatOpen ? node?.classList.add('body-drawer-toggle') : node?.classList.remove('body-drawer-toggle');
  }, [isChatOpen]);

  const handleUploadDocClick = () => {
    dispatch(actions.setSaveUrl(window.location.href));
    if (pageType() === 'BRM') {
      dispatch(actions.setSaveProfileName(`${tagItem?.name || ''} Buyer Group`));
    } else if (pageType() === 'company') {
      dispatch(actions.setSaveProfileName(`${tagItem?.name || ''}'s Profile`));
    } else if (pageType() === 'people') {
      dispatch(actions.setSaveProfileName(`${tagItem?.name || ''}'s Profile`));
    } else if (pageType() === 'feed') {
      dispatch(actions.setSaveProfileName('Feed'));
    } else if (pageType() === 'other') {
      dispatch(actions.setSaveProfileName('previous screen'));
    }
    navigate(DOCUMENTS_UPLOAD_ROUTE);
    dispatch(UiAction.isShowGilroyGlobalToggle(false));
    dispatch(documentHubActions.setDrawerOpen(true));
    dispatch(flurryActions.callFlurryEvent(valueToObject('documentHub_uploadDocumentBanner_clicked_Gilroy', 0, {
      host,
    })));
  };

  const listenerOutSide = (e: MouseEvent) => {
    const el = e.target as HTMLElement;
    const parentEl = el.parentElement as HTMLElement;
    if (chatContainer && chatContainer?.classList.contains('show') && !showAccordion && !isCompletionPopupOpen && !drawerPreviewOpen && !el.classList.contains('ja-contextmenu') && !el.classList.contains('ja-panel') && !parentEl.classList.contains('ja-contextmenu') && !el.classList.contains('menu-item-label') && !parentEl.classList.contains('menu-item-label')) {
      setIsChatOpen(false);
      dispatch(UiAction.isShowGilroyGlobalToggle(false));
      chatContainer.classList.remove('show');
      botBubble?.classList.remove('hide');
      chatContainer.classList.add('out');
    }
    dispatch(actions.setLimitPopupVisible(false));
  };

  const listener = (isPopover?: boolean) => {
    if (chatContainer) {
      if (chatContainer?.classList.contains('show')) {
        setIsChatOpen(false);
        dispatch(UiAction.isShowGilroyGlobalToggle(false));
        chatContainer.classList.remove('show');
        botBubble?.classList.remove('hide');
        chatContainer.classList.add('out');
      } else {
        setIsChatOpen(true);
        dispatch(UiAction.isShowGilroyGlobalToggle(true));
        chatContainer.classList.add('show');
        botBubble?.classList.add('hide');
        chatContainer.classList.remove('out');
        if (isPopover) {
          dispatch(flurryActions.callFlurryEvent(
            valueToObject('xcgpt_push_popup_open', startTime, {
              host,
            }),
          ));
        } else {
          dispatch(flurryActions.callFlurryEvent(
            valueToObject('xcgpt_gilroyButton_clicked', startTime, {
              host,
            }),
          ));
        }
        dispatch(certificationActions.aiCertificationAction({
          type: aiCertification()?.type,
          source: aiCertification()?.source,
          data: aiCertificationData,
        }));
      }
    }
  };

  useEffect(() => {
    if (currentUrl === saveUrl && !saveProfile.includes(SMART_REDIRECTION_TEXT)) {
      setTimeout(() => {
        if (chatContainerRef.current) {
          setIsChatOpen(true);
          dispatch(UiAction.isShowGilroyGlobalToggle(true));
          botBubble?.classList.add('hide');
          chatContainerRef.current.classList.add('show');
          chatContainerRef.current.classList.remove('out');
        }
      }, 600);
    }
  }, []);

  const handleChatClicked = () => {
    toggleTabs(false);
    if (selectedChannelId === -1 && conversationSections?.length) {
      dispatch(actions.setSelectedConversationId(
        conversationSections[0].sectionData[0]?.channelId,
      ));
    }
  };

  useEffect(() => {
    dispatch(actions.getPromptCategories({
      type: pageType(),
    }));
    return () => {
      dispatch(UiAction.isEditChatOpenInput(false));
      dispatch(UiAction.isCompanyPrompt(false));
    };
  }, []);

  const handleAsideToggler = () => {
    if (!toggleBtn) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_conversationHistory_open', startTime, {
          host,
        }),
      ));
    } else {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_conversationHistory_closed', startTime, {
          host,
        }),
      ));
    }
    dispatch(actions.setHistoryPanelOpened(!toggleBtn));
  };

  useEffect(() => {
    if (certificationPrompt) {
      setInputText(certificationPrompt);
    }
  }, [certificationPrompt]);

  useEffect(() => {
    if (openGilroy && chatContainer) {
      setIsChatOpen(true);
      chatContainer.classList.add('show');
      botBubble?.classList.add('hide');
      chatContainer.classList.remove('out');
      dispatch(certificationActions.aiCertificationAction({
        type: aiCertification()?.type,
        source: aiCertification()?.source,
        data: aiCertificationData,
      }));
    } else if (chatContainer) {
      setIsChatOpen(false);
      chatContainer.classList.remove('show');
      botBubble?.classList.remove('hide');
      chatContainer.classList.add('out');
    }
  }, [openGilroy]);

  return (
    <StyleWrapper className={classNames(
      'chat-bot-styled-wrapper chatbot-v3-styled-wrapper',
      {
        hideDocumentBtn: hideDocumentBtnFlag,
        safariHeightWrapper: isSafariFlag,
        TabMode: isTab,
        mobileMode: isMob,
        samsungDisclaimer: showDisclaimer && disclaimerText && !isTab,
      },
    )}
    >
      <OutsideClickHandler onOutsideClick={listenerOutSide}>
        <ContentPanel className="chat-container chatContainer chatbot-v3-container" ref={chatContainerRef}>
          <div className={classNames('container-inner-area h-100', { conversationalPanelOpen: toggleBtn && !isTab })}>
            <HeaderArea className="chatbot-v3-heading-area">
              <CloseIcon
                className="cross-icon-x3"
                id="chat-bot-close-am"
                onClick={() => listener(false)}
              >
                <CrossCircle />
              </CloseIcon>
              <GilroyHeaderV3 />
            </HeaderArea>
            <BodyArea id="chatbot-v3-body-height" className={classNames('chatbot-v3-body-area', { bodyContentHeight: isHeightContent })}>
              <div className="tabs-div">
                <CustomTabGilroy text="Library" activeState={showLibrary} leftIcon={<LibraryIcon />} onClick={() => toggleTabs(true)} />
                <CustomTabGilroy text="Chat" activeState={!showLibrary} leftIcon={<HistoryIcon />} onClick={handleChatClicked} />
              </div>
              <div id="editDivMobile" className={classNames('body-content', { contentHeight: isHeightContent })}>
                {showLibrary ? (
                  <ChatBotLibrary
                    setInputText={setInputText}
                    isGilroyV3
                  />
                ) : (
                  <ChatBotHistory
                    promptInProgress={promptInProgress}
                    setInputText={setInputText}
                    setIsStopGenerating={setIsStopGenerating}
                    isGilroyV3
                    isEditChatOpenFlag={isHeightContent}
                  />
                )}
              </div>
            </BodyArea>
            <FooterArea className="chatbot-v3-footer-area">
              <CustomTextAreaGilroy
                setPromptInProgress={setPromptInProgress}
                inputText={inputText}
                setInputText={setInputText}
                isStopGenerating={isStopGenerating}
                setIsStopGenerating={setIsStopGenerating}
                tagItem={tagItem}
                setContentEditableRef={setContentEditableRef}
              />
              <DisclaimerCmpFooter newStyle={isTab} />
            </FooterArea>
            <AsideToggler className={classNames('aside-toggler', { openAside: toggleBtn })} onClick={handleAsideToggler}>
              {!toggleBtn ? <ToggleIconOpen isMob={isMob} /> : null}
            </AsideToggler>
            <ChatHistoryAside
              refWidth={chatContainerRef?.current?.clientWidth || 398}
              className={classNames('aside-history', { show: toggleBtn, out: !toggleBtn })}
            >
              {toggleBtn ? <ClosePanelIcon onButtonClick={handleAsideToggler} /> : null}
              <AsideToggler className={classNames('aside-toggler', { openAside: toggleBtn })} onClick={handleAsideToggler}>
                {toggleBtn ? <ToggleIconClose isMob={isMob} /> : null}
              </AsideToggler>
              <ChatBotHistoryPanel
                contentEditableRef={contentEditableRef}
                tagItem={tagItem as TagItem}
                showUploadBtnWithChat={isMob}
              />
              {showDocumentHub && isEnterprise && toggleBtn
                && !hideDocumentBtnFlag && showUploadDocument
                ? (
                  <span
                    tabIndex={0}
                    role="button"
                    className={classNames('upload-document-btn')}
                    onClick={handleUploadDocClick}
                    onKeyPress={handleUploadDocClick}
                  >
                    <UploadDocumentIcon />
                    Upload Document
                  </span>
                ) : null}
            </ChatHistoryAside>
            {showDocumentHub && isEnterprise && !toggleBtn
              && !hideDocumentBtnFlag && showUploadDocument
              ? (
                <span
                  tabIndex={0}
                  role="button"
                  className={classNames('upload-document-btn', { showBtnWithDrawer: toggleBtn })}
                  onClick={handleUploadDocClick}
                  onKeyPress={handleUploadDocClick}
                >
                  <UploadDocumentIcon />
                  Upload Document
                </span>
              ) : null}
            {showPlanPopup ? (
              <ProfilePopups
                title="Limit Reached"
                description={popupText}
                type={showPlanPopup}
                callToAction={callToAction}
                contactUs={contactUsEmail}
                isDownloadPopup={false}
                loading={chatLoading}
                isChatbotPopup
              />
            ) : null}
          </div>
        </ContentPanel>
      </OutsideClickHandler>
      {!isChatOpen
        ? (
          <ContentPopOver
            tipTitle={tagItem?.name || ''}
            showAskGif={!showAskGif}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            openGilroy={listener}
          >
            <div
              onClick={() => listener(false)}
              onKeyPress={() => listener(false)}
              tabIndex={0}
              role="button"
              aria-label="button"
            >
              <img
                src={askGilroyAnimationV7}
                alt="chatBubble"
                className={classNames('chatBubble buddleV3', { chatImage: !showAskGif })}
              />
            </div>
          </ContentPopOver>
        )
        : null}
    </StyleWrapper>
  );
};
ChatBotV3.defaultProps = defaultProps;
export default ChatBotV3;
